<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('modalDialogs.entityRoleDetail.header')}}</h4>

        <form class="form-horizontal">
            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{ $t('modalDialogs.entityRoleDetail.form.beName.label')}}</label>
                <div class="col-md">
                    <fg-input v-model="billingEntityName" readonly></fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label mt-2 text-left text-md-right">{{ $t('modalDialogs.entityRoleDetail.form.eName.label')}}</label>
                <div class="col-md mt-2">
                    <fg-input>
                        <el-select class="select-primary" disabled
                                   v-model="detailData.entityHID"
                                   :placeholder="$t('modalDialogs.entityRoleDetail.form.eName.placeholder')">
                            <el-option v-for="ent in entities"
                                       class="select-primary"
                                       :value="ent.hid"
                                       :label="ent.name"
                                       :key="ent.hid">
                            </el-option>
                        </el-select>
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{ $t('modalDialogs.entityRoleDetail.form.role.label')}}</label>
                <div class="col-md">
                    <fg-input :error="getError('roleId')">
                        <el-select class="select-primary" name="roleId"
                                   v-model="detailData.roleId"
                                   v-validate="modelValidations.roleId"
                                   :placeholder="$t('modalDialogs.entityRoleDetail.form.role.placeholder')">
                            <el-option v-for="role in roles"
                                       class="select-primary"
                                       :value="role.id"
                                       :label="role.name"
                                       :key="role.id">
                            </el-option>
                        </el-select>
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label mt-2 text-left text-md-right">{{ $t('modalDialogs.entityRoleDetail.form.accountEmail.label')}}</label>
                <div class="col-md mt-2">
                    <fg-input :error="getError('accountEmail')">
                        <el-select class="select-primary" filterable remote :loading="findingAccount" default-first-option
                                   name="accountEmail" allow-create :remote-method="findAccount"
                                   v-validate="modelValidations.accountEmail"
                                   v-model="detailData.email" :disabled="mode === 'edit'"
                                   :placeholder="$t('modalDialogs.entityRoleDetail.form.accountEmail.placeholder')">
                            <el-option v-for="item in accountResult"
                                       :key="item.email"
                                       :label="item.email"
                                       :value="item.email">
                            </el-option>
                        </el-select>
                    </fg-input>
                </div>
            </div>
        </form>

        <div slot="footer" class="row mr-3">
            <div class="col">
                <p-button type="info" round @click="onClickSave">
                    {{ $t('modalDialogs.entityRoleDetail.btnSave')}}
                </p-button>
                <p-button type="info" round @click="onClickCancel">
                    {{ $t('modalDialogs.entityRoleDetail.btnCancel')}}
                </p-button>
            </div>
        </div>
    </modal>
</template>
<script>
    import { Modal } from 'src/components/UIComponents'
    import { Select, Option } from 'element-ui'
    import swal from 'sweetalert2'
    import { RoleEnum } from 'src/lpLibrary.js'

    export default {
        name: 'e-role-detail',
        components: {
            Modal,
            [Select.name]: Select,
            [Option.name]: Option
        },
        props: {
            billingEntityHID: String,
            billingEntityName: String,
            entityHID: String,
            detailData: Object,
            show: Boolean,
            mode: {
                type: String,
                default: 'new',
                description: 'new|edit)'
            }
        },
        data() {
            return {
                modalShow: false,
                modelValidations: {
                    accountEmail: {
                        required: true,
                        email: true,
                    },
                    roleId: {
                        required: true,
                    }
                },
                findingAccount: false,
                accountResult: [],
                entities:[],
            }
        },
        methods: {
            onClickCancel: function () {
                this.$emit('hide', false);
            },
            onClickSave: function () {
                let vueThis = this;
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.$emit('save');
                    }
                })
            },
            findAccount: function (query) {
                let vueThis = this;
                if (query !== '' && query.length > 3) {
                    this.findingAccount = true;
                    this.axios.post(this.$root.config.account.getAccountsUrl(), null, { params: { query: query } })
                        .then(response => {
                            this.findingAccount = false;
                            this.accountResult = response.data;                            
                        }).catch(function (error) {
                            vueThis.writeEx2Log(error);
                            this.findingAccount = false;
                        });
                } else {
                    this.accountResult = [];
                }
            },
            getError(fieldName) {
                let familierName = this.$t('modalDialogs.entityRoleDetail.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
            loadEntities() {
                let vueThis = this;
                this.axios.post(this.$root.config.billingEntity.getEntities4BeUrl(this.billingEntityHID), null)
                    .then(response => {
                        vueThis.entities = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
        },
        computed: {
            roles() {
                return [
                    { name: RoleEnum.ManagerName, id: RoleEnum.ManagerID },
                    { name: RoleEnum.ReaderName, id: RoleEnum.ReaderID },
                    //{ name: RoleEnum.DemoName, id: RoleEnum.DemoID },
                ];
            },

        },
        watch: {
            show: function (value) {
                this.modalShow = value;
            },
            modalShow: function (value) {
                if (value) {
                    this.errors.clear();
                    this.loadEntities();
                    this.detailData.entityHID = this.entityHID;

                }
                else {
                    this.$emit('hide', false);
                }
            },
        }
    }
</script>
<style scoped>
</style>
