<template>
    <div>
        <div class="row" v-if="manualControl && lazyAIStopped">
            <div class="col-12" ref="campaignStatus">
                <card class="card-stats" data-v-step="step-cstat">
                    <div class="row">
                        <div class="col text-center numbers">
                            <p class="card-category">
                                {{$t('entityViews.campaignList.status.scheduledLabel')}}
                            </p>
                            <p class="card-title">
                                {{campaignStatus.statistics.scheduled}}
                            </p>
                        </div>
                        <div class="col text-center numbers">
                            <p class="card-category">
                                {{$t('entityViews.campaignList.status.runningLabel')}}
                            </p>
                            <p class="card-title">
                                {{campaignStatus.statistics.running}}
                            </p>
                        </div>
                        <div class="col text-center numbers">
                            <p class="card-category">
                                {{$t('entityViews.campaignList.status.pausedLabel')}}
                            </p>
                            <p class="card-title">
                                {{campaignStatus.statistics.paused}}
                            </p>
                        </div>
                        <div class="col text-center numbers">
                            <p class="card-category">
                                {{$t('entityViews.campaignList.status.completedLabel')}}
                            </p>
                            <p class="card-title">
                                {{campaignStatus.statistics.finished}}
                            </p>
                        </div>
                    </div>
                    <hr slot="footer" />
                </card>
            </div>
        </div>

        <div class="mb-3" data-v-step="step-cfilter">
            <div class="btn-group d-none d-md-block">
                <p-button type="primary" round outline nativeLabel @click="campFilterStatus('sc-all')" :class="{active: campaignsFilter.status === 'sc-all' }"> <i class="fa fa-list"></i> {{$t('entityViews.campaignList.filter.allLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="campFilterStatus('sc-scheduled')" :class="{active: campaignsFilter.status === 'sc-scheduled' }"> <i class="fa-solid fa-clock"></i> {{$t('entityViews.campaignList.filter.scheduledLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="campFilterStatus('sc-running')" :class="{active: campaignsFilter.status === 'sc-running' }"> <i class="fa-solid fa-play"></i> {{$t('entityViews.campaignList.filter.runningLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="campFilterStatus('sc-paused')" :class="{active: campaignsFilter.status === 'sc-paused' }"> <i class="fa fa-pause"></i> {{$t('entityViews.campaignList.filter.pausedLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="campFilterStatus('sc-finished')" :class="{active: campaignsFilter.status === 'sc-finished' }"> <i class="fa fa-check"></i> {{$t('entityViews.campaignList.filter.completedLabel')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="campFilterStatus('sc-all')" :class="{active: campaignsFilter.status === 'sc-all' }"> <i class="fa fa-list"></i> {{$t('entityViews.campaignList.filter.allLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="campFilterStatus('sc-scheduled')" :class="{active: campaignsFilter.status === 'sc-scheduled' }"> <i class="fa-solid fa-clock"></i> {{$t('entityViews.campaignList.filter.scheduledLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="campFilterStatus('sc-running')" :class="{active: campaignsFilter.status === 'sc-running' }"> <i class="fa-solid fa-play"></i> {{$t('entityViews.campaignList.filter.runningLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="campFilterStatus('sc-paused')" :class="{active: campaignsFilter.status === 'sc-paused' }"> <i class="fa fa-pause"></i> {{$t('entityViews.campaignList.filter.pausedLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="campFilterStatus('sc-finished')" :class="{active: campaignsFilter.status === 'sc-finished' }"> <i class="fa fa-check"></i> {{$t('entityViews.campaignList.filter.completedLabel')}}</p-button>
                </div>
            </div>
        </div>

        <div ref="campaignsContent">
            <div class="row">
                <div class="col-12">
                    <div class="card" >
                        <div class="card-header">
                            <div class="row">
                                <div class="col  d-flex justify-content-start">
                                    <h4 class="card-title">{{$t('entityViews.campaignList.header')}}</h4>
                                </div>
                                <div class="col text-right">
                                    <el-tooltip :content="$t('entityViews.campaignList.btnRefreshTooltip')" :open-delay="300" placement="top">
                                        <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                            <i class="fa-solid fa-arrows-rotate"></i>
                                        </p-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!-- toolbar -->
                            <div class="row toolbar">
                                <div class="col-12">
                                    <div class="" v-if="manualControl && lazyAIStopped">
                                        <router-link class="btn btn-success" :to="{ name: 'newCampaign'}" data-v-step="step-cnew">
                                            <i class="nc-icon nc-simple-add"></i> {{$t('entityViews.campaignList.btnNewCamp')}}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!-- header -->
                            <div class="row justify-content-between">
                                <div class="col-12 col-md-6 pt-0 pt-md-2">

                                </div>
                                <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                                    <div class="d-flex justify-content-end">
                                        <fg-input class="input-sm mr-2"
                                                  :placeholder="$t('entityViews.campaignList.searchPlaceholder')"
                                                  v-model="c_searchQuery"
                                                  addon-right-icon="nc-icon nc-zoom-split">
                                        </fg-input>
                                        <el-select class="select-default float-sm-left page-size"
                                                   v-model="c_pagination.perPage">
                                            <el-option class="select-default"
                                                       v-for="item in c_pagination.perPageOptions"
                                                       :key="item"
                                                       :label="item"
                                                       :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>


                            </div>
                            <!-- table -->
                            <div class="row">
                                <div class="col-12" ref="tablecontainer" data-v-step="step-camps">
                                    <el-table :data="c_queriedData" ref="eltable" :empty-text="$t('entityViews.campaignList.table.noData')"
                                              :default-sort="{ prop: 'endUtc', order: 'descending'}" class="camp-list-table"
                                              @sort-change="c_handleSortChange">
                                        <el-table-column prop="name" sortable="custom" :label="$t('entityViews.campaignList.table.colName.header')">
                                            <template slot-scope="props">
                                                <el-tooltip class="mr-1" :content="$t('entityViews.campaignList.table.colName.notifyMsg')" :open-delay="300" placement="bottom" v-if="props.row.errorCode != null">
                                                    <span class="fa-solid fa-triangle-exclamation text-danger"></span>
                                                </el-tooltip>
                                                <span class="" v-if="props.row.isTest">{{$t('entityViews.campaignList.table.colName.prefixTest')}}</span>
                                                {{props.row.name}}
                                                <el-tooltip class="ml-1" :content="$t('entityViews.campaignList.table.colName.noTrainingTooltip')" :open-delay="300" placement="bottom" v-if="props.row.anonymousMode">
                                                    <i class="fa-stack">
                                                        <i class="fa fa-graduation-cap fa-stack-1x campaign-info-icon"></i>
                                                        <i class="fa fa-ban fa-stack-2x campaign-info-icon"></i>
                                                    </i>
                                                </el-tooltip>
                                                <el-tooltip class="ml-1" :content="$t('entityViews.campaignList.table.colName.trainingTooltip')" :open-delay="300" placement="bottom" v-else>
                                                    <i class="fa fa fa-graduation-cap campaign-info-icon" aria-hidden="true"></i>
                                                </el-tooltip>
                                                <el-tooltip class="ml-1" :content="$t('entityViews.campaignList.table.colName.qaTooltip')" :open-delay="300" placement="bottom" v-if="props.row.isQA">
                                                    <i class="fa-solid fa-user-check campaign-info-icon" aria-hidden="true"></i>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="hvGroupName" sortable="custom" :label="$t('entityViews.campaignList.table.colHVG')">
                                            <template slot-scope="props">
                                                {{translateGroupName(props.row.hvGroupName, props.row.isHvGroupSystem)}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="campaignBlockGroupName" sortable="custom" :label="$t('entityViews.campaignList.table.colCB')">
                                            <template slot-scope="props">
                                                {{props.row.campaignBlockGroupName}} ({{props.row.campaignBlockGroupCulture}})
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="endUtc" sortable="custom" :label="$t('entityViews.campaignList.table.colEndDate')" :width="150">
                                            <template slot-scope="props">
                                                {{props.row.endUtc | moment("DD.MM.YYYY")}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="order" sortable="custom" :label="$t('entityViews.campaignList.table.colStatus.header')" align="right">
                                            <template slot-scope="props">
                                                <div class="row justify-content-end d-flex align-items-center">
                                                    <div class="col-auto" style="min-width:40%">
                                                        <div class="" v-if="props.row.isRunning">
                                                            <el-tooltip :content="props.row.tooltipContent" :open-delay="300" placement="top">
                                                                <p-progress :value="props.row.processPercent"></p-progress>
                                                            </el-tooltip>
                                                        </div>
                                                        <div class="text-right scheduled-text" v-if="props.row.isScheduled">
                                                            {{$t('entityViews.campaignList.table.colStatus.scheduledLabel', [props.row.daysToStart])}}
                                                        </div>
                                                        <div class="text-right paused-text" v-if="props.row.isPaused">
                                                            {{$t('entityViews.campaignList.table.colStatus.pausedLabel')}} {{props.row.lastStatusChangeUtc | moment("DD.MM.YYYY")}}
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span :class="props.row.statusIcon"></span>
                                                        {{$t(props.row.statusLabelId)}}
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column :width="120" class-name="td-actions" align="center">
                                            <template slot="header" slot-scope="scope">
                                                <span data-v-step="step-cactions">{{$t('entityViews.campaignList.table.colActions.header')}}</span>
                                            </template>
                                            <template slot-scope="props">
                                                <el-tooltip :content="$t('entityViews.campaignList.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                    <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'eCampaign', params:{ chid: props.row.hid}}" v-bind:class="{'detail-btn-mr': (lazyAIStopped && manualControl && !props.row.canStopped && !props.row.isPaused) }">
                                                        <i class="fa-solid fa-circle-info"></i>
                                                    </router-link>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('entityViews.campaignList.table.colActions.btnPause')" :open-delay="300" placement="top">
                                                    <p-button type="warning" icon size="sm" @click="stopCampaign(props.row.hid, props.row.name)" v-if="manualControl && lazyAIStopped && props.row.canStopped">
                                                        <i class="fa fa-pause"></i>
                                                    </p-button>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('entityViews.campaignList.table.colActions.btnStart')" :open-delay="300" placement="top">
                                                    <p-button type="success" icon size="sm" @click="startCampaign(props.row.hid, props.row.name)" v-if="manualControl && lazyAIStopped && props.row.isPaused">
                                                        <i class="fa fa-play"></i>
                                                    </p-button>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <!-- paging -->
                            <div class="row">
                                <div class="col-sm-6 pagination-info">
                                    <p class="category">{{$t('entityViews.campaignList.pagingInfo', [c_from + 1,c_to, c_total ])}}</p>
                                </div>
                                <div class="col-sm-6">
                                    <p-pagination class="pull-right"
                                                  v-model="c_pagination.currentPage"
                                                  :per-page="c_pagination.perPage"
                                                  :total="c_pagination.total">
                                    </p-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>


<script>
    import { CampaignTools, MaturityLevelTools, LazyAITools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Progress, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'


    function c_sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Progress.name]: Progress,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            StatsCard,
        },
        data() {
            return {
                entityHID: '',
                campaigns: [],
                campaignsFilter: {
                    status: 'sc-all'
                },
                manualControl: false,
                lazyAIStopped: false,
                campaignStatus: {
                    concurrentLimit: 0,
                    runningByBillingEntity: 0,
                    statistics: {
                        scheduled: 0,
                        running: 0,
                        paused: 0,
                        finished: 0,
                    },
                },
                campaignDays: [],
                //-------------------
                c_pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                c_propsToSearch: ['name', 'hvGroupName', 'order'],
                c_searchQuery: '',
                c_sortObject: { order: 'descending', name: 'endUtc' },
                //------------------
            }
        },
        methods: {
            initComponent: function () {
                this.manualControl = this.$store.getters.appState.entity.manualControl;
                this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;

                this.loadCampFilter();
                this.loadCampaigns();
                setTimeout(() => this.loadCampStatus(), 500);                
            },
            loadCampStatus(force) {
                if (!this.manualControl || !this.lazyAIStopped) return;
                if (!this.entityHID) return;
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.campaignStatus));
                this.axios.post(this.$root.config.entity.campaign.getCampaignStatusUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        loader.hide();
                        this.campaignStatus = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            loadCampFilter() {
                let clState = this.$store.getters.campaignListState;
                if (clState.campaignsFilter.status != '') {
                    this.campaignsFilter.status = clState.campaignsFilter.status;
                }
            },
            loadCampaigns(force) {
                if (!this.entityHID) return;
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.campaignsContent));
                this.axios.post(this.$root.config.entity.campaign.getCampaignsUrl(this.entityHID), { }, { params: { force: force } })
                    .then(response => {
                        loader.hide();
                        this.campaigns = response.data;
                        this.campaigns.forEach(function (item) {
                            var suiData = CampaignTools.GetStatusUIData(item.status);
                            item.statusLabelId = suiData.labelId;
                            item.statusIcon = suiData.icon;
                            item.order = suiData.order;
                            item.isRunning = item.status == CampaignTools.Status_Running;
                            item.isScheduled = item.status == CampaignTools.Status_Scheduled;
                            item.isPaused = item.status == CampaignTools.Status_Paused;
                            item.isFinished = item.status == CampaignTools.Status_Finished;
                            item.canStopped = item.status == CampaignTools.Status_Running || item.status == CampaignTools.Status_Scheduled;
                            item.tooltipContent = vueThis.$moment(item.startUtc).format("DD-MM-YYYY") + " - " + vueThis.$moment(item.endUtc).format("DD-MM-YYYY");
                            item.isSendMHuge = item.sendingMode == CampaignTools.Status_Huge;

                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            stopCampaign(sid, name) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.campaignList.stopAlert.msg', [name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.campaign.changeCampaignStatusUrl(this.entityHID), null, { params: { campaignHID: sid, action: 0 } })
                            .then(response => {
                                vueThis.loadCampaigns();
                                vueThis.loadCampStatus();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.campaignList.stopAlert.success', [name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.campaignList.stopAlert.failed', [name]),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            startCampaign(sid, name) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.campaignList.startAlert.msg', [name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.campaign.changeCampaignStatusUrl(this.entityHID), null, { params: { campaignHID: sid, action: 1 } })
                            .then(response => {
                                vueThis.loadCampaigns();
                                vueThis.loadCampStatus();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.campaignList.startAlert.success', [name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });                                
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.campaignList.startAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            campFilterStatus(status) {
                //this.campaignsFilter.status = status;
                //if (status === "sc-all") {
                //    this.$refs.eltable.sort('order', 'descending');
                //}
                //else {
                //    this.$refs.eltable.sort('name', 'ascending');
                //}
                //this.$store.commit('updateCampaignList', this.campaignsFilter);
                //this.loadCampaigns();

                if (this.campaignsFilter.status != status) {
                    this.$router.push({ name: 'eCampaignsList', query: { s: status } });
                }
                else {
                    this.campaignsFilter.status = status;
                }
            },
            refresh(force) {
                this.loadCampaigns(force);
                this.loadCampStatus(force);
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
            //-----------------------------
            c_handleSortChange(e) {
                this.c_sortObject = { order: e.order, name: e.prop };
            },
        },
        computed: {
            activeCampaigns() {
                if (this.campaignStatus.statistics == undefined) return 0;
                return this.campaignStatus.statistics.running + this.campaignStatus.statistics.stopped + this.campaignStatus.statistics.scheduled;
            },
            //campaignChartData() {
            //    let data = {
            //        labels: [],
            //        datasets: [
            //            {
            //                label: "Campaign status",
            //                borderColor: '#6bd098',
            //                fill: true,
            //                backgroundColor: '#6bd098',
            //                hoverBorderColor: '#6bd098',
            //                borderWidth: 5,
            //                data: [],
            //            }
            //        ],
            //    };

            //    if (this.campaignStatus.statistics != undefined) {
            //        data.labels.push("Scheduled");
            //        data.datasets[0].data.push(this.campaignStatus.statistics.scheduled);
            //        data.labels.push("Running");
            //        data.datasets[0].data.push(this.campaignStatus.statistics.running);
            //        data.labels.push("Paused");
            //        data.datasets[0].data.push(this.campaignStatus.statistics.stopped);
            //        data.labels.push("Finished");
            //        data.datasets[0].data.push(this.campaignStatus.statistics.finished);
            //    }

            //    return data;
            //},
            //--------------------------
            c_preFilteredCampaigns() {
                switch (this.campaignsFilter.status) {
                    case 'sc-running':
                        return this.campaigns.filter(c => c.isRunning);
                    case 'sc-scheduled':
                        return this.campaigns.filter(c => c.isScheduled);
                    case 'sc-paused':
                        return this.campaigns.filter(c => c.isPaused);
                    case 'sc-finished':
                        return this.campaigns.filter(c => c.isFinished);
                    default:
                        return this.campaigns;
                }
            },
            c_queriedData() {
                var cThis = this;
                if (!this.c_searchQuery) {
                    this.c_pagination.total = this.c_preFilteredCampaigns.length;
                    return this.c_preFilteredCampaigns.sort(function (a, b) { return c_sortingHandler(a, b, cThis.c_sortObject); }).slice(this.c_from, this.c_to);
                }
                let result = this.c_preFilteredCampaigns
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.c_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.c_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.c_pagination.total = result.length;
                return result.sort(function (a, b) { return c_sortingHandler(a, b, cThis.c_sortObject); }).slice(this.c_from, this.c_to);
            },
            c_to() {
                let highBound = this.c_from + this.c_pagination.perPage;
                if (this.c_total < highBound) {
                    highBound = this.c_total;
                }
                return highBound;
            },
            c_from() {
                if (this.c_pagination.total == 0) return -1;
                return this.c_pagination.perPage * (this.c_pagination.currentPage - 1);
            },
            c_total() {
                return this.c_pagination.total;
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    let s = this.$route.query.v;
                    if (s == undefined) s = 'sc-all';
                    this.campaignsFilter.status = s;

                    this.initComponent();
                }
            }
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            let s = this.$route.query.s;
            if (s == undefined) s = 'sc-all';
            this.campaignsFilter.status = s;
        }
    }

</script>

<style scoped>
    .detail-btn-mr {
        margin-right: 35px !important;
    }
</style>