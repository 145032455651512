<template>
    <div>
        <bar-chart :labels="chartXAxisLabel"  ref="barChart"
                   :height="100"
                   :datasets="chartDataSets" :extraOptions="extraOptions">
        </bar-chart>
    </div>
</template>

<script>
    import BarChart from 'src/components/UIComponents/Charts/BarChart'

    export default {
        name: 'camp-statistic-chart',
        props: {
            ChartData: Object,
        },
        components: {
            BarChart,
        },
        data() {
            let vueThis = this;
            return {
                extraOptions: {
                    scales: {
                        //display: false,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                max: 100,
                                min: 0,
                                callback: function (value, index, values) {
                                    return value + "%";
                                }
                            }
                        }]
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                        callbacks: {
                            //title: function (tooltipItem, data) {
                            //    return data['labels'][tooltipItem[0]['index']];
                            //},
                            label: function (tooltipItem, data) {
                                return " " + data['datasets'][0]['data'][tooltipItem['index']] + " % (" + vueThis.chartAbsolutValues[tooltipItem['index']] + ")";
                            },
                        },
                    },
                },
            }
        },
        computed: {
            chartXAxisLabel() {
                return [
                    this.$t('charts.campStatChart.initiatedLabel'),
                    this.$t('charts.campStatChart.interactedLabel'),
                    this.$t('charts.campStatChart.reactedLabel'),
                    this.$t('charts.campStatChart.compromisedLabel'),
                    this.$t('charts.campStatChart.trainedLabel'),
                    this.$t('charts.campStatChart.reportedLabel')
                ];
            },
            chartInitiatedData() {
                let dataProc = 0;
                let dataValue = 0;
                if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                    dataProc = Math.round(100 * this.ChartData.percentInitiated);
                    dataValue = this.ChartData.countInitiated;
                }
                    
                return {
                    label: this.$t('charts.campStatChart.initiatedLabel'),
                    color: '#6bd098',
                    dataProc: dataProc,
                    dataValue: dataValue,
                };
            },
            chartInteractedData() {
                let dataProc = 0;
                let dataValue = 0;
                if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                    dataProc = (100 * this.ChartData.percentInteracted).toFixed(1);
                    dataValue = this.ChartData.countInteracted;
                }

                return {
                    label: this.$t('charts.campStatChart.interactedLabel'),
                    color: '#fbc658',
                    dataProc: dataProc,
                    dataValue: dataValue,
                };
            },
            chartReactedData() {
                let dataProc = 0;
                let dataValue = 0;
                if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                    dataProc = (100 * this.ChartData.percentReacted).toFixed(1);
                    dataValue = this.ChartData.countReacted;
                }
                return {
                    label: this.$t('charts.campStatChart.reactedLabel'),
                    color: '#ffa500',
                    dataProc: dataProc,
                    dataValue: dataValue,
                 };
            },
            chartCompromisedData() {
                let dataProc = 0;
                let dataValue = 0;
                if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                    dataProc = (100 * this.ChartData.percentCompromised).toFixed(1);
                    dataValue = this.ChartData.countCompromised;
                }
                return {
                    label: this.$t('charts.campStatChart.compromisedLabel'),
                    color: '#ef8157',
                    dataProc: dataProc,
                    dataValue: dataValue,
                 };
            },
            //chartReInteractedData() {
            //    let dataProc = 0;
            //    let dataValue = 0;
            //     if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
            //         dataProc = (100 * this.ChartData.percentReInteracted).toFixed(1);
            //         dataValue = this.ChartData.countReInteracted;
            //    }
            //    return {
            //        label: 'ReInteracted',
            //        color: '#fdeece',
            //        dataProc: dataProc,
            //        dataValue: dataValue,
            //     };
            //},
            //chartReReactedData() {
            //    let dataProc = 0;
            //    let dataValue = 0;
            //     if (this.ChartData != undefined/* && this.ChartData.hoaxVictims > 0*/) {
            //         dataProc = (100 * this.ChartData.percentReReacted).toFixed(1);
            //         dataValue = this.ChartData.countReReacted;
            //    }
            //    return {
            //        label: 'ReReacted',
            //        color: '#ffe4b5',
            //        dataProc: dataProc,
            //        dataValue: dataValue,
            //     };
            //},
            //chartReCompromisedData() {
            //    let dataProc = 0;
            //    let dataValue = 0;
            //     if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
            //         dataProc = (100 * this.ChartData.percentReCompromised).toFixed(1);
            //         dataValue = this.ChartData.countReCompromised;
            //    }
            //    return {
            //        label: 'ReCompromised',
            //        color: '#fadace',
            //        dataProc: dataProc,
            //        dataValue: dataValue,
            //     };
            //},
            chartTrainedData() {
                let dataProc = 0;
                let dataValue = 0;
                 if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                     dataProc = (100 * this.ChartData.percentTrained).toFixed(1);
                     dataValue = this.ChartData.countTrained;
                }
                return {
                    label: this.$t('charts.campStatChart.trainedLabel'),
                    color: '#51bcda',
                    dataProc: dataProc,
                    dataValue: dataValue,
                 };
            },
            chartReportedData() {
                let dataProc = 0;
                let dataValue = 0;
                 if (this.ChartData != undefined /*&& this.ChartData.hoaxVictims > 0*/) {
                     dataProc = (100 * this.ChartData.percentReported).toFixed(1);
                     dataValue = this.ChartData.countReported;
                }
                return {
                    label: this.$t('charts.campStatChart.reportedLabel'),
                    color: '#e2f6eb',
                    dataProc: dataProc,
                    dataValue: dataValue,
                 };
            },
            chartDataSets() {
                let dataProc = [
                    this.chartInitiatedData.dataProc,
                    this.chartInteractedData.dataProc,
                    this.chartReactedData.dataProc,
                    this.chartCompromisedData.dataProc,
                    this.chartTrainedData.dataProc,
                    this.chartReportedData.dataProc
                ];
                let colors = [
                    this.chartInitiatedData.color,
                    this.chartInteractedData.color,
                    this.chartReactedData.color,
                    this.chartCompromisedData.color,
                    this.chartTrainedData.color,
                    this.chartReportedData.color
                ];
                return [
                    {
                        label: this.$t('charts.campStatChart.percentlabel'),
                        fill: true,
                        borderWidth: 5,
                        data: dataProc,
                        backgroundColor: colors,
                        borderColor: colors,
                    }];
            },
            chartAbsolutValues() {
                return [
                    this.chartInitiatedData.dataValue,
                    this.chartInteractedData.dataValue,
                    this.chartReactedData.dataValue,
                    this.chartCompromisedData.dataValue,
                    this.chartTrainedData.dataValue,
                    this.chartReportedData.dataValue
                ]
            },
        }
    }
</script>

<style scoped>
</style>