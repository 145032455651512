<template>
    <div ref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <div class="dash-icon" v-if="viewBy === 'entity'">
                    <span class="fa fa-building"></span>
                </div>
                <router-link class="btn btn-info btn-icon btn-round btn-lg d-print-none" :to="{ name: 'eReport', query: { v: 'groups' }}" v-if="viewBy === 'group'">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <a class="btn btn-info btn-icon btn-round btn-lg d-print-none" href="javascript:void(0)" @click="goback" v-if="viewBy === 'hv'">
                    <i class="fa fa-arrow-left "></i>
                </a>

                <div class="view-title ml-3">
                    <p class="title">
                        {{reportData.name}}
                        <span class="text-muted" style="font-size: 50%">{{hvIdentifire}}</span>
                    </p>
                    <p class="category">{{title}}</p>
                </div>
            </div>
            <div class="col text-right d-print-none">
                <el-tooltip :content="$t('report.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh(true)">
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>


        <!-- score trends -->
        <div class="row" v-if="viewBy == 'hv'">
            <div class="col-12">
                <score-trend :statistic="reportData.scoreStatistic"></score-trend>
            </div>
        </div>


        <!-- E a HVG overview -->
        <div class="row justify-content-md-center" v-if="viewBy != 'hv'">
            <div class="col-12 col-md">
                <benchmark-card :reacted="subjectBenchmark.reacted" :compromised="subjectBenchmark.compromised"
                                :title="titleBenchmark" :icon="iconBenchmark"
                                footerText="">
                </benchmark-card>
            </div>
            <div class="col-12 col-md-auto">
                <benchmark-card :reacted="diffBenchmark.reacted" :compromised="diffBenchmark.compromised" :showProgressBar="false"
                                :title="$t('report.benchmark.dheader')" icon="fa-solid fa-play fa-3x fa-rotate-270" mode="diff"
                                footerText="" class="diff-card">
                </benchmark-card>
            </div>
            <div class="col-12 col-md">
                <benchmark-card :reacted="globalBenchmark.reacted" :compromised="globalBenchmark.compromised"
                                :title="$t('report.benchmark.wheader')" 
                                :footerText="$t('report.benchmark.wtext')">
                </benchmark-card>
            </div>
        </div>

        <div class="row" v-if="viewBy != 'hv'">
            <div class="col-12 d-none d-sm-block">
                <card>
                    <h4 slot="header">
                        {{$t('report.hvMaturityLvlDistributionHeader')}}
                    </h4>
                    <hv-maturitylevel-distribution-chart :chartData="hvMLDistribution"></hv-maturitylevel-distribution-chart>
                </card>
            </div>
        </div>


        <div class="row" v-if="viewBy != 'hv'">
            <div class="col-12 d-none d-sm-block">
                <card>
                    <h4 slot="header">
                        {{$t('report.hvStatTrendHeader')}}
                    </h4>
                    <statistic-by-hvs-chart :statistic="statisticsByHvs"></statistic-by-hvs-chart>
                </card>
            </div>
        </div>

        <!-- statistics -->
        <div class="mt-5 mt-md-0" v-if="viewBy != 'hv'">
            <h3 class="title mt-4 text-center">{{$t('report.hvStatHeader')}}</h3>

            <channel-statistic-cards-byhvs :statistic="byHvs">
            </channel-statistic-cards-byhvs>
        </div>

        <div class="mt-5 mt-md-0" v-if="viewBy == 'hv'">
            <h3 class="title mt-4 text-center">{{$t('report.simulationStatHeader')}}</h3>

            <channel-statistic-cards-bymsgs :statistic="byMessages">
            </channel-statistic-cards-bymsgs>
        </div>

        <div class="row d-none d-md-block mt-3">
            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('report.dateDistHeader')}}
                    </h4>
                    <date-distribution-chart :chartData="dateDistribution">

                    </date-distribution-chart>
                </card>
            </div>

            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('report.wdDistHeader')}}
                    </h4>
                    <weekday-distribution-chart :chartData="weekDayDistribution">

                    </weekday-distribution-chart>
                </card>
            </div>

            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('report.hDistHeader')}}
                    </h4>
                    <hourly-distribution-chart :chartData="hourlyDistribution">

                    </hourly-distribution-chart>
                </card>
            </div>

            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('report.cbDistHeader')}}
                    </h4>
                    <div>
                        <cb-distribution :data="campaignBlockDistribution">

                        </cb-distribution>
                    </div>
                </card>
            </div>
        </div>


        <!-- HVs -->
        <div class="row d-print-none" v-if="viewBy == 'group'">
            <div class="col-12">
                <hv-table ref="hvTable" :entityHID="entityHID" viewBy="group" :hvGroupHID="groupHID"
                          :autoLoading="false"
                          :title="$t('report.listHvsHeader')"
                          :description="$t('report.listHvsText')">
                </hv-table>
            </div>
        </div>


    </div>
</template>

<script>
    import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, StatsCard } from 'src/components/UIComponents'
    import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
    import DateDistributionChart from 'src/components/UIComponents/Charts/DateDistributionChart.vue'
    import ChannelStatisticCardsMsgs from 'src/components/UIComponents/ChannelStatisticCardsByMsgs.vue'
    import ScoreTrend from 'src/components/UIComponents/ScoreTrend.vue'
    import WeekDayDistributionChart from 'src/components/UIComponents/Charts/WeekDayDistributionChart.vue'
    import HourlyDistributionChart from 'src/components/UIComponents/Charts/HourlyDistributionChart.vue'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import HvTable from 'src/components/UIComponents/HVResult/HVTable.vue'
    import CBDistribution from 'src/components/UIComponents/CampaignBlock/CampaignBlockDistribution.vue'
    import ChannelStatisticCardsHvs from 'src/components/UIComponents/ChannelStatisticCardsByHvs.vue'
    import StatisticByHvsChart from 'src/components/UIComponents/Charts/StatisticByHvsChart.vue'
    import BenchmarkCard from 'src/components/UIComponents/Cards/BenchmarkCard.vue'
    import HvMaturityLevelDistributionChart from 'src/components/UIComponents/Charts/HvMaturityLevelDistributionChart.vue'


    import swal from 'sweetalert2'



    export default {
        name: 'report',
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            PPagination,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            [Progress.name]: Progress,
            CircleChartCard,
            DateDistributionChart,
            StatsCard,
            [ChannelStatisticCardsMsgs.name]: ChannelStatisticCardsMsgs,
            ChartCard,
            ScoreTrend,
            [WeekDayDistributionChart.name]: WeekDayDistributionChart,
            [HourlyDistributionChart.name]: HourlyDistributionChart,
            [HvTable.name]: HvTable,
            [CBDistribution.name]: CBDistribution,
            [ChannelStatisticCardsHvs.name]: ChannelStatisticCardsHvs,
            [StatisticByHvsChart.name]: StatisticByHvsChart,
            [BenchmarkCard.name]: BenchmarkCard,
            [HvMaturityLevelDistributionChart.name]: HvMaturityLevelDistributionChart,
        },
        props: {
            viewBy: {
                type: String,
                description: '[entity|group|hv]'
            },
            entityHID: {
                type: String,
            },
            groupHID: {
                type: String,
            },
            hvHID: {
                type: String,
            },
        },
        data() {
            return {
                reportData: {
                    name: '',
                    statistic: {},
                },
                globalBenchmarkData: null,
                //-----------
                g_pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                g_propsToSearch: ['name'],
                g_searchQuery: '',
                g_sortObject: { order: 'ascending', name: 'score' },
            };
        },
        methods: {
            initComponent(force) {
                if (this.viewBy === 'entity') {
                    this.loadEntityData(force);
                }
                if (this.viewBy === 'group') {
                    this.loadGroupData(force);
                    this.$refs.hvTable.refresh(force);
                }
                if (this.viewBy === 'hv') {
                    this.loadHvData(force);
                }
            },
            refresh(force) {
                this.initComponent(force);
            },
            loadEntityData(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.report.getEntityReportUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        vueThis.reportData = response.data;                        
                        swal.close();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        swal.close();
                        vueThis.genericErrorAlert();
                    });
            },
            loadGroupData(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                if (!this.groupHID) return;
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.report.getHvGroupReportUrl(this.entityHID), null, { params: { groupHID: this.groupHID, force: force } })
                    .then(response => {
                        vueThis.reportData = response.data;
                        swal.close();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        swal.close();
                        vueThis.genericErrorAlert();
                    });
            },
            loadHvData(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                if (!this.hvHID) return;
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.report.getHoaxVictimReportUrl(this.entityHID), null, { params: { hvHID: this.hvHID, force: force } })
                    .then(response => {
                        vueThis.reportData = response.data;
                        
                        swal.close();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        swal.close();
                        vueThis.genericErrorAlert();
                    });
            },
            goback() {
                if (window.history.length > 1) {                    
                    this.$router.go(-1);
                }
                else {
                    this.$router.push({ name: 'eReport', query: { v: 'hvs' } });
                }
            },
            loadGlobalBenchmark() {
                let vueThis = this;
                this.axios.get(this.$root.config.globalBenchmark, { withCredentials: false }).then(response => {
                    this.globalBenchmarkData = response.data;
                }).catch(function (ex) {
                    vueThis.writeEx2Log(ex);
                });
            },
            //-------------
            g_handleSortChange(e) {
                this.g_sortObject = { order: e.order, name: e.prop };
            },
            groupTableRowClassName({ row, rowIndex, }) {
                if (row.isSystem == true) {
                    return "system-group " + row.name.toLowerCase();
                }
                return '';
            },
        },
        mounted() {
            this.loadGlobalBenchmark();
        },
        computed: {
            dateDistribution() {
                if (this.reportData.statistic == undefined) return [];
                return this.reportData.statistic.dateDistribution;
            },
            weekDayDistribution() {
                if (this.reportData.statistic == undefined) return [];
                return this.reportData.statistic.weekDayDistribution;
            },
            hourlyDistribution() {
                if (this.reportData.statistic == undefined) return [];
                return this.reportData.statistic.hourlyDistribution;
            },
            campaignBlockDistribution() {
                if (this.reportData.statistic == undefined) return [];
                return this.reportData.statistic.campaignBlockDistribution;
            },
            hvMLDistribution() {
                if (this.reportData.statisticsByHvs == undefined || this.reportData.statisticsByHvs.maturityLevelDistribution == undefined) return [];
                return this.reportData.statisticsByHvs.maturityLevelDistribution;
            },
            byMessages() {
                if (this.reportData.statistic == undefined) return {};
                return this.reportData.statistic.byMessages;
            },
            byHvs() {
                if (this.reportData.statistic == undefined) return {};
                return this.reportData.statistic.byHvs;
            },
            title() {
                if (this.viewBy === 'entity') {
                    return this.$t('report.headerEntity');
                }
                if (this.viewBy === 'group') {
                    return this.$t('report.headerGroup');
                }
                if (this.viewBy === 'hv') {
                    return this.$t('report.headerHv');
                }
            },
            statisticsByHvs() {
                if (this.reportData.statisticsByHvs == undefined || this.reportData.statisticsByHvs.hvStatisticTrend == undefined) return {};
                return { chartData: this.reportData.statisticsByHvs.hvStatisticTrend };
            },

            hvIdentifire() {
                if (this.viewBy === 'hv') {
                    let data = this.reportData.email;
                    if (this.reportData.phoneNumber != undefined && this.reportData.phoneNumber.length > 0) {
                        data += " - " + this.reportData.phoneNumber;
                    }
                    return data;
                }
                return "";
            },
            globalBenchmark() {
                if (this.globalBenchmarkData == null) return { reacted: 0, compromised: 0 };
                return this.globalBenchmarkData;
            },
            subjectBenchmark() {
                if (this.byHvs == undefined || this.byHvs.countReacted == undefined || this.byHvs.countCompromised == undefined)
                    return { reacted: 0, compromised: 0 };
                return { reacted: Math.floor(this.byHvs.percentReacted * 100), compromised: Math.floor(this.byHvs.percentCompromised * 100) };
            },
            titleBenchmark() {
                if (this.viewBy === 'group') {
                    return this.$t('report.benchmark.gheaderGroup');

                }
                return this.$t('report.benchmark.gheader');
            },
            diffBenchmark() {
                return {
                    reacted: this.globalBenchmark.reacted - this.subjectBenchmark.reacted,
                    compromised: this.globalBenchmark.compromised - this.subjectBenchmark.compromised,
                };
            },
            iconBenchmark() {
                if (this.viewBy === 'group') {
                    return "fa-solid fa-user-shield";

                }
                return "fa-solid fa-building-shield";
            },
            //--------------------------
            g_queriedData() {
                if (this.reportData.groups == undefined) return [];

                let cThis = this;
                if (!this.g_searchQuery) {
                    this.g_pagination.total = this.reportData.groups.length;
                    return this.reportData.groups.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); }).slice(this.g_from, this.g_to);
                }
                let result = this.reportData.groups
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.g_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.g_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.g_pagination.total = result.length;
                return result.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); }).slice(this.g_from, this.g_to);
            },
            g_to() {
                let highBound = this.g_from + this.g_pagination.perPage;
                if (this.g_total < highBound) {
                    highBound = this.g_total;
                }
                return highBound;
            },
            g_from() {
                if (this.g_pagination.total == 0) return -1;
                return this.g_pagination.perPage * (this.g_pagination.currentPage - 1);
            },
            g_total() {
                return this.g_pagination.total;
            },
        },
    }
</script>

<style scoped>
    .dash-icon {
        height: 3.6rem;
        min-width: 3.6rem;
        width: 3.6rem;
        background-color: red;
        border-width: 1px;
        border-radius: 30px;
        margin: 10px 1px;
        font-size: 20px;
        position: relative;
        color: #fff;
        background-color: #2ba9cd;
        border-color: #10707f;
    }

        .dash-icon span {
            position: absolute;
            top: 50%;
            left: 55%;
            -webkit-transform: translate(-12px,-12px);
            transform: translate(-12px,-12px);
            line-height: 1.5626rem;
            width: 24px;
            font-size: 1.325rem;
        }
</style>