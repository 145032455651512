<template>
    <div>
        <report viewBy="group" :entityHID="entityHID" :groupHID="groupHID" ref="groupReportView"></report>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Modal, Card, Pagination } from 'src/components/UIComponents'
    import Report from 'src/components/UIComponents/Report.vue'

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            Report,
        },
        data() {
            return {
                entityHID: '',
                groupHID: '',
            }
        },
        methods: {
            initComponent: function () {
                setTimeout(() => this.$refs.groupReportView.refresh(), 200);                
            },
            //----------
        },
        computed: {

        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    if (this.entityHID != this.$route.params.ehid) {
                        this.$router.push({ name: 'eReport' });
                    }
                    else {
                        this.entityHID = this.$route.params.ehid;
                        this.groupHID = this.$route.params.ghid;
                        this.initComponent();
                    }
                }
            }
        },
        mounted: function () {
            this.initComponent();
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            this.groupHID = this.$route.params.ghid;
        }
    }
</script>

<style scoped>
</style>