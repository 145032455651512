<template>
    <div ref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'beList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">
                        {{billingEntity.name}}
                    </p>
                    <p class="category">{{ $t('beViews.beDetail.header')}}</p>
                </div>
            </div>
            <div class="col text-right">
                <el-tooltip :content="$t('beViews.beDetail.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh()">
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <div class="card" data-v-step="step-beedit">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title">{{ $t('beViews.beDetail.generalDetails.header')}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <fg-input type="text" :label="$t('beViews.beDetail.generalDetails.form.orgName.label')" :placeholder="$t('beViews.beDetail.generalDetails.form.orgName.placeholder')" name="orgName"
                                              v-validate="modelValidations.orgName" :error="getError('orgName')"
                                              v-model="beName">
                                    </fg-input>
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-center">
                                <div class="col-12 col-md-auto text-center">
                                    <p-button native-type="submit" type="info" class="w-100" @click.prevent="beNameValidate" data-v-step="step-beupdate">
                                        {{ $t('beViews.beDetail.generalDetails.btnUpdate')}}
                                    </p-button>
                                </div>
                            </div>
                        </form>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title" data-v-step="step-bebilling">{{ $t('beViews.beDetail.updateProfile.header')}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <billingProfileForm :billingProfile="currentProfile" ref="billingForm" @validationFinished="updateProfile"></billingProfileForm>

                            <div class="row mt-3 justify-content-center">
                                <div class="col-12 col-md-auto text-center">
                                    <p-button native-type="submit" type="info" class="w-100" @click.prevent="validate" data-v-step="step-bebillingupdate">
                                        {{ $t('beViews.beDetail.updateProfile.btnUpdate')}}
                                    </p-button>
                                </div>
                            </div>
                        </form>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import swal from 'sweetalert2'
    import BillingProfileForm from 'src/components/UIComponents/BillingProfileForm.vue'

    export default {
        components: {
            BillingProfileForm,
        },
        data() {
            return {
                billingEntityHID: '',
                billingEntity: {
                    name: "",
                },
                beName: "",
                billingProfiles: [],
                currentProfile: {},
                billingProfileFormIsValid: false,
                onTheFlyValidation: false,
                modelValidations: {
                    orgName: {
                        required: true,
                        max: this.$root.config.maxBillingEntityNameLength,
                    },
                },
            }
        },
        methods: {
            validate() {
                this.$refs.billingForm.validate();
            },
            updateProfile(result) {
                if (!result) return;
                let vueThis = this;
                this.axios.post(this.$root.config.billingEntity.billingProfileUpdateUrl(this.billingEntityHID), this.currentProfile)
                    .then(response => {
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('beViews.beDetail.updateProfile.success'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('beViews.beDetail.updateProfile.failed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            loadBillingProfiles() {
                let vueThis = this;
                if (this.billingEntityHID == 0) return;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.billingEntity.getBillingProfilesUrl(this.billingEntityHID), null)
                    .then(response => {
                        loader.hide();
                        this.billingProfiles = response.data;
                        if (this.billingProfiles.length > 0) {
                            this.currentProfile = this.billingProfiles[0];
                            this.billingProfileFormIsValid = true;
                            this.onTheFlyValidation = true;
                        }
                        
                    }).catch(function (ex) {
                        vueThis.writeEx2Log(ex);
                        loader.hide();
                        vueThis.genericErrorAlert();
                    });
            },
            refresh() {
                this.billingEntityHID = this.$route.params.behid;
                this.billingEntity = this.$store.getters.userState.billingEntities.find(c => c.hid == this.billingEntityHID);
                this.beName = this.billingEntity.name;
                this.loadBillingProfiles();
            },
            getError(fieldName) {
                let familierName = this.$t('beViews.beDetail.generalDetails.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                if (fieldName == 'orgName') {
                    let error = this.errors.items.find(c => c.field == 'orgName')
                    if (error != undefined && error.rule == 'max') {
                        return this.$t('beViews.beDetail.generalDetails.form.orgName.errLen', [this.$root.config.maxBillingEntityNameLength]);
                    }
                }
                return errMsg;
            },
            beNameValidate() {
                let vueThis = this;
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        vueThis.axios.post(vueThis.$root.config.billingEntity.renameBillingEntityUrl(vueThis.billingEntityHID), null, { params: { name: vueThis.beName } })
                            .then(response => {
                                vueThis.$store.dispatch('updateUser');
                                vueThis.billingEntity.name = vueThis.beName;
                                swal.fire({
                                    icon: 'success',
                                    html: vueThis.$t('beViews.beDetail.generalDetails.updateSuccess'),
                                    confirmButtonClass: 'btn btn-success btn-fill',
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    let respMsg = error.response.data;
                                    swal.fire({
                                        title: vueThis.$t('beViews.beDetail.generalDetails.updateFailed'),
                                        text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', [vueThis.billingEntity.name]),
                                        icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                })
            },
        },
        mounted: function () {
            this.loadBillingProfiles();
        },
        created() {
            this.billingEntityHID = this.$route.params.behid;
            this.billingEntity = this.$store.getters.userState.billingEntities.find(c => c.hid == this.billingEntityHID);
            this.beName = this.billingEntity.name;
        }
    }
</script>

<style scoped>
</style>