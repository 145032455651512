<template>
    <div class="lazy-ai">
        <div ref="lazyAI">
            <div class="row">
                <div class="col">
                    <h2>{{$t('entityViews.lai.cc.header')}}</h2>
                </div>
                <div class="col text-right">
                    <el-tooltip :content="$t('entityViews.lai.cc.btnRefreshTooltip')" :open-delay="300" placement="top">
                        <p-button type="default" class="mr-2" icon @click="refresh()">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </p-button>
                    </el-tooltip>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-3">
                    <control-card :type="lazyAIUI.statusColor" title="LazyAI" :small-title="lazyAIUI.statusTitle" :coloredSmallTitle="true">
                        <div slot="icon" class="d-flex justify-content-center">
                            <img class="img-fluid" :src="lazyAIUI.iconUrl" width="150" />
                        </div>
                        <div class="row" slot="footer">
                            <div class="col-12 text-center">
                                <p-button type="success" block @click="changeLazyAIStatus(1)" v-if="lazyAIUI.showStartBtn">
                                    <i class="fa fa-play mr-1" aria-hidden="true"></i> {{$t('entityViews.lai.cc.cCard.btnStart')}}
                                </p-button>
                            </div>
                            <div class="col-12  text-center">
                                <p-button type="warning" block @click="changeLazyAIStatus(0)" v-if="lazyAIUI.showPauseBtn">
                                    <i class="fa fa-pause mr-1" aria-hidden="true"></i> {{$t('entityViews.lai.cc.cCard.btnPause')}}
                                </p-button>
                            </div>
                            <div class="col-12  text-center">
                                <p-button type="success" block @click="changeLazyAIStatus(2)" v-if="lazyAIUI.showResumeBtn">
                                    <i class="fa fa-play mr-1" aria-hidden="true"></i> {{$t('entityViews.lai.cc.cCard.btnResume')}}
                                </p-button>
                            </div>
                            <div class="col-12 text-center mt-3">
                                <p-button type="danger" block @click="changeLazyAIStatus(-1)" :disabled="lazyAIUI.disableStopBtn">
                                    <i class="fa fa-stop mr-1" aria-hidden="true"></i> {{$t('entityViews.lai.cc.cCard.btnStop')}}
                                </p-button>
                            </div>
                        </div>
                    </control-card>
                </div>
                <div class="col-12 col-xl-9 d-none d-md-block">
                    <card>
                        <slot name="header">
                            <h5 class="">{{$t('entityViews.lai.cc.statusCard.header')}}</h5>
                        </slot>
                        <stepper :steps="lazyAISteps" :disabled="disableLAIProgress"></stepper>
                    </card>

                </div>
            </div>

        </div>

        <!-- LAi Settings desc -->
        <div class="row lai-settings-card">
            <div class="col-12">
                <card>
                    <slot name="header">
                        <h5 class="">{{$t('entityViews.lai.settings.header')}}</h5>
                        <p>{{$t('entityViews.lai.settings.description')}}</p>
                    </slot>
                    <div class="row lai-settings-row align-items-center">
                        <div class="col-12 col-sm col-label align-items-center">
                            <div class="icon mr-3">
                                <span class="fa fa-gauge-high fa-2x"></span>
                            </div>
                            <span>{{$t('entityViews.lai.settings.velocity.iconText')}}</span>
                        </div>
                        <div class="col-12 col-sm col-desc"> {{$t('entityViews.lai.settings.velocity.description')}}</div>
                    </div>
                    <div class="row lai-settings-row align-items-center">
                        <div class="col-12 col-sm col-label align-items-center">
                            <div class="icon mr-3">
                                <span class="fa fa-language fa-2x"></span>
                            </div>
                            <span class="align-middle">{{$t('entityViews.lai.settings.languages.iconText')}}</span>
                        </div>
                        <div class="col-12 col-sm col-desc"> {{$t('entityViews.lai.settings.languages.description')}}</div>
                    </div>
                    <div class="row lai-settings-row align-items-center">
                        <div class="col-12 col-sm col-label align-items-center">
                            <div class="icon mr-3">
                                <span class="fa fa-industry fa-2x"></span>
                            </div>
                            <span>{{$t('entityViews.lai.settings.themesIndustry.iconText')}}</span>
                        </div>
                        <div class="col-12 col-sm col-desc"> {{$t('entityViews.lai.settings.themesIndustry.description')}}</div>
                    </div>
                    <div class="row lai-settings-row align-items-center">
                        <div class="col-12 col-sm col-label align-items-center">
                            <div class="icon mr-3">
                                <span class="fa fa-microchip fa-2x"></span>
                            </div> 
                            <span>{{$t('entityViews.lai.settings.themesTechnology.iconText')}}</span>
                        </div>
                        <div class="col-12 col-sm col-desc"> {{$t('entityViews.lai.settings.themesTechnology.description')}}</div>
                    </div>
                </card>
            </div>
        </div>

        <!-- LAI Velocity -->
        <div class="row">
            <div class="col-12 col-md-3">
                <control-card :small-title="$t('entityViews.lai.settings.velocity.iconText')" icon="fa fa-gauge-high" :showSeparator="false" :sizeSmallTitle="160">
                </control-card>
            </div>
            <div class="col-12 col-md-9">
                <lazyAI-setup-card :modes="lazyAIModes" :mode="lazyAI.mode.name" @changeMode="changeLazyAIMode" size="sm">
                </lazyAI-setup-card>
            </div>
        </div>

        <!-- LAI Languages -->
        <div class="row">
            <div class="col-12 col-md-3">
                <control-card :small-title="$t('entityViews.lai.settings.languages.iconText')" icon="fa fa-language" :showSeparator="false" :sizeSmallTitle="160">
                </control-card>
            </div>
            <div class="col-12 col-md-9">
                <circle-select-card :items="lazyAILanguages" @changeLang="changeLazyAILang" size="sm">
                </circle-select-card>
            </div>
        </div>

        <!-- Themes - industry -->
        <div class="row">
            <div class="col-12 col-md-3">
                <control-card :small-title="$t('entityViews.lai.settings.themesIndustry.iconText')" icon="fa fa-industry" :showSeparator="false" :sizeSmallTitle="160">
                </control-card>
            </div>
            <div class="col-12 col-md-9">
                <circle-select-card :items="lazyAIThemesIndustry" @changeLang="changeLazyAITheme" size="sm">
                </circle-select-card>
            </div>
        </div>

        <!-- Themes - technology -->
        <div class="row">
            <div class="col-12 col-md-3">
                <control-card :small-title="$t('entityViews.lai.settings.themesTechnology.iconText')" icon="fa fa-microchip" :showSeparator="false" :sizeSmallTitle="160">
                </control-card>
            </div>
            <div class="col-12 col-md-9">
                <circle-select-card :items="lazyAIThemesTechnology" @changeLang="changeLazyAITheme" size="sm">
                </circle-select-card>
            </div>
        </div>

    </div>
</template>

<script>
    import { LazyAITools, ThemeTools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, ControlCard, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import LazyAISetupCard from 'src/components/UIComponents/Cards/LazyAISetupCard.vue'
    import Stepper from 'src/components/UIComponents/Stepper/Stepper.vue'
    import CircleSelectCard from 'src/components/UIComponents/Cards/CircleSelectCard.vue'



    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            [Progress.name]: Progress,
            ControlCard,
            LazyAISetupCard,
            StatsCard,
            [Stepper.name]: Stepper,
            CircleSelectCard
        },
        data() {
            let vueThis = this;
            return {
                entityHID: null,
                manualControl: false,
                lazyAIChecker: null,
                lazyAI: {
                    status: '',
                    mode: {},
                    setupCheck: {},
                },
            }
        },
        computed: {
            lazyAIUI() {
                let result = {
                    showPauseBtn: false,
                    showStartBtn: false,
                    disableStopBtn: false,
                    showResumeBtn: false,
                    statusTitle: '',
                    statusColor: 'info',
                    iconUrl: '/static/img/lazyai/stopped.png',
                };

                if (this.lazyAI.setupCheck.isSubscriptionSizeExhausted) {
                    result.statusColor = 'danger';
                    result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.pausedUnavailableLabel');
                    result.disableStopBtn = true;
                    result.iconUrl = '/static/img/lazyai/error.png';
                }
                else if (this.lazyAI.setupCheck.hvCount == 0 || this.lazyAI.setupCheck.domainValidCount == 0) {
                    result.statusColor = 'danger';
                    result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.unavailablelabel');
                    result.disableStopBtn = true;
                    result.iconUrl = '/static/img/lazyai/error.png';
                }
                else {
                    if (this.lazyAI.status === LazyAITools.Status_Running) {
                        result.showPauseBtn = true;
                        result.statusColor = 'success';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.runningLabel');
                        result.iconUrl = '/static/img/lazyai/running.png';
                    }
                    if (this.lazyAI.status === LazyAITools.Status_Stopped) {
                        result.showStartBtn = true;
                        result.statusColor = 'default';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.stoppedLabel');
                        result.disableStopBtn = true;
                    }
                    if (this.lazyAI.status === LazyAITools.Status_Paused) {
                        result.showResumeBtn = true;
                        result.statusColor = 'warning';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.pausedLabel');
                        result.iconUrl = '/static/img/lazyai/paused.png';
                    }

                    if (this.lazyAI.status === LazyAITools.Status_Starting) {
                        result.statusColor = 'info';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.startingLabel');
                        result.disableStopBtn = true;
                        result.iconUrl = '/static/img/lazyai/status-change.png';
                    }
                    if (this.lazyAI.status === LazyAITools.Status_Stopping) {
                        result.statusColor = 'info';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.stoppingLabel');
                        result.disableStopBtn = true;
                        result.iconUrl = '/static/img/lazyai/status-change.png';
                    }
                    if (this.lazyAI.status === LazyAITools.Status_Pausing) {
                        result.statusColor = 'info';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.pausingLabel');
                        result.disableStopBtn = true;
                        result.iconUrl = '/static/img/lazyai/status-change.png';
                    }
                    if (this.lazyAI.status === LazyAITools.Status_Resuming) {
                        result.statusColor = 'info';
                        result.statusTitle = this.$t('entityViews.lai.cc.cCard.status.resumingLabel');
                        result.disableStopBtn = true;
                        result.iconUrl = '/static/img/lazyai/status-change.png';
                    }
                }
                return result;
            },
            lazyAISteps() {
                let steps = [
                    {
                        title: this.$t('entityViews.lai.cc.cCard.laiSteps.nHvDetect.header1'),
                        icon: 'fa-solid fa-user-gear',
                        status: 'default',
                        lineClass: 'new-emp-detect',
                        description: this.$t('entityViews.lai.cc.cCard.laiSteps.nHvDetect.text1'),
                    },
                    {
                        title: this.$t('entityViews.lai.cc.cCard.laiSteps.hvClass.header'),
                        icon: 'fa-solid fa-arrow-down-short-wide',
                        status: 'default',
                        description: this.$t('entityViews.lai.cc.cCard.laiSteps.hvClass.text'),
                    },
                    {
                        title: this.$t('entityViews.lai.cc.cCard.laiSteps.attackSim.header'),
                        icon: 'fa-solid fa-satellite-dish',
                        status: 'default',
                        description: this.$t('entityViews.lai.cc.cCard.laiSteps.attackSim.text'),
                    },
                    {
                        title: this.$t('entityViews.lai.cc.cCard.laiSteps.dataGather.header'),
                        icon: 'fa-solid fa-house-signal',
                        status: 'default',
                        description: this.$t('entityViews.lai.cc.cCard.laiSteps.dataGather.text'),
                    },
                ];
                //------------------ detection
                switch (this.lazyAI.hvDetectionStatus) {
                    case LazyAITools.HvDetectionStatus.Active:
                        steps[0].status = "active";
                        break;
                    case LazyAITools.HvDetectionStatus.Complete:
                        steps[0].status = "complete";
                        steps[0].title = this.$t('entityViews.lai.cc.cCard.laiSteps.nHvDetect.header2');
                        steps[0].icon = "fa-solid fa-user-check";
                        steps[0].description = this.$t('entityViews.lai.cc.cCard.laiSteps.nHvDetect.text2');
                        break;
                }

                //------------------ training
                switch (this.lazyAI.hvTrainingStatus) {
                    case LazyAITools.HvTrainingStatus.EmpClassification:
                        steps[1].status = "active";
                        steps[2].status = "default";
                        steps[3].status = "default";
                        break;
                    case LazyAITools.HvTrainingStatus.AttacksSimulations:
                        steps[1].status = "complete";
                        steps[2].status = "active";
                        steps[3].status = "default";
                        break;
                    case LazyAITools.HvTrainingStatus.DataGathering:
                        steps[1].status = "complete";
                        steps[2].status = "complete";
                        steps[3].status = "active";
                        break;
                }


                return steps;
            },
            disableLAIProgress() {
                return this.lazyAI.status !== LazyAITools.Status_Running;
            },
            lazyAIModes() {
                return [
                    { mode: 'm3Mode', title: this.$t('entityViews.lai.settings.velocity.modes.m3Mode.header'), text: this.$t('entityViews.lai.settings.velocity.modes.m3Mode.text'), icon: 'check-icon fa-solid fa-plane' },
                    { mode: 'm2Mode', title: this.$t('entityViews.lai.settings.velocity.modes.m2Mode.header'), text: this.$t('entityViews.lai.settings.velocity.modes.m2Mode.text'), icon: 'check-icon fa-solid fa-jet-fighter' },
                    { mode: 'm1Mode', title: this.$t('entityViews.lai.settings.velocity.modes.m1Mode.header'), text: this.$t('entityViews.lai.settings.velocity.modes.m1Mode.text'), icon: 'check-icon fa-solid fa-shuttle-space' },
                ];
            },
            lazyAILanguages() {
                let vueThis = this;
                if (this.lazyAI == undefined || this.lazyAI.availableLanguages == undefined)
                    return [];

                return this.lazyAI.availableLanguages.map((l) => {
                    return {
                        key: l,
                        icon: null, 
                        iconText: l.toUpperCase(),
                        isSelected: this.lazyAI.languages.includes(l),
                        label: this.$t('entityViews.lai.settings.languages.' + l +'.label')
                    }
                });
            },
            lazyAIThemesIndustry() {
                let vueThis = this;
                if (this.lazyAI == undefined || this.lazyAI.availableThemes == undefined)
                    return [];

                return this.lazyAI.availableThemes.filter(t => t.category == ThemeTools.Category_Industry).map((t) => {
                    return {
                        key: t.id,
                        icon: this.$t('themes.' + t.name + '.icon'),
                        iconText: null,
                        isSelected: this.lazyAI.themes.includes(t.id),
                        label: this.$t('themes.' + t.name + '.label')
                    }
                });
            },
            lazyAIThemesTechnology() {
                let vueThis = this;
                if (this.lazyAI == undefined || this.lazyAI.availableThemes == undefined)
                    return [];

                return this.lazyAI.availableThemes.filter(t => t.category == ThemeTools.Category_Technology).map((t) => {
                    return {
                        key: t.id,
                        icon: this.$t('themes.' + t.name + '.icon'),
                        iconText: null,
                        isSelected: this.lazyAI.themes.includes(t.id),
                        label: this.$t('themes.' + t.name + '.label')
                    }
                });
            },
        },
        methods: {
            initComponent() {
                this.manualControl = this.$store.getters.appState.entity.manualControl;

                this.loadLazyAI();
            },
            loadLazyAI() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.lazyai.getLazyAIStatusUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.lazyAI = response.data;    
                        if (vueThis.$store.getters.appState.entity.lazyAIStatus != response.data.status) {
                            vueThis.$store.dispatch('updateUser');
                        }
                        swal.close();
                    }).catch(function (error) {
                        swal.close();
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            lAIChecker() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.lazyai.getLazyAIStatusUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.lazyAI = response.data;
                        if (vueThis.$store.getters.appState.entity.lazyAIStatus != response.data.status) {
                            vueThis.$store.dispatch('updateUser');
                        }                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                    });
            },
            refresh() {
                this.loadLazyAI();
            },
            changeLazyAIStatus(action) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                switch (action) {
                    case 0:
                        cfg.title = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionPause.title');
                        cfg.text = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionPause.text');
                        break;
                    case 1:
                        cfg.title = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionStart.title');
                        cfg.text = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionStart.text');
                        break;
                    case 2:
                        cfg.title = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionResume.title');
                        cfg.text = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionResume.text');
                        break;
                    case -1:
                        cfg.title = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionDisableStop.title');
                        cfg.text = vueThis.$t('entityViews.lai.cc.cCard.actionDialog.actionDisableStop.text');
                        break;
                    default:
                        return;
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.lazyai.changeLazyAIStatusUrl(this.entityHID), null, { params: { action: action } })
                            .then(response => {
                                vueThis.$store.dispatch('updateUser');
                                vueThis.loadLazyAI();
                                let title = ``;
                                switch (action) {
                                    case 0:
                                        title = vueThis.$t('entityViews.lai.cc.cCard.alert.pausingLabel');
                                        break;
                                    case 1:
                                        title = vueThis.$t('entityViews.lai.cc.cCard.alert.startingLabel');
                                        break;
                                    case 2:
                                        title = vueThis.$t('entityViews.lai.cc.cCard.alert.resumingLabel');
                                        break;
                                    case -1:
                                        title = vueThis.$t('entityViews.lai.cc.cCard.alert.stoppingLabel');
                                        break;
                                }
                                swal.fire({
                                    icon: 'success',
                                    title: title,
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                                if (vueThis.lazyAIChecker == null) {
                                    vueThis.lazyAIChecker = setInterval(vueThis.lAIChecker, 15000);
                                }
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    let title = vueThis.$t('entityViews.lai.cc.cCard.generalErrorTitle');
                                    let respMsg = error.response.data;
                                    title =  vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                    swal.fire({
                                        icon: 'error',
                                        title: title,
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            changeLazyAIMode(mode) {
                this.updateLazyAISettings({ mode: mode });
            },
            updateLazyAISettings(obj) {
                let vueThis = this;
                this.axios.post(this.$root.config.entity.lazyai.updateLazyAISettingsUrl(this.entityHID), obj)
                    .then(response => {    
                        vueThis.loadLazyAI();
                        //swal.fire({
                        //    icon: 'success',
                        //    title: vueThis.$t('entityViews.lai.updateLAIAlert.success.msg'),
                        //    text: vueThis.$t('entityViews.lai.updateLAIAlert.success.text'),
                        //    showConfirmButton: true,
                        //    timer: vueThis.$root.config.alertTimer15,
                        //    timerProgressBar: true,
                        //}).then(function () {
                        //    vueThis.loadLazyAI();
                        //});
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('entityViews.lai.updateLAIAlert.failed.msg'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },    
            changeLazyAILang(lang, selected) {
                this.updateLazyAISettings({ languageChange: { language: lang, isSelected: selected } });
            },
            changeLazyAITheme(themeID, selected) {
                this.updateLazyAISettings({ themeChange: { ID: themeID, isSelected: selected } });
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    this.initComponent();                    
                }
            },
        },
        created() {
            this.entityHID = this.$route.params.ehid;
        },
        beforeRouteLeave(to, from, next) {
            console.log("stop AI checker");
            clearInterval(this.lazyAIChecker);
            next();
        },
    }

</script>

<style scoped>
</style>