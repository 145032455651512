<template>
    <div ref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'beList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">
                        {{billingEntity.name}}
                    </p>
                    <p class="category">{{ $t('beViews.subscriptions.header')}}</p>
                </div>
            </div>
            <div class="col text-right">
                <el-tooltip :content="$t('beViews.subscriptions.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh()">
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4 col-12">
                <stats-card type="success" data-v-step="step-stype"
                            :title="activeSub.type"
                            :small-title="$t('beViews.subscriptions.cardSub')"
                            icon="fa-solid fa-cubes-stacked">
                </stats-card>
            </div>
            <div class="col-sm-4 col-12">
                <stats-card type="info" data-v-step="step-sexp"
                            :title="activeSub.endDateUtcUI"
                            :small-title="$t('beViews.subscriptions.cardExp')"
                            icon="fa-solid fa-calendar-days">
                </stats-card>
            </div>
            <div class="col-sm-4 col-12 ">
                <stats-card type="info" data-v-step="step-shvlimit"
                            :title="activeSub.sizeUI"
                            :small-title="$t('beViews.subscriptions.cardHvLimit')"
                            icon="fa-solid fa-users-between-lines">
                </stats-card>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card" data-v-step="step-history">
                    <div class="card-header">
                        <h4 class="card-title">{{$t('beViews.subscriptions.history.header')}}</h4>
                    </div>
                    <div class="card-body">
                        <!--toolbar-->
                        <div class="row toolbar">
                            <div class="col-12">

                                <!-- pro B2B neni potreba -->
                                <!--<div class="">
                                    <el-tooltip :content="$t('beViews.subscriptions.history.toolbar.order.tooltip')" :open-delay="300" placement="top" v-if="showBtnNewSub">
                                        <p-button type="success" @click="handleNewSubscription">
                                            <i class="fa-solid fa-cart-shopping"></i>
                                            {{$t('beViews.subscriptions.history.toolbar.order.btnLabel')}}
                                        </p-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('beViews.subscriptions.history.toolbar.increase.tooltip')" :open-delay="300" placement="top" v-if="showBtnIncreaseSub">
                                        <p-button type="info" @click="handleIncreaseSubscription">
                                            <i class="fa-solid fa-cart-plus"></i>
                                            {{$t('beViews.subscriptions.history.toolbar.increase.btnLabel')}}

                                        </p-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('beViews.subscriptions.history.toolbar.trial.tooltip')" :open-delay="300" placement="top" v-if="showBtnTrialSub">
                                        <p-button type="warning" @click="handleTrialSubscription">
                                            <i class="fa-solid fa-cart-shopping"></i>
                                            {{$t('beViews.subscriptions.history.toolbar.trial.btnLabel')}}

                                        </p-button>
                                    </el-tooltip>
                                </div>-->

                            </div>
                        </div>
                        <!-- header -->
                        <div class="row justify-content-between">
                            <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-2">
                                <el-select class="select-default float-sm-left w-100"
                                           v-model="pagination.perPage">
                                    <el-option class="select-default"
                                               v-for="item in pagination.perPageOptions"
                                               :key="item"
                                               :label="item"
                                               :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                <div class="float-sm-right">
                                    <fg-input class="input-sm"
                                              :placeholder="$t('beViews.subscriptions.history.searchPlaceholder')"
                                              v-model="searchQuery"
                                              addon-right-icon="nc-icon nc-zoom-split">
                                    </fg-input>
                                </div>
                            </div>
                        </div>
                        <!-- table -->
                        <div class="row">
                            <div class="col-12" ref="table">
                                <el-table :data="queriedData" :empty-text="$t('beViews.subscriptions.history.table.noData')"
                                          :default-sort="{ prop: 'uid', order: 'descending'}"
                                          @sort-change="handleSortChange" ref="subsTable">
                                    <el-table-column type="expand">
                                        <template slot-scope="props">
                                            <div class="row text-center">
                                                <div class="col">
                                                    <b>{{$t('beViews.subscriptions.history.table.colDetail.priceIncVAT')}}</b>
                                                    <br />
                                                    {{NumberFormat(props.row.price)}} &euro;
                                                    <span v-if="props.row.currencyRatioToEur != null">
                                                        <br />
                                                        <span>({{NumberFormat(props.row.otherPrice)}} CZK)</span>
                                                    </span>
                                                </div>
                                                <div class="col">
                                                    <b>{{$t('beViews.subscriptions.history.table.colDetail.vat')}}</b>
                                                    <br />
                                                    {{Math.round(props.row.vat * 100)}} %
                                                    <span v-if="props.row.vat > 0">
                                                        {{NumberFormat(props.row.vatAmount)}} &euro;
                                                        <span v-if="props.row.currencyRatioToEur != null">
                                                            <br />
                                                            <span>({{NumberFormat(props.row.otherVatAmount)}} CZK)</span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="col" v-if="props.row.discount > 0">
                                                    <b>{{$t('beViews.subscriptions.history.table.colDetail.discount')}}</b>
                                                    <br />
                                                    {{Math.round(props.row.discount * 100)}} %
                                                    {{NumberFormat(props.row.discountAmount)}} &euro;
                                                    <span v-if="props.row.currencyRatioToEur != null">
                                                        <br />
                                                        <span>({{NumberFormat(props.row.otherDiscountAmount)}} CZK)</span>
                                                    </span>
                                                </div>
                                                <div class="col">
                                                    <b>{{$t('beViews.subscriptions.history.table.colDetail.subsOrderedAt')}}</b>
                                                    <br />
                                                    {{props.row.timeStampUtc | moment("DD.MM.YYYY HH:mm")}}
                                                </div>
                                                <div class="col" v-if="props.row.manualControl">
                                                    <b> {{$t('beViews.subscriptions.history.table.colDetail.maxConcurrentCamps')}}</b>
                                                    <br />
                                                    {{props.row.maxConcurrentCampaigns}}
                                                </div>
                                                <div class="col">
                                                    <a :href="props.row.dwnlnk" v-if="props.row.hasInvoice && (props.row.isPaid || props.row.isClosed || props.row.isActive) && !props.row.isTrial" target="_blank">
                                                        <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                                        {{$t('beViews.subscriptions.history.table.colDetail.invoice')}}
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="uid" sortable="custom" :label="$t('beViews.subscriptions.history.table.colUID')">
                                    </el-table-column>
                                    <el-table-column prop="dateFrom" sortable="custom" :label="$t('beViews.subscriptions.history.table.colFrom')" align="center">
                                        <template slot-scope="props">
                                            {{props.row.dateFrom | moment("DD.MM.YYYY")}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="dateTo" sortable="custom" :label="$t('beViews.subscriptions.history.table.colTo')" align="center">
                                        <template slot-scope="props">
                                            {{props.row.dateTo | moment("DD.MM.YYYY")}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="type" sortable="custom" :label="$t('beViews.subscriptions.history.table.colType')" align="center">
                                    </el-table-column>
                                    <el-table-column prop="size" sortable="custom" :label="$t('beViews.subscriptions.history.table.colLimit')" align="center">
                                    </el-table-column>
                                    <el-table-column prop="status" sortable="custom" :label="$t('beViews.subscriptions.history.table.colStatus')" align="center">
                                    </el-table-column>
                                    <el-table-column :width="160" class-name="td-actions" align="center">
                                        <template slot="header" slot-scope="scope">
                                            <span data-v-step="step-actions">{{$t('beViews.subscriptions.history.table.colActions.header')}}</span>
                                        </template>
                                        <template slot-scope="props">
                                            <!-- pro B2B neni potreba -->
                                            <!--<el-tooltip :content="$t('beViews.subscriptions.history.table.colActions.btnCancel')" :open-delay="300" placement="top" v-if="props.row.isOrdered">
                                                <p-button type="danger" size="sm" icon @click="cancelSub(props.row.hid)">
                                                    <i class="fa-solid fa-trash-can"></i>
                                                </p-button>
                                            </el-tooltip>
                                            <el-tooltip :content="$t('beViews.subscriptions.history.table.colActions.btnPay')" :open-delay="300" placement="top" v-if="props.row.isOrdered">
                                                <p-button type="warning" size="sm" icon @click="paySub(props.row.hid)">
                                                    <i class="fa-solid fa-credit-card"></i>
                                                </p-button>
                                            </el-tooltip>-->
                                            <el-tooltip :content="$t('beViews.subscriptions.history.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                <p-button type="default" size="sm" icon @click="expandRow(props.row)">
                                                    <i class="fa-solid fa-circle-info"></i>
                                                </p-button>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <!-- paging -->
                        <div class="row">
                            <div class="col-sm-6 pagination-info">
                                <p class="category">
                                    {{$t('beViews.subscriptions.history.pagingInfo', [from + 1, to, total])}}
                                </p>
                            </div>
                            <div class="col-sm-6">
                                <p-pagination class="pull-right"
                                              v-model="pagination.currentPage"
                                              :per-page="pagination.perPage"
                                              :total="pagination.total">
                                </p-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <card>
                    <div slot="header">
                        <h4 class="card-title">{{ $t('beViews.subscriptions.moreCard.header')}}</h4>
                    </div>
                    <div>
                        <p>
                            {{ $t('beViews.subscriptions.moreCard.text')}}
                        </p>
                        <p>
                            <a href="mailto:info@lazyphish.com">info@lazyphish.com</a>
                        </p>
                    </div>
                </card>
            </div>
        </div>

        <!-- new and increase subs -->
        <newinc-subscription :show="modals.newSubscriptionModal" :subPricing="subPricing" :activeSub="activeSubForWizard"
                          @hide="modalSubscriptionHide" :billingEntityHID="billingEntityHID"
                          @finish="modalSubscriptionFinish" :increaseSub="modals.increaseSub">
        </newinc-subscription>

    </div>
</template>

<script>
    import { SubscriptionTools, Tools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Card } from 'src/components/UIComponents'
    import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import NewIncSubscription from 'src/components/UIComponents/ModalDialog/NewIncSubscription'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            StatsCard,
            [NewIncSubscription.name]: NewIncSubscription,
        },
        data() {
            return {
                billingEntityHID: '',
                billingEntity: {
                    name:"",
                },
                isTrialAvailable: false,
                subscriptions: [],
                modals: {
                    newSubscriptionModal: false,
                    increaseSub: false,
                },
                //-------------------
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                propsToSearch: ['uid', 'dateFrom', 'dateTo', 'size', 'type', 'status'],
                searchQuery: '',
                sortObject: { order: 'descending', name: 'uid' },
                subPricing:[],
                eurCzkRatio: 27,
                emptySub: {
                    startDateUtc: "",
                    endDateUtc: "",
                    size: 0,
                    type: "",
                    typeID: 0,
                    price: 0,
                    maxCampaigns: "0",
                    vatRatio: 0,
                    pricePerEmp: 0,
                },
            }
        },
        methods: {
            initComponent: function () {
                this.billingEntityHID = this.$route.params.behid;
                this.billingEntity = this.$store.getters.userState.billingEntities.find(c => c.hid == this.billingEntityHID);
                this.loadSubscriptions();
            },
            loadSubscriptions() {
                let vueThis = this;
                let accountHID = this.$store.getters.userState.accountHID;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.billingEntity.getSubscriptionsUrl(this.billingEntityHID), null, { params: { accountHID: accountHID } })
                    .then(response => {
                        loader.hide();
                        vueThis.isTrialAvailable = response.data.isTrialAvailable;
                        vueThis.subPricing = [
                            { amount: 1, price: response.data.pricePerEmp },
                        ];
                        vueThis.subscriptions = response.data.subscriptions;
                        vueThis.subscriptions.forEach(function (item) {
                            item.dwnlnk = vueThis.$root.config.billingEntity.getDownloadInvoicesForSubscriptionUrl(vueThis.billingEntityHID) + "?subscriptionHID=" + item.hid;
                            item.isOrdered = item.status == SubscriptionTools.Status_Ordered;
                            item.isPaid = item.status == SubscriptionTools.Status_Paid;
                            item.isClosed = item.status == SubscriptionTools.Status_Closed;
                            item.isActive = item.status == SubscriptionTools.Status_Active;
                            item.isCancelled = item.status == SubscriptionTools.Status_Cancelled;
                            item.isTrial = item.typeID == SubscriptionTools.Type_TrialId;

                        });                            
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            handleNewSubscription() {
                this.modals.newSubscriptionModal = true;
                this.modals.increaseSub = false;
            },
            handleIncreaseSubscription() {
                this.modals.newSubscriptionModal = true;
                this.modals.increaseSub = true;
            },
            cancelSub(HID) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('beViews.subscriptions.cancelSub.msg');
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.billingEntity.cancelSubscriptionUrl(vueThis.billingEntityHID), null, { params: { subscriptionHID: HID } })
                            .then(response => {
                                vueThis.loadSubscriptions();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('beViews.subscriptions.cancelSub.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('beViews.subscriptions.cancelSub.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            paySub(HID) {
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                let returnUrl = encodeURIComponent(window.location.href);
                this.axios.post(this.$root.config.billingEntity.paySubscriptionUrl(this.billingEntityHID), null, { params: { subscriptionHID: HID, returnUrl: returnUrl } })
                    .then(response => {
                        swal.close();
                        window.location.href = response.data.data;
                    }).catch(function (error) {
                        swal.close();
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('beViews.subscriptions.paySub.failed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            refresh() {
                this.initComponent();
            },
            handleTrialSubscription() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('beViews.subscriptions.trial.msg');
                cfg.text = vueThis.$t('beViews.subscriptions.trial.text');
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.billingEntity.activateTrialSubscriptionUrl(vueThis.billingEntityHID), null)
                            .then(response => {
                                vueThis.refresh();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('beViews.subscriptions.trial.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('beViews.subscriptions.trial.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            //--------------
            modalSubscriptionFinish(newSub, billingData) {
                this.modals.newSubscriptionModal = false;                
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                let vueThis = this;
                this.axios.post(this.$root.config.billingEntity.createSubscriptionUrl(this.billingEntityHID), { subscription: newSub, billingProfile: billingData })
                    .then(response => {
                        swal.close();
                        vueThis.loadSubscriptions();
                        vueThis.paySub(response.data.data);
                    }).catch(function (error) {
                        swal.close();
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('beViews.subscriptions.order.failed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            modalSubscriptionHide() {
                this.modals.newSubscriptionModal = false;
            },
            //-----------------------------
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            expandRow(row) {
                this.$refs.subsTable.toggleRowExpansion(row);
            },
        },
        computed: {
            activeSub() {
                let aSub = this.subscriptions.find(c => c.status === SubscriptionTools.Status_Active);
                let result = this.emptySub;
                if (aSub != undefined) {
                    result = {
                        startDateUtc: aSub.dateFrom,
                        endDateUtcUI: this.$moment(aSub.dateTo).format("DD-MM-YYYY"),
                        endDateUtc: aSub.dateTo,
                        sizeUI: aSub.usedSize.toString() + "/" + aSub.size.toString(),
                        size: aSub.size,
                        type: aSub.type,
                        typeID: aSub.typeID,
                        price: aSub.priceBaseVat,
                        maxCampaigns: aSub.maxConcurrentCampaigns.toString(),
                        vatRatio: aSub.vatRatio,
                        pricePerHv: aSub.nominalPricePerHv,
                    };
                }
                return result;
            },
            activeSubForWizard() {
                if (this.activeSub.typeID == SubscriptionTools.Type_TrialId) {                    
                    return this.emptySub;
                }
                return this.activeSub;
            },
            showBtnNewSub() {
                if (this.subscriptions.some(c => c.status === SubscriptionTools.Status_Ordered || c.status === SubscriptionTools.Status_Paid))
                    return false;

                if (this.activeSub.typeID == this.emptySub.typeID)
                    return true;

                let activeSubEndDate = new Date(new Date(Date.parse(this.activeSub.endDateUtc)).getFullYear(), new Date(Date.parse(this.activeSub.endDateUtc)).getMonth(), new Date(Date.parse(this.activeSub.endDateUtc)).getDate());
                let nowUtc = new Date(Date.now());
                let expDays = this.$moment(activeSubEndDate).diff(this.$moment(nowUtc), 'days');
                if (expDays < 31) {
                    return true;
                }
                
                return false;
            },
            showBtnTrialSub() {
                return this.isTrialAvailable && this.subscriptions.every(c => c.status === SubscriptionTools.Status_Cancelled);
            },
            showBtnIncreaseSub() {
                if (this.subscriptions.some(c => c.status === SubscriptionTools.Status_Paid || c.status === SubscriptionTools.Status_Ordered))
                    return false;

                if (this.activeSub.typeID == this.emptySub.typeID)
                    return false;

                if (this.activeSub.typeID == SubscriptionTools.Type_TrialId || this.activeSub.typeID == SubscriptionTools.Type_ManagedId)
                    return false;

                if (this.activeSub.size >= 5000)
                    return false;

                return true;
            },
            //--------------------------
            queriedData() {
                var cThis = this;
                if (!this.searchQuery) {
                    this.pagination.total = this.subscriptions.length;
                    return this.subscriptions.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }
                let result = this.subscriptions
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.pagination.total;
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }



</script>

<style scoped>
</style>