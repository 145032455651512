<template>
    <div class="row score-trend">
        <div class="col-12 col-md-4" >
            <div>
                <div class="row">
                    <div class="col-12">
                        <card type="pricing">
                            <h6 slot="header" class="card-category">{{$t('scoreTrend.actScoreLabel')}}</h6>
                            <div class="card-icon icon-default">
                                <i class="fa fa-shield"></i>
                            </div>
                            <h2 class="card-title">{{avgScoreTitle}}</h2>
                        </card>
                    </div>
                    <div class="col-12">
                        <card type="pricing">
                            <h6 slot="header" class="card-category">{{$t('scoreTrend.maturityLabel')}}</h6>
                            <div class="card-icon icon-default">
                                <i class="fa fa-puzzle-piece" style="padding-left: 10px;"></i>
                            </div>
                            <h3 class="card-title">{{maturityGroup}}</h3>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 d-none d-md-block">
            <card data-v-step="step-score-hist">
                <template slot="header">
                    <h4 class="card-title">{{$t('scoreTrend.trendLabel')}}</h4>
                    <p class="category">{{$t('scoreTrend.trendText')}}</p>
                </template>
                <line-chart :extraOptions="scoreHistoryChart.extraOptions" :labels="scoreHistChartXAxisLabel" :height="scoreHistoryChart.chatHeight"
                            :datasets="scoreHist">
                </line-chart>
            </card>
        </div>
    </div>
</template>

<script>
    import { MaturityLevelTools } from 'src/lpLibrary.js'
    import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
    import PieChart from 'src/components/UIComponents/Charts/PieChart'
    import LineChart from 'src/components/UIComponents/Charts/LineChart'
    import { Card, StatsCard } from 'src/components/UIComponents'

    export default {
        name: 'score-trend',
        components: {
            ChartCard,
            Card,
            StatsCard,
            PieChart,
            LineChart,
        },
        props: {
            statistic: {
                type: Object,
            },
        },
        data() {
            let vueThis = this;
            return {
                scoreHistoryChart: {
                    chatHeight: 160,
                    extraOptions: {
                        legend: {
                            display: false,
                        },
                        scales: {
                            //display: true,
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                    max: 100,
                                    min: 0,
                                }
                            }]
                        },
                        tooltips: {
                            tooltipFillColor: "rgba(0,0,0,0.5)",
                            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            tooltipFontSize: 14,
                            tooltipFontStyle: "normal",
                            tooltipFontColor: "#fff",
                            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                            tooltipTitleFontSize: 14,
                            tooltipTitleFontStyle: "bold",
                            tooltipTitleFontColor: "#fff",
                            tooltipYPadding: 6,
                            tooltipXPadding: 6,
                            tooltipCaretSize: 8,
                            tooltipCornerRadius: 6,
                            tooltipXOffset: 10,
                        },
                        annotation: {
                            drawTime: 'beforeDatasetsDraw',
                            annotations: [
                                {
                                    yScaleID: "y-axis-0",
                                    type: "box",
                                    yMin: 0,
                                    yMax: 20,
                                    backgroundColor: MaturityLevelTools.Color_Group1_T,
                                    borderWidth: 0,
                                    borderColor: "rgba(0,0,0,0)",
                                },
                                {
                                    yScaleID: "y-axis-0",
                                    type: "box",
                                    yMin: 20,
                                    yMax: 40,
                                    backgroundColor: MaturityLevelTools.Color_Group2_T,
                                    borderWidth: 0,
                                    borderColor: "rgba(0,0,0,0)",
                                },
                                {
                                    yScaleID: "y-axis-0",
                                    type: "box",
                                    yMin: 40,
                                    yMax: 60,
                                    backgroundColor: MaturityLevelTools.Color_Group3_T,
                                    borderWidth: 0,
                                    borderColor: "rgba(0,0,0,0)",
                                },
                                {
                                    yScaleID: "y-axis-0",
                                    type: "box",
                                    yMin: 60,
                                    yMax: 80,
                                    backgroundColor: MaturityLevelTools.Color_Group4_T,
                                    borderWidth: 0,
                                    borderColor: "rgba(0,0,0,0)",
                                },
                                {
                                    yScaleID: "y-axis-0",
                                    type: "box",
                                    yMin: 80,
                                    yMax: 100,
                                    backgroundColor: MaturityLevelTools.Color_Group5_T,
                                    borderWidth: 0,
                                    borderColor: "rgba(0,0,0,0)",
                                },
                            ]
                        },
                    },
                },
            }
        },
        computed: {
            avgScoreTitle() {
                if (this.statistic == undefined || this.statistic.score == undefined)
                    return "NaN";

                return this.statistic.score.toString();
            },
            scoreHistChartXAxisLabel() {
                if (this.statistic == undefined)
                    return [];
                return this.statistic.chartData.map(a => this.$moment(a.timeStampUtc).format("DD-MM-YYYY"));
            },
            scoreHist() {
                let data = null;
                if (this.statistic != undefined && this.statistic.chartData != undefined && this.statistic.chartData != null && this.statistic.chartData.length > 0) {
                    data = this.statistic.chartData.map(a => {
                        return a.score;
                    });
                }

                return [{
                    label: 'Avg. score',
                    fill: false,
                    backgroundColor: '#66615b',
                    borderColor: '#66615b',
                    data: data,
                }];
            },
            maturityGroup() {
                if (this.avgScoreTitle == 'NaN') return "NaN";
                return this.$t("general.lpLibrary.hvGroup." + MaturityLevelTools.getGroupByScore(this.avgScoreTitle));

            }
        },
    }
</script>

<style scoped>
</style>