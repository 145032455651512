<template>
    <div ref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'beList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">
                        {{entity.name}}
                    </p>
                    <p class="category">
                        {{ $t('beViews.entityRoleMgmt.header')}}
                    </p>
                </div>
            </div>
            <div class="col text-right">
                <el-tooltip :content="$t('beViews.entityRoleMgmt.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh()">
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <div class="card" ref="content" data-v-step="step-list">
                    <div class="card-header">
                        <div class="row">
                            <div class="col  d-flex justify-content-start">
                                <h4 class="card-title">{{ $t('beViews.entityRoleMgmt.header')}}</h4>
                            </div>
                            <div class="col text-right">

                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- toolbar -->
                        <div class="row toolbar">
                            <div class="col-12">
                                <div class="">
                                    <el-tooltip content="Add account" :open-delay="300" placement="top">
                                        <p-button type="success" @click="addAccount" data-v-step="step-addaccount">
                                            <i class="nc-icon nc-simple-add"></i> {{ $t('beViews.entityRoleMgmt.btnNewAccess')}}
                                        </p-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <!-- header -->
                        <div class="row justify-content-between">
                            <div class="col-12 col-sm-6 col-md-5 col-lg-3">
                                <el-select class="select-default float-sm-left w-100"
                                           v-model="e_pagination.perPage">
                                    <el-option class="select-default"
                                               v-for="item in e_pagination.perPageOptions"
                                               :key="item"
                                               :label="item"
                                               :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                <div class="float-sm-right">
                                    <fg-input class="input-sm"
                                              :placeholder="$t('beViews.entityRoleMgmt.searchPlaceholder')"
                                              v-model="e_searchQuery"
                                              addon-right-icon="nc-icon nc-zoom-split">
                                    </fg-input>
                                </div>
                            </div>
                        </div>
                        <!-- table -->
                        <div class="row">
                            <div class="col-12" ref="tablecontainer">
                                <el-table :data="e_queriedData" ref="eltable" :empty-text="$t('beViews.entityRoleMgmt.table.noData')"
                                          :default-sort="{ prop: 'email', order: 'ascending'}"
                                          @sort-change="e_handleSortChange">
                                    <el-table-column prop="email" sortable="custom" :label="$t('beViews.entityRoleMgmt.table.colEmail')">
                                        <template slot-scope="props">
                                            {{props.row.email}}
                                            <el-tooltip content="Invitation not yet accepted" :open-delay="300" placement="right" v-if="props.row.createdAndNotLogged">
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="roleName" sortable="custom" :label="$t('beViews.entityRoleMgmt.table.colRole')">
                                    </el-table-column>
                                    <el-table-column :width="140" class-name="td-actions" align="center">
                                        <template slot="header" slot-scope="scope">
                                            <span data-v-step="step-actions">{{$t('beViews.entityRoleMgmt.table.colActions.header')}}</span>
                                        </template>
                                        <template slot-scope="props">
                                            <el-tooltip :content="$t('beViews.entityRoleMgmt.table.colActions.btnEdit')" :open-delay="300" placement="top">
                                                <p-button type="default" size="sm" class="mr-2" icon @click="editAccount(props.row.eRoleHID, props.row.roleID, props.row.entityHID, props.row.email)">
                                                    <i class="fa fa-cogs" aria-hidden="true"></i>
                                                </p-button>
                                            </el-tooltip>
                                            <el-tooltip :content="$t('beViews.entityRoleMgmt.table.colActions.btnDelete')" :open-delay="300" placement="top">
                                                <p-button type="danger" size="sm" icon @click="deleteAccount(props.row.eRoleHID, props.row.entityName, props.row.email)">
                                                    <i class="fa-solid fa-trash-can"></i>
                                                </p-button>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <!-- paging -->
                        <div class="row">
                            <div class="col-sm-6 pagination-info">
                                <p class="category">
                                    {{ $t('beViews.entityRoleMgmt.pagingInfo', [e_from + 1, e_to, e_total])}}
                                </p>
                            </div>
                            <div class="col-sm-6">
                                <p-pagination class="pull-right"
                                              v-model="e_pagination.currentPage"
                                              :per-page="e_pagination.perPage"
                                              :total="e_pagination.total">
                                </p-pagination>
                            </div>
                        </div>
                    </div>

                    <!-- e role modal -->
                    <e-role-detail :detailData="modals.erDetailData"
                                   :show="modals.erDetailModal"
                                   :billingEntityHID="billingEntityHID"
                                   :billingEntityName="billingEntity.name"
                                   :entityHID="entityHID"
                                   :mode="modals.erDetailMode"
                                   @hide="erDetailHide"
                                   @save="erDetailSave">
                    </e-role-detail>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Card, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import { RoleEnum } from 'src/lpLibrary.js'
    import eRDetail from 'src/components/UIComponents/ModalDialog/EntityRoleDetail'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            StatsCard,
            [eRDetail.name]: eRDetail,
        },
        data() {
            return {
                entityHID: '',
                entity: {
                    name: "",
                },
                billingEntityHID: '',
                billingEntity: {
                    name: "",
                },
                entities: [],
                modals: {
                    erDetailModal: false,
                    erDetailMode: 'new',
                    erDetailData: {},
                },
                //-------------------
                e_pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25],
                    total: 0
                },
                e_propsToSearch: ['email', 'roleName'],
                e_searchQuery: '',
                e_sortObject: { order: 'ascending', name: 'email' },

            }
        },
        methods: {
            initComponent: function () {
                this.loadEntityInfo();
                this.loadEntities();
            },
            loadEntities() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.billingEntity.getRolesForEntitiesUrl(this.billingEntityHID), null)
                    .then(response => {
                        loader.hide();
                        vueThis.entities = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            loadEntityInfo() {
                let vueThis = this;
                this.axios.post(this.$root.config.billingEntity.getEntities4BeUrl(this.billingEntityHID), null)
                    .then(response => {
                        let ent = response.data.find(c => c.hid == vueThis.entityHID);
                        vueThis.entity.name = ent.name;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            addAccount() {
                this.modals.erDetailData = {};
                this.modals.erDetailMode = 'new';
                this.modals.erDetailModal = true;
            },
            refresh() {
                this.loadEntityInfo();
                this.loadEntities();
            },
            editAccount: function (erhid, rid, ehid,  email) {
                this.modals.erDetailData = {
                    entityHID: ehid,
                    roleId: rid,
                    email: email,
                    eRoleHId: erhid,
                };
                this.modals.erDetailMode = 'edit';
                this.modals.erDetailModal = true;
            },
            deleteAccount: function (erhid, eName, email) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('beViews.entityRoleMgmt.delRoleAlert.msg', [email]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.billingEntity.removeAccountFromEntityUrl(vueThis.billingEntityHID), null, { params: { eroleHID: erhid } })
                            .then(response => {
                                vueThis.refresh();
                                this.$store.dispatch('updateUser');
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('beViews.entityRoleMgmt.delRoleAlert.success', [email]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('beViews.entityRoleMgmt.delRoleAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            //-----
            erDetailSave() {
                //console.log(this.modals.erDetailData);
                this.modals.erDetailModal = false;
                let vueThis = this;
                let endpointUrl = this.$root.config.billingEntity.addAccountToEntityUrl(this.billingEntityHID);
                let successMsg = vueThis.$t('beViews.entityRoleMgmt.detailRoleSaveAlert.addSuccessMsg');
                let errorMsg = vueThis.$t('beViews.entityRoleMgmt.detailRoleSaveAlert.addFailedMsg');
                if (this.modals.erDetailMode == 'edit') {
                    endpointUrl = this.$root.config.billingEntity.editAccountToEntityUrl(this.billingEntityHID);
                    successMsg = vueThis.$t('beViews.entityRoleMgmt.detailRoleSaveAlert.editSuccessMsg');
                    errorMsg = vueThis.$t('beViews.entityRoleMgmt.detailRoleSaveAlert.editFailedMsg');
                }
                this.axios.post(endpointUrl, this.modals.erDetailData)
                    .then(response => {
                        this.refresh();
                        this.$store.dispatch('updateUser');
                        swal.fire({
                            icon: 'success',
                            title: successMsg,
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            swal.fire({
                                title: errorMsg,
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });


            },
            erDetailHide() {
                this.modals.erDetailModal = false;
            },
            //-----------------------------
            e_handleSortChange(e) {
                this.e_sortObject = { order: e.order, name: e.prop };
            },
        },
        computed: {
            //--------------------------
            e_preFilteredEntities() {
                return this.entities.filter(c => c.entityHID == this.entityHID);
            },
            e_queriedData() {
                var cThis = this;
                if (!this.e_searchQuery) {
                    this.e_pagination.total = this.e_preFilteredEntities.length;
                    return this.e_preFilteredEntities.sort(function (a, b) { return sortingHandler(a, b, cThis.e_sortObject); }).slice(this.e_from, this.e_to);
                }
                let result = this.e_preFilteredEntities
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.e_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.e_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.e_pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.e_sortObject); }).slice(this.e_from, this.e_to);
            },
            e_to() {
                let highBound = this.e_from + this.e_pagination.perPage;
                if (this.e_total < highBound) {
                    highBound = this.e_total;
                }
                return highBound;
            },
            e_from() {
                if (this.e_pagination.total == 0) return -1;
                return this.e_pagination.perPage * (this.e_pagination.currentPage - 1);
            },
            e_total() {
                return this.e_pagination.total;
            },
        },
        mounted: function () {
            this.initComponent();
        },
        created() {
            this.billingEntityHID = this.$route.params.behid;
            this.billingEntity = this.$store.getters.userState.billingEntities.find(c => c.hid == this.billingEntityHID);
            this.entityHID = this.$route.params.ehid;
        }
    }
</script>

<style scoped>
</style>