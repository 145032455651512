<template>
    <div>
        <div class="card" >
            <div class="card-header">
                <div class="row">
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{title}}</h4>
                    </div>
                    <div class="col text-right">
                        <el-tooltip :content="$t('hvManageTable.btnRefreshTooltip')" :open-delay="300" placement="top">
                            <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row" ref="table">
                    <div class="col-12">
                        <!-- toolbar -->
                        <div class="row toolbar">
                            <div class="col-12 d-flex flex-row">
                                <slot name="toolbar-start">

                                </slot>
                                <p-button type="danger" @click="massDeleteUsers" :disabled="disableMassButtons" data-v-step="step-deleteemp" v-if="deleteMode && editableMode && !groupIsSystem">
                                    <i class="fa-solid fa-trash-can"></i> {{$t('hvManageTable.toolsbar.btnDelHvs')}}
                                </p-button>
                                <p-button type="danger" @click="massRemoveFromGroup" :disabled="disableMassButtons" data-v-step="step-removeemp" v-if="!deleteMode && editableMode && !groupIsSystem">
                                    <i class="fa fa-times"></i> {{$t('hvManageTable.toolsbar.btnRemoveHvs')}}
                                </p-button>
                                <slot name="toolbar-end">

                                </slot>
                            </div>
                        </div>
                        <!-- select -->
                        <div class="row justify-content-between">
                            <div class="col-12 col-md-6 pt-0 pt-md-2">
                                <div class="d-flex flex-row">
                                    <div class="d-flex align-items-center">
                                        <el-tooltip :content="$t('hvManageTable.switchMassSelect.tooltip')" :open-delay="300" placement="top" class="mr-2" v-show="editableMode && !groupIsSystem">
                                            <toggle-button v-model="enableSelect" :width="120" :height="30" data-v-step="step-switchmass"
                                                           :labels="{checked: $t('hvManageTable.switchMassSelect.checkedLabel'), unchecked: $t('hvManageTable.switchMassSelect.uncheckedLabel')}" :color="{checked: '#66615b', unchecked: '#cfcfca'}" />
                                        </el-tooltip>
                                        <el-tooltip :content="filterTooltipLable" :open-delay="300" placement="top" v-show="showHasGroupUserFilter">
                                            <toggle-button v-model="filterHasGroup" :width="120" :height="30" data-v-step="step-switchhasgroup"
                                                           :labels="{checked: $t('hvManageTable.switchNoGroup.checkedLabel'), unchecked: $t('hvManageTable.switchNoGroup.uncheckedLabel')}" :color="{checked: '#66615b', unchecked: '#cfcfca'}" />
                                        </el-tooltip>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                                <div class="d-flex justify-content-end">
                                    <fg-input class="input-sm mr-2"
                                              :placeholder="$t('hvManageTable.searchPlaceholder')"
                                              v-model="searchQuery"
                                              addon-right-icon="nc-icon nc-zoom-split">
                                    </fg-input>
                                    <el-select class="select-default float-sm-left page-size"
                                               v-model="pagination.perPage">
                                        <el-option class="select-default"
                                                   v-for="item in pagination.perPageOptions"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                        </div>
                        <!-- table -->
                        <div class="row">
                            <div class="col-12">
                                <el-table :data="hoaxVictim" :empty-text="$t('hvManageTable.table.noData')"
                                          :default-sort="{ prop: 'name', order: 'ascending'}"
                                          @sort-change="handleSortChange" @selection-change="handleSelectionChange">
                                    <el-table-column type="selection" width="55" v-if="enableSelect">
                                    </el-table-column>
                                    <el-table-column prop="name" sortable="custom" :label="$t('hvManageTable.table.colName.header')">
                                        <template slot-scope="props">
                                            {{props.row.name}}
                                            <el-tooltip :content="$t('hvManageTable.table.colName.noGroupTooltip')" :open-delay="300" placement="right" v-if="!props.row.hasGroup">
                                                <i class="fa fa-chain-broken" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="email" sortable="custom" :label="$t('hvManageTable.table.colEmail')">
                                    </el-table-column>
                                    <el-table-column prop="phoneNumber" width="150" :label="$t('hvManageTable.table.colPNumber')" align="center">
                                    </el-table-column>
                                    <el-table-column :width="120" align="center"
                                                     class-name="td-actions"
                                                     v-if="!readOnly">
                                        <template slot="header" slot-scope="scope">
                                            <span data-v-step="step-hvsactions">{{$t('hvManageTable.table.colActions.header')}}</span>
                                        </template>
                                        <template slot-scope="props">
                                            <el-tooltip :content="$t('hvManageTable.table.colActions.btnEdit')" :open-delay="300" placement="top">
                                                <p-button type="default" size="sm" icon @click="handleEdit(props.$index, props.row)">
                                                    <i class="fa-solid fa-user-gear"></i>
                                                </p-button>
                                            </el-tooltip>
                                            <el-tooltip :content="$t('hvManageTable.table.colActions.btnDelete')" :open-delay="300" placement="top" v-if="deleteMode && !groupIsSystem">
                                                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                                                    <i class="fa-solid fa-trash-can"></i>
                                                </p-button>
                                            </el-tooltip>
                                            <el-tooltip :content="$t('hvManageTable.table.colActions.btnRemove')" :open-delay="300" placement="top" v-else-if="!groupIsSystem">
                                                <p-button type="danger" size="sm" icon @click="handleRemoveFromGroup(props.$index, props.row)">
                                                    <i class="fa fa-times"></i>
                                                </p-button>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>
                        </div>
                        <!-- paging -->
                        <div class="row">
                            <div class="col-sm-6 pagination-info">
                                <p class="category">{{$t('hvManageTable.pagingInfo', [from + 1, to, pagination.total])}}</p>
                            </div>
                            <div class="col-sm-6">
                                <p-pagination class="pull-right"
                                              v-model="pagination.currentPage"
                                              :per-page="pagination.perPage"
                                              :total="pagination.total">
                                </p-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- hoax victim modal -->
        <hoax-victim-detail :hoaxVictimHID="modals.hvHID"
                            :show="modals.hvDetailModal"
                            :entityHID="entityHID"
                            :title="$t('hvManageTable.hvDetail.header')"
                            :confirmBtnLabel="$t('hvManageTable.hvDetail.btnConfirm')"
                            @hide="hvDetailHide"
                            @save="hoaxVictimSave">
        </hoax-victim-detail>
    </div>
</template>

<script>
    //import { CampaignTools, HvGroupTools } from 'src/lpLibrary.js'
    import { ToggleButton } from 'vue-js-toggle-button'

    import { Input, Select, Option, Table, Card, TableColumn, Empty } from 'element-ui'
    import { Modal, Pagination, Switch } from 'src/components/UIComponents'
    import hvDetail from 'src/components/UIComponents/ModalDialog/HoaxVictimDetail'
    import hvResult from 'src/components/UIComponents/HVResult/HVResultTable.vue'
    import hvScoreChart from 'src/components/UIComponents/Charts/HVScoreChart.vue'

    import swal from 'sweetalert2'

    export default {
        name: 'hv-manage-table',
        props: {
            entityHID: {
                type: String,
                required: true
            },
            hvGroupHID: String,
            campaignHID: String,
            title: String,
            readOnly: Boolean,
            viewBy: {
                type: String,
                default: 'entity',
                description: '[entity|group]'
            },
            showHasGroupUserFilter: Boolean,
            groupIsSystem: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            [hvResult.name]: hvResult,
            [hvDetail.name]: hvDetail,
            [hvScoreChart.name]: hvScoreChart,
            [Switch.name]: Switch,
            ToggleButton: ToggleButton,
            [Empty.name]: Empty,
        },
        data() {
            return {
                hoaxVictim: [],
                hvGroups: [],
                selectedhvGroupHIDs: [],
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [25, 50, 100, 200, 500],
                    total: 0,
                },
                propsToSearch: ['name', 'email'],
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'name' },
                modals: {
                    hvDetailModal: false,
                    hvHID: '',
                },
                filterHasGroup: false,
                filterTooltipLable: this.$t('hvManageTable.switchNoGroup.tooltipUnchecked'),
                enableSelect: false,
                hvs2Mass: [],
                timeoutQuery: null,
            }
        },
        methods: {
            initComponent: function () {
                this.loadHoaxVictim(false);
            },
            loadHoaxVictim: function (force) {
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = null;

                let filter = {
                    pageSize: this.pagination.perPage,
                    page: this.pagination.currentPage-1,
                    searchPattern: this.searchQuery,
                    orderBy: this.sortObject.name,
                    orderDirection: this.sortObject.order,
                    onlyWithoutGroup: this.filterHasGroup,
                };

                switch (this.viewBy) {
                    case 'entity':
                        if (this.entityHID == undefined && this.entityHID == null)
                            return;
                        loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                        filter.objectHID = this.entityHID;
                        this.axios.post(this.$root.config.entity.getHoaxVictimsUrl(this.entityHID), filter, { params: { force: force } })
                            .then(response => {
                                loader.hide();
                                this.pagination.total = response.data.total;
                                this.hoaxVictim = response.data.records;
                                this.hoaxVictim.forEach(function (item) {
                                    item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                                });
                                
                                this.loadHvGroups();
                            }).catch(function (error) {
                                loader.hide();
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();                                
                            });
                        break;
                    case 'group':
                        if (this.hvGroupHID == undefined && this.hvGroupHID == null)
                            return;
                        loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                        filter.objectHID = this.hvGroupHID;
                        this.axios.post(this.$root.config.entity.hvGroup.getHoaxVictimsUrl(this.entityHID), filter, { params: { force: force } })
                            .then(response => {
                                loader.hide();
                                this.pagination.total = response.data.total;
                                this.hoaxVictim = response.data.records;
                                this.hoaxVictim.forEach(function (item) {
                                    item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                                });                                
                                this.loadHvGroups();
                            }).catch(function (error) {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                                loader.hide();
                            });
                        break;
                }
            },
            loadHvGroups() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupsUrl(this.entityHID), null)
                    .then(response => {
                        this.hvGroups = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            refresh(force) {
                this.loadHoaxVictim(force);
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };

            },
            handleEdit(index, row) {
                this.modals.hvHID = row.hid;
                this.modals.hvDetailModal = true;
            },
            handleDelete(index, row) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('hvManageTable.deleteAlert.msg', [row.name]);
                let hvGroupHIDs = row.groups.map(c => c.hid);
                if (this.hvGroups.some(c => hvGroupHIDs.includes(c.hid) && c.hasRunningCampaign)) {
                    cfg.text = vueThis.$t('hvManageTable.deleteAlert.text');
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.hv.hoaxVictimsDeleteUrl(this.entityHID), { ParentHID: '', HIDs: [row.hid] })
                            .then(response => {
                                vueThis.loadHoaxVictim(vueThis.hvGroupHID);
                                vueThis.modals.hvDetailModal = false;
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('hvManageTable.deleteAlert.success', [row.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('hvManageTable.deleteAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            handleRemoveFromGroup(index, row) {
                let vueThis = this;
                let data = row;
                if (this.hvGroupHID == undefined || this.hvGroupHID == null) {
                    return;
                }
                data.deleteFromGroupIds = [];
                data.deleteFromGroupIds.push(this.hvGroupHID);

                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('hvManageTable.removeAlert.msg', [row.name]);
                if (this.hvGroups.find(c => c.hid == this.hvGroupHID).hasRunningCampaign) {
                    cfg.text = vueThis.$t('hvManageTable.removeAlert.text');
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        this.axios.post(this.$root.config.entity.hv.hoaxVictimsRemoveGroupUrl(this.entityHID), { ParentHID: this.hvGroupHID, HIDs: [row.hid] })
                            .then(response => {
                                vueThis.loadHoaxVictim(true);
                                vueThis.modals.hvDetailModal = false;
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('hvManageTable.removeAlert.success', [row.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('hvManageTable.removeAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            handleSelectionChange(vals) {
                this.hvs2Mass = vals;
            },
            massRemoveFromGroup() {
                let vueThis = this;
                if (this.hvGroupHID == undefined || this.hvGroupHID == null) {
                    return;
                }
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('hvManageTable.massRemoveAlert.msg');
                if (this.hvGroups.find(c => c.hid == this.hvGroupHID).hasRunningCampaign) {
                    cfg.text = vueThis.$t('hvManageTable.massRemoveAlert.text');
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);

                        this.axios.post(this.$root.config.entity.hv.hoaxVictimsRemoveGroupUrl(this.entityHID), { ParentHID: this.hvGroupHID, HIDs: this.hvs2Mass.map(c=>c.hid) })
                            .then(response => {
                                vueThis.loadHoaxVictim(true);
                                vueThis.modals.hvDetailModal = false;
                                vueThis.$emit('OnRemoveFinished');
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('hvManageTable.massRemoveAlert.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('hvManageTable.massRemoveAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            massDeleteUsers() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('hvManageTable.massDeleteAlert.msg');
                for (const hv of this.hvs2Mass) {
                    let hvGroupHIDs = hv.groups.map(c => c.hid);
                    if (this.hvGroups.some(c => hvGroupHIDs.includes(c.hid) && c.hasRunningCampaign)) {
                        cfg.text = vueThis.$t('hvManageTable.massDeleteAlert.text');
                        break;
                    }
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);

                        this.axios.post(this.$root.config.entity.hv.hoaxVictimsDeleteUrl(this.entityHID), { ParentHID: '', HIDs: this.hvs2Mass.map(c => c.hid) })
                            .then(response => {
                                vueThis.loadHoaxVictim(vueThis.hvGroupHID);
                                vueThis.modals.hvDetailModal = false;
                                vueThis.$emit('OnDeleteFinished');
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('hvManageTable.massDeleteAlert.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('hvManageTable.massDeleteAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            manualLoad() {
                this.manualLoading = true;
                this.loadHoaxVictim();
            },

            //-----------------
            hoaxVictimSave(hvData) {
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hv.hoaxVictimUpdateUrl(this.entityHID), hvData)
                    .then(response => {
                        this.loadHoaxVictim();
                        this.modals.hvDetailModal = false;
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('hvManageTable.hvSaveAlert.success'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            //500101, 500202. 500104, 500105
                            swal.fire({
                                title: vueThis.$t('hvManageTable.hvSaveAlert.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { 'email': hvData.email, 'phoneNumber': hvData.phoneNumber }),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            hvDetailHide() {
                this.modals.hvHID = '';
                this.modals.hvDetailModal = false;
            },
        },
        watch: {
            hvGroupHID: function () {
                this.loadHoaxVictim();
            },
            enableSelect: function () {
                if (!this.enableSelect) {
                    this.hvs2Mass = [];
                }
            },
            searchQuery: function () {
                let vueThis = this;
                if (this.timeoutQuery != null) {
                    clearTimeout(this.timeoutQuery);
                }
                this.timeoutQuery = setTimeout(function () { vueThis.loadHoaxVictim(); }, vueThis.$root.config.searchWaitingAction);

            },
            filterHasGroup: function () {
                if (this.filterHasGroup) {
                    this.filterTooltipLable = this.$t('hvManageTable.switchNoGroup.tooltipChecked');
                } else {
                    this.filterTooltipLable = this.$t('hvManageTable.switchNoGroup.tooltipUnchecked');
                }
                this.loadHoaxVictim();
            },
            sortObject: {
                deep: true,
                handler: function () {
                    this.loadHoaxVictim();
                }
            },
            'pagination.currentPage': function() {
                this.loadHoaxVictim();
            },
            'pagination.perPage': function () {
                if (this.pagination.currentPage == 1) {
                    this.loadHoaxVictim();
                }
            }
        },
        mounted: function () {
            this.initComponent();
        },
        computed: {
            disableMassButtons() {
                return this.hvs2Mass.length == 0;
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.pagination.total < highBound) {
                    highBound = this.pagination.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            deleteMode() {
                return this.viewBy === 'entity';
            },
            editableMode() {
                return this.viewBy != 'campaign';
            },
        },
    }
</script>

<style scoped>
</style>