<template>
    <div>
        <div ref="beContent">
            <div class="row">

                <div class="col-12" v-if="showNoBENotice">
                    <card>
                        <div slot="header">
                            <h4 class="card-title text-danger">{{ $t('accountViews.beList.inotice.header')}}</h4>
                        </div>
                        <div>
                            <p>
                                {{ $t('accountViews.beList.inotice.text')}}
                            </p>
                        </div>
                    </card>
                </div>

                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-flex justify-content-start">
                                    <h4 class="card-title" data-v-step="step-bes"> {{ $t('accountViews.beList.header')}}</h4>
                                </div>
                                <div class="col text-right">
                                    <el-tooltip :content="$t('accountViews.beList.btnRefreshTooltip')" :open-delay="300" placement="top">
                                        <p-button type="neutral" class="mr-2" icon @click="refresh()">
                                            <i class="fa-solid fa-arrows-rotate"></i>
                                        </p-button>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!-- toolbar -->
                            <div class="row toolbar">
                                <div class="col-12">
                                    <div class="">
                                        <el-tooltip :content="$t('accountViews.beList.btnCreateNewBETooltip')" :open-delay="300" placement="top">
                                            <router-link class="btn btn-success" :to="{ name: 'beCreate'}" data-v-step="step-newbe">
                                                <i class="nc-icon nc-simple-add"></i> {{ $t('accountViews.beList.btnAddNewBE')}}
                                            </router-link>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <!-- header -->
                            <div class="row justify-content-between">
                                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-2">
                                    <el-select class="select-default float-sm-left w-100"
                                               v-model="be_pagination.perPage">
                                        <el-option class="select-default"
                                                   v-for="item in be_pagination.perPageOptions"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                                    <div class="float-sm-right">
                                        <fg-input class="input-sm"
                                                  :placeholder="$t('accountViews.beList.searchPlaceholder')"
                                                  v-model="be_searchQuery"
                                                  addon-right-icon="nc-icon nc-zoom-split">
                                        </fg-input>
                                    </div>
                                </div>
                            </div>
                            <!-- table -->
                            <div class="row">
                                <div class="col-12" ref="table">
                                    <el-table :data="be_queriedData" row-key="hid" :expand-row-keys="expandRows" :empty-text="$t('accountViews.beList.table.noData')"
                                              :default-sort="{ prop: 'name', order: 'ascending'}"
                                              @sort-change="be_handleSortChange" ref="morgTable">
                                        <el-table-column type="expand">
                                            <template slot-scope="props" v-if="!props.row.isDisabled">
                                                <entity-mgmt :billingEntityHID="props.row.hid"></entity-mgmt>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="name" sortable="custom" :label="$t('accountViews.beList.table.colName')">
                                            <template slot-scope="props">
                                                <el-tooltip content="Blocked" :open-delay="300" placement="top" v-if="props.row.isDisabled">
                                                    <i class="fa fa-lock text-danger mr-2" aria-hidden="true"></i>
                                                </el-tooltip>
                                                {{props.row.name}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="role" sortable="custom" :label="$t('accountViews.beList.table.colRole')" align="center">
                                        </el-table-column>
                                        <el-table-column prop="subscriptionType" sortable="custom" :label="$t('accountViews.beList.table.colSub')" align="center">
                                        </el-table-column>
                                        <el-table-column prop="subscriptionExpiration" sortable="custom" :label="$t('accountViews.beList.table.colExp')" align="center">
                                            <template slot-scope="props">
                                                {{props.row.subscriptionExpiration | moment("DD.MM.YYYY")}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column :width="240" class-name="td-actions" align="center">
                                            <template slot="header" slot-scope="scope">
                                                <span data-v-step="step-actions">{{ $t('accountViews.beList.table.colActions.header')}}</span>
                                            </template>
                                            <template slot-scope="props">
                                                <el-tooltip :content="$t('accountViews.beList.table.colActions.btnSubs')" :open-delay="300" placement="top">
                                                    <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'beSubs', params:{ behid: props.row.hid}}" :disabled="props.row.isDisabled">
                                                        <i class="fa-solid fa-cart-shopping"></i>
                                                    </router-link>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('accountViews.beList.table.colActions.btnEnts')" :open-delay="300" placement="top">
                                                    <p-button type="default" size="sm" icon @click="expandRow(props.row)" :disabled="props.row.isDisabled">
                                                        <i class="fa fa-building"></i>
                                                    </p-button>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('accountViews.beList.table.colActions.btnAccess')" :open-delay="300" placement="top">
                                                    <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'beRoleMgmt', params:{ behid: props.row.hid}}" :disabled="props.row.isDisabled">
                                                        <i class="fa-solid fa-key"></i>
                                                    </router-link>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('accountViews.beList.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                    <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'beDetails', params:{ behid: props.row.hid}}" :disabled="props.row.isDisabled">
                                                        <i class="fa fa-cogs"></i>
                                                    </router-link>
                                                </el-tooltip>
                                                <el-tooltip :content="$t('accountViews.beList.table.colActions.btnDel')" :open-delay="300" placement="top">
                                                    <p-button type="danger" size="sm" icon @click="deleteBEntity(props.row.hid, props.row.name)" :disabled="props.row.isDisabled">
                                                        <i class="fa-solid fa-trash-can"></i>
                                                    </p-button>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <!-- paging -->
                            <div class="row">
                                <div class="col-sm-6 pagination-info">
                                    <p class="category">
                                        {{ $t('accountViews.beList.pagingInfo', [be_from + 1, be_to, be_total ])}}
                                    </p>
                                </div>
                                <div class="col-sm-6">
                                    <p-pagination class="pull-right"
                                                  v-model="be_pagination.currentPage"
                                                  :per-page="be_pagination.perPage"
                                                  :total="be_pagination.total">
                                    </p-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Card, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import EntityMgmt from '../BillingEntity/EntityMgmt.vue'



    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            StatsCard,
            EntityMgmt,
        },
        data() {
            return {
                billingEntities: [],
                expandRows:[],
                //-------------------
                be_pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                be_propsToSearch: ['name', 'subscriptionExpiration', 'subscriptionType', 'role'],
                be_searchQuery: '',
                be_sortObject: { order: 'ascending', name: 'name' },
            }
        },
        methods: {
            initComponent: function () {
                this.loadBEntities();
            },
            loadBEntities() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.beContent));
                this.axios.post(this.$root.config.billingEntity.getBillingEntitiesUrl(), null, { params: { accountHID: this.$store.getters.userState.accountHID } })
                    .then(response => {
                        loader.hide();
                        this.billingEntities = response.data;
                        this.$store.dispatch('updateUser');
                        this.expandRows = this.$store.getters.beListTableState.expandRows;
                        this.$store.commit('updateBeListTableExpRows', []);                        
                    }).catch(function (ex) {
                        vueThis.writeEx2Log(ex);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            refresh() {
                this.loadBEntities();
            },
            deleteBEntity(id, name) {
                console.log(id, name);
                let vueThis = this;
                //let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                //swal.fire(cfg)
                swal.fire({
                    icon: 'question',
                    title: this.$t('accountViews.beList.delBEAlert.msg', [name]),
                    html: this.$t('accountViews.beList.delBEAlert.html'),
                    input: 'text',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return this.$t('accountViews.beList.delBEAlert.errNoInput');
                        }
                        if (value.trim().toUpperCase() !=  'DELETE') {
                            return this.$t('accountViews.beList.delBEAlert.errNotValid');
                        }
                    }
                })
                .then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        vueThis.axios.post(vueThis.$root.config.billingEntity.deleteBillingEntityUrl(id), null)
                            .then(response => {
                                swal.close();
                                vueThis.refresh();
                                this.$store.dispatch('updateUser');
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('accountViews.beList.delBEAlert.success', [name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                swal.close();
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('accountViews.beList.delBEAlert.errFailed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });

            },
            //-------------------------
            be_handleSortChange(e) {
                this.be_sortObject = { order: e.order, name: e.prop };
            },
            expandRow(row) {
                this.$refs.morgTable.toggleRowExpansion(row);
            },
        },
        computed: {
            showNoBENotice() {
                return !this.$store.getters.userState.billingEntities.some(e => 1);
            },
            //--------------------------
            be_queriedData() {
                var cThis = this;
                if (!this.be_searchQuery) {
                    this.be_pagination.total = this.billingEntities.length;
                    return this.billingEntities.sort(function (a, b) { return sortingHandler(a, b, cThis.be_sortObject); }).slice(this.be_from, this.be_to);
                }
                let result = this.billingEntities
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.be_propsToSearch) {
                            if (row[key]) {
                                let rowValue = row[key].toString();
                                if (rowValue.includes && rowValue.toLowerCase().includes(this.be_searchQuery.toLowerCase())) {
                                    isIncluded = true;
                                }
                            }
                        }
                        return isIncluded;
                    })
                this.be_pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.be_sortObject); }).slice(this.be_from, this.be_to);
            },
            be_to() {
                let highBound = this.be_from + this.be_pagination.perPage;
                if (this.be_total < highBound) {
                    highBound = this.be_total;
                }
                return highBound;
            },
            be_from() {
                if (this.be_pagination.total == 0) return -1;
                return this.be_pagination.perPage * (this.be_pagination.currentPage - 1);
            },
            be_total() {
                return this.be_pagination.total;
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }

</script>

<style scoped>
</style>