<template>
    <div ref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'beList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3">
                    <p class="title">
                        {{ $t('beViews.createBe.header')}}
                    </p>
                </div>
            </div>
            <div class="col text-right">

            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                    </div>
                    <div class="card-body">
                        <form>

                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <fg-input type="text" :label="$t('beViews.createBe.form.orgName.label')" :placeholder="$t('beViews.createBe.form.orgName.placeholder')" name="orgName"
                                              v-validate="modelValidations.orgName" :error="getError('orgName')" data-v-step="step-name"
                                              v-model="formModel.name">
                                    </fg-input>
                                </div>
                            </div>

                            <div class="mt-3">
                                <billingProfileForm :billingProfile="formModel.billingProfile" ref="billingForm" @validationFinished="billingProfileFormValid"></billingProfileForm>
                            </div>

                            <div class="row mt-3 justify-content-center">
                                <div class="col-12 col-md-auto text-center text-md-right">
                                    <p-button native-type="submit" type="success" class="w-100" @click.prevent="validate" data-v-step="step-btncreate">
                                        {{ $t('beViews.createBe.btnCreate')}}
                                    </p-button>
                                </div>
                                <div class="col-12 col-md-auto">
                                    <router-link class="btn btn-info w-100" :to="{ name: 'beList'}">
                                        {{ $t('beViews.createBe.btnCancel')}}
                                    </router-link>
                                </div>
                            </div>
                        </form>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import swal from 'sweetalert2'
    import BillingProfileForm from 'src/components/UIComponents/BillingProfileForm.vue'

    export default {
        components: {
            BillingProfileForm,
        },
        data() {
            return {
                modelValidations: {
                    orgName: {
                        required: true,
                        max: this.$root.config.maxBillingEntityNameLength,
                    },
                },
                formModel: {
                    name: "",
                    billingProfile: {
                        status: 'cz'
                    }
                },
                billingProfileFormIsValid: false,
            }
        },
        methods: {
            validate() {
                this.$refs.billingForm.validate();
            },
            getError(fieldName) {
                let familierName = this.$t('beViews.createBe.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                if (fieldName == 'orgName') {
                    let error = this.errors.items.find(c => c.field == 'orgName')
                    if (error != undefined && error.rule == 'max') {
                        return this.$t('beViews.createBe.form.orgName.errLen', [this.$root.config.maxBillingEntityNameLength]);
                    }
                }
                console.log(errMsg);
                return errMsg;
            },
            billingProfileFormValid(result) {
                let vueThis = this;
                this.$validator.validateAll().then(isValid => {
                    if (isValid && result) {
                        vueThis.createNewBEntity();
                    }
                })
            },
            createNewBEntity() {
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.billingEntity.createBillingEntityUrl(), this.formModel)
                    .then(response => {
                        swal.close();
                        vueThis.$router.push({ name: 'beList' });
                        swal.fire({
                            icon: 'success',
                            html: vueThis.$t('beViews.createBe.success', [vueThis.formModel.name]),
                            confirmButtonClass: 'btn btn-success btn-fill',
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });

                    }).catch(function (error) {
                        swal.close();
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('beViews.createBe.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: vueThis.formModel.name }),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
        },
    }
</script>

<style scoped>
</style>