<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center" class="model-wider" :show-header="false">
        <wizard @complete="wizardComplete" :showHeader="false" class="subs-wizard" finishButtonText="Finish Order" prevButtonText="Back" ref="subswizard">
            <template slot="header">
                <h3 class="card-title">New subscription</h3>
            </template>


            <!-- SIZING -->
            <wizard-tab :before-change="tabSubComplete">
                <template slot="label">
                    <i class="now-ui-icons users_circle-08"></i>
                    SIZING
                </template>

                <div class="row">
                    <div class="col-12">
                        <h5 class="" v-if="increaseSub">
                            Please select the new number of employees:
                        </h5>
                        <h5 class="" v-else>
                            Please select the number of employees:
                        </h5>
                    </div>
                    <div class="col-12 mb-3">
                        <el-slider v-model="newSubscription.size" :marks="sliderMarks" :min="sliderMin" :max="sliderCfg.max" show-input :step="sliderCfg.step"
                                   @change="subSizeChanged">
                        </el-slider>
                    </div>
                    <div class="col-12 mt-5" v-if="increaseSub">
                        <h6>
                            CURRENT PRICING
                        </h6>
                        <div class="row sub-table">
                            <div class="col-6 text-muted">
                                Number of employees:
                            </div>
                            <div class="col-6 text-muted">
                                {{NumberFormat(activeSub.size)}}
                            </div>
                            <div class="col-6 text-muted">
                                Price per employee:
                            </div>
                            <div class="col-6 text-muted">
                                {{NumberFormat(activeSub.pricePerHv)}} &euro;
                            </div>
                            <div class="col-6 sub-table-highlight-gray text-muted">
                                <b>Current subscription value (excl. VAT):</b>
                            </div>
                            <div class="col-6  sub-table-highlight-gray text-muted">
                                <b>{{NumberFormat(activeSub.size * activeSub.pricePerHv)}} &euro;</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5">
                        <h6 v-if="increaseSub">
                            NEW PRICING
                        </h6>
                        <h6 v-else>
                            PRICING
                        </h6>
                        <div class="row sub-table">
                            <div class="col-6 text-muted">
                                Number of employees:
                            </div>
                            <div class="col-6 text-muted">
                                {{NumberFormat(newSubscription.size)}}
                            </div>
                            <div class="col-6 text-muted">
                                Price per employee:
                            </div>
                            <div class="col-6 text-muted">
                                {{NumberFormat(pricePerHv)}} &euro;
                            </div>
                            <div class="col-6  sub-table-highlight-green text-muted">
                                <b>Total price (excl. VAT):</b>
                            </div>
                            <div class="col-6  sub-table-highlight-green text-muted">
                                <b>{{NumberFormat(totalPrice)}} &euro;</b>
                            </div>
                        </div>
                        <p class="text-danger mt-2" v-if="increaseSub">
                            *Price will be reduced by the unused prepaid time in the final step of the order.
                        </p>
                    </div>

                    <div class="col-12 mt-5">
                        <p>
                            Subscription can be upgraded anytime. We will reduce the price for the upgrade by the unused prepaid time based on the remaining days and size of the active subscription.
                        </p>
                        <p>
                            If you require subscription for more employees or if you have special requests (custom attack scenarios, training page, awareness page etc.)
                            please <a href="mailto:info@lazyphish.com">contact us</a> for the managed services offer.
                        </p>
                    </div>
                </div>
            </wizard-tab>

            <!-- BILLING DATA -->
            <wizard-tab :before-change="() => validateBillingData()">
                <template slot="label">
                    <i class="now-ui-icons ui-1_email-85"></i>
                    BILLING DETAILS
                </template>
                <h5>
                    Billing Details
                </h5>
                <p>
                    Please review and update the billing details that will be used as billing details. Please do not forget the company ID number and If you are registered for VAT in any EU member state please remember to add valid EU VAT number.
                </p>
                <billingProfileForm :billingProfile="billingData" ref="billingDataForm" defaultCountryCode="CZ" @validationFinished="billingValidationFinished"></billingProfileForm>
            </wizard-tab>

            <!-- PLANNING -->
            <wizard-tab>
                <template slot="label">
                    <i class="now-ui-icons ui-1_settings-gear-63"></i>
                    Planning
                </template>
                <div v-if="increaseSub">
                    <h5>
                        Subscription start date
                    </h5>
                    <p>
                        Please select the start date for your new subscription.
                    </p>

                    <div class="row mb-3 align-items-center">
                        <div class="col-auto">
                            <el-date-picker v-model="newSubscription.startDateUtc" type="date" placeholder="Start date" :default-value="defaultStartDate"
                                            :picker-options="datePickerOptions" :clearable="false">
                            </el-date-picker>
                        </div>
                        <div class="col text-danger">
                            Start date was automatically set on today but it can be changed.
                        </div>
                    </div>

                    <h6>
                        New Subscription Order Details:
                    </h6>
                    <div class="row sub-table mb-3">
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>Start date:</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>{{newSubscription.startDateUtc | moment("DD.MM.YYYY")}}</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b> End date:</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>{{newSubscription.endDateUtc | moment("DD.MM.YYYY")}}</b>
                        </div>
                        <!-- ************************ -->
                        <div class="col-6 text-muted">
                            Number of employees:
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(newSubscription.size)}}
                        </div>
                        <div class="col-6 text-muted">
                            Price per employee:
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(pricePerHv)}} &euro;
                        </div>
                        <div class="col-6  sub-table-highlight-gray text-muted">
                            <b>Total price (excl. VAT):</b>
                        </div>
                        <div class="col-6  sub-table-highlight-gray text-muted">
                            <b>{{NumberFormat(totalPrice)}} &euro;</b>
                        </div>
                    </div>


                    <h6>
                        Current Subscription (Calculation of deduction for unused time)
                    </h6>
                    <div class="row sub-table">
                        <div class="col-6 text-muted ">
                            Number of employees:
                        </div>
                        <div class="col-6 text-muted ">
                            {{activeSub.size}}
                        </div>
                        <div class="col-6 text-muted">
                            Price per employee:
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(activeSub.pricePerHv)}} &euro;
                        </div>
                        <div class="col-6 text-muted">
                            Current subscription value (excl. VAT):
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(activeSub.size * activeSub.pricePerHv)}} &euro;
                        </div>
                        <div class="col-6 text-muted">
                            Start Date:
                        </div>
                        <div class="col-6 text-muted">
                            {{activeSub.startDateUtc | moment("DD.MM.YYYY")}}
                        </div>
                        <div class="col-6  text-muted">
                            Original End Date:
                        </div>
                        <div class="col-6  text-muted">
                            {{activeSub.endDateUtc | moment("DD.MM.YYYY")}}
                        </div>
                        <div class="col-6  text-muted">
                            New End Date (start date of new subscription):
                        </div>
                        <div class="col-6  text-muted">
                            {{newSubscription.endDateUtc | moment("DD.MM.YYYY")}}
                        </div>
                        <div class="col-6  text-muted">
                            Unused Months (&euro; Amout):
                        </div>
                        <div class="col-6  text-muted">
                            {{NumberFormat(subPriceCorrection.oldSubRemainMonths)}} ({{NumberFormat(subPriceCorrection.correctionAmount)}} &euro;)
                        </div>
                        <div class="col-6  text-muted sub-table-highlight-red">
                            Deduction for Unused Time:
                        </div>
                        <div class="col-6  text-muted sub-table-highlight-red">
                            {{NumberFormat(subPriceCorrection.correctionAmount)}} &euro;
                        </div>
                    </div>
                    <p class="text-danger mt-2">
                        *Price will be reduced by the unused prepaid time in the final step of the order.
                    </p>
                </div>
                <div v-else class="">
                    <h5>
                        Subscription Planning
                    </h5>
                    <p>
                        Start date for new subscription is automatically set on today.
                    </p>
                    <div class="row sub-table">
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>Start date:</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>{{newSubscription.startDateUtc | moment("DD.MM.YYYY")}}</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b> End date:</b>
                        </div>
                        <div class="col-6 text-muted sub-table-highlight-green">
                            <b>{{newSubscription.endDateUtc | moment("DD.MM.YYYY")}}</b>
                        </div>
                        <!-- ************************ -->
                        <div class="col-6 text-muted">
                            Number of employees:
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(newSubscription.size)}}
                        </div>
                        <div class="col-6 text-muted">
                            Price per employee:
                        </div>
                        <div class="col-6 text-muted">
                            {{NumberFormat(pricePerHv)}} &euro;
                        </div>
                        <div class="col-6  sub-table-highlight-gray text-muted">
                            <b>Total price (excl. VAT):</b>
                        </div>
                        <div class="col-6  sub-table-highlight-gray text-muted">
                            <b>{{NumberFormat(totalPrice)}} &euro;</b>
                        </div>
                    </div>
                </div>
            </wizard-tab>
            <!-- SUMMARY -->
            <wizard-tab>
                <template slot="label">
                    <i class="now-ui-icons ui-1_email-85"></i>
                    Summary
                </template>
                <h5>
                    Order Summary
                </h5>
                <p>
                    Please find the order summary in the table below.
                </p>
                <div class="row sub-table">
                    <div class="col-6 text-muted">
                        Start date:
                    </div>
                    <div class="col-6 text-muted">
                        {{newSubscription.startDateUtc | moment("DD.MM.YYYY")}}
                    </div>
                    <div class="col-6 text-muted">
                        End date:
                    </div>
                    <div class="col-6 text-muted">
                        {{newSubscription.endDateUtc | moment("DD.MM.YYYY")}}
                    </div>
                    <!-- ************************ -->
                    <div class="col-6 text-muted">
                        Number of employees:
                    </div>
                    <div class="col-6 text-muted">
                        {{NumberFormat(newSubscription.size)}}
                    </div>
                    <div class="col-6 text-muted">
                        Price per employee:
                    </div>
                    <div class="col-6 text-muted">
                        {{NumberFormat(pricePerHv)}} &euro;
                    </div>
                    <!-- ************************ -->
                    <div class="col-6  text-muted sub-table-highlight-gray" v-if="increaseSub">
                        Total Price before Deduction (excl. VAT):
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-gray" v-if="increaseSub">
                        {{NumberFormat(totalPrice)}} &euro;
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-red" v-if="increaseSub">
                        Deduction for Unused Time:
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-red" v-if="increaseSub">
                        {{NumberFormat(subPriceCorrection.correctionAmount)}} &euro;
                    </div>


                    <div class="col-6  text-muted sub-table-highlight-green">
                        <b>Total price to be paid (excl. VAT):</b>
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-green">
                        <b>{{NumberFormat(newSubscription.price)}} &euro;</b>
                    </div>
                    <!-- ************************ -->
                    <div class="col-6  text-muted sub-table-highlight-gray">
                        VAT {{vatInPerc}} %:
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-gray">
                        {{NumberFormat(Math.round((priceIncVat - newSubscription.price) * 100)/100)}} &euro;
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-gray">
                        Total price to be paid (incl. VAT):
                    </div>
                    <div class="col-6  text-muted sub-table-highlight-gray">
                        {{NumberFormat(priceIncVat)}} &euro;
                    </div>
                    <!-- ************************ -->
                    <div class="col-6  text-muted">
                        Payment method:
                    </div>
                    <div class="col-6  text-muted">
                        Credit card
                    </div>
                    <div class="col-6  text-muted align-middle">
                        Billing details:
                    </div>
                    <div class="col-6  text-muted">
                        {{billingData.companyName}}<br />
                        ID: {{billingData.companyNumber}} <br />
                        VAT ID: {{billingData.taxNumber}} <br />
                        {{billingData.address}} <br />
                        {{billingData.postCode}} {{billingData.city}} <br />
                        {{countryCodeToName(billingData.country)}}
                    </div>
                </div>
            </wizard-tab>
        </wizard>
    </modal>
</template>
<script>
    import { SubscriptionTools, Tools } from 'src/lpLibrary.js'
    import { Modal, Wizard, WizardTab, IconCheckbox } from 'src/components/UIComponents'
    import { Select, DatePicker, Option, Slider  } from 'element-ui'
    import BillingProfileForm from 'src/components/UIComponents/BillingProfileForm.vue'
    import { ToggleButton } from 'vue-js-toggle-button'
    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }
    export default {
        name: 'newinc-subscription',
        components: {
            Modal,
            [DatePicker.name]: DatePicker,
            [Select.name]: Select,
            [Option.name]: Option,
            Wizard,
            WizardTab,
            IconCheckbox,
            BillingProfileForm,
            [Slider.name]: Slider,
            ToggleButton: ToggleButton,
        },
        props: {
            show: Boolean,
            billingEntityHID: String,
            subPricing: Array,
            activeSub: Object,
            increaseSub: Boolean,
        },
        data() {
            var vm = this;
            return {
                modalShow: false,
                datePickerOptions: {
                    disabledDate(time) {
                        return vm.disabledDateDatePicker(time);
                    },
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date())
                        }
                    },
                    ]
                },
                modelValidations: {
                },
                billingData: {},
                billingWasValided: false,
                //firstAllowSubDate: null,
                newSubscription: {
                    typeID: 2,
                    price: 0,
                    startDateUtc: null,
                    endDateUtc: null,
                    saleCode: "",
                    size: 100
                },
                subPriceCorrection: {
                    correctionAmount: 0,
                    oldSubPrice: 0,
                    oldSubRemainMonths: 0,
                },
                vatRatio: 0,
                sliderCfg:{
                    min: 50,
                    max: 5000,
                    step: 10,
                },
            }
        },
        methods: {
            onClickCancel: function () {
                this.$emit('hide', false);
            },
            wizardComplete: function () {
                this.newSubscription.startDateUtc = new Date(Date.UTC(this.newSubscription.startDateUtc.getFullYear(), this.newSubscription.startDateUtc.getMonth(), this.newSubscription.startDateUtc.getDate()));
                //this.newSubscription.endDateUtc =  edit v watch
                this.$emit('finish', this.newSubscription, this.billingData);
            },
            clearDialog() {
                this.startDateUtc = null;
                this.newSubscription = {
                    typeID: 2,
                    price: 0,
                    startDateUtc: null,
                    endDateUtc: null,
                    saleCode: "",
                    size: 100,
                };
                //this.firstAllowSubDate = null;
                this.subPriceCorrection = {
                    typePrice: 0,
                    correctionAmount: 0,
                };
                this.$refs.subswizard.navigateToTab(0);
            },
            disabledDateDatePicker(time) {
                let tDate = new Date(time.getFullYear(), time.getMonth(), time.getDate() + 1);
                let faDate = new Date(Date.now());
                //if (this.firstAllowSubDate != null)
                    //faDate = new Date(this.firstAllowSubDate.getFullYear(), this.firstAllowSubDate.getMonth(), this.firstAllowSubDate.getDate());
                let subEndDate = new Date(new Date(this.activeSub.endDateUtc).getFullYear(), new Date(this.activeSub.endDateUtc).getMonth(), new Date(this.activeSub.endDateUtc).getDate() + 1);
                return tDate < faDate || tDate > subEndDate;
            },
            tabSubComplete() {
                this.newSubscription.price = this.newSubscription.size * this.pricePerHv;
                this.newSubscription.startDateUtc = this.getFirstAllowSubDate();
                //this.newSubscription.endDateUtc = edit v watch
                return true;
            },
            updatePrice() {
                if (!this.increaseSub) return;
                if (this.newSubscription.startDateUtc == null) return;

                var newSubStartDate = new Date(this.newSubscription.startDateUtc.getFullYear(), this.newSubscription.startDateUtc.getMonth(), this.newSubscription.startDateUtc.getDate());
                var activeSubEndDate = new Date(new Date(Date.parse(this.activeSub.endDateUtc)).getFullYear(), new Date(Date.parse(this.activeSub.endDateUtc)).getMonth(), new Date(Date.parse(this.activeSub.endDateUtc)).getDate());
                let daysLeft = Math.max(this.$moment(activeSubEndDate).diff(this.$moment(newSubStartDate), 'days') + 1, 0);
                let monthsLeft = Math.max(this.$moment(activeSubEndDate).diff(this.$moment(newSubStartDate), 'months', true), 0);
                if (newSubStartDate > activeSubEndDate)
                    daysLeft = 0;

                let activeSubPriceHvDay = this.activeSub.pricePerHv / 365;
                //this.subPriceCorrection.price = (this.newSubscription.size * this.pricePerHv) + (this.activeSub.size * this.activeSub.pricePerEmp);
                //this.subPriceCorrection.correctionAmount = Math.round(activeSubPriceHvDay * daysLeft * this.activeSub.size * 100) / 100;
                this.subPriceCorrection.price = (this.newSubscription.size * this.pricePerHv);
                this.subPriceCorrection.correctionAmount = Math.round(activeSubPriceHvDay * daysLeft * this.activeSub.size * 100) / 100;
                this.subPriceCorrection.oldSubRemainMonths = Math.round(monthsLeft * 100) / 100;
                this.subPriceCorrection.oldSubPrice = this.activeSub.size * this.activeSub.pricePerHv; // this.activeSub.price;

                let sPrice = this.subPriceCorrection.price - this.subPriceCorrection.correctionAmount;
                this.newSubscription.price = Math.round(sPrice * 100) / 100;
            },
            getFirstAllowSubDate() {
                let fAllowDate = new Date(Date.now());
                if (!this.increaseSub && this.activeSub.endDateUtc != "") {
                    fAllowDate = new Date(this.$moment(this.activeSub.endDateUtc).add(1, 'days').format());
                }
                //this.startSubDateUtc = fAllowDate;
                return fAllowDate;
            },
            validateBillingData() {
                if (this.billingWasValided) {
                    this.vatRatio = this.billingData.status === 'eu' ? 0 : 0.21;
                    this.billingWasValided = false;
                    return true;
                }
                this.$refs.billingDataForm.validate();
                return false;
            },
            billingValidationFinished(result) {
                if (!result) return;
                this.billingWasValided = true;
                this.$refs.subswizard.nextTab();
            },
            countryCodeToName(code) {
                //let country = Tools.Countries().find(c => c.Code == code);
                let country = this.$t("countries").find(c => c.Code == code);
                if (country == undefined) return "";
                return country.Name;
            },
            subSizeChanged(value) {
                this.newSubscription.size = Math.floor(value / 10) * 10;
            }
        },
        mounted: function () {
            this.clearDialog();
        },
        computed: {
            priceIncVat() {
                return Math.round(this.newSubscription.price * (1 + this.vatRatio) * 100) / 100;
            },
            vatInPerc() {
                return this.vatRatio * 100;
            },
            defaultStartDate() {
                //return this.$moment(this.firstAllowSubDate).format('YYYY-MM-DD');
                return this.$moment(this.getFirstAllowSubDate()).format('YYYY-MM-DD');
            },
            sliderMarks() {
                let result = {
                    500: '500',
                    1000: '1000',
                    3000: '3000',
                    5000: '5000',
                };
                return result;
            },
            actualPricing() {
                let sortSubPricing = this.subPricing.sort(function (a, b) { return sortingHandler(a, b, { order: 'descending', name: 'amount' }); });
                return sortSubPricing.find(c => this.newSubscription.size > c.amount);
            },
            pricePerHv() {
                if (this.actualPricing == undefined) return 0;
                return this.actualPricing.price;
            },
            totalPrice() {
                return this.pricePerHv * this.newSubscription.size;
            },
            sliderMin() {
                if (this.increaseSub) {
                    return Math.min(5000,this.activeSub.size + 10);
                }
                return this.sliderCfg.min
            },
        },
        watch: {
            show: function (value) {
                this.modalShow = value;
                this.clearDialog();
                if (this.modalShow) {
                    let vueThis = this;
                    this.axios.post(this.$root.config.billingEntity.getBillingProfilesUrl(this.billingEntityHID), null)
                        .then(response => {
                            //console.log(response.data);
                            this.billingProfiles = response.data;
                            if (this.billingProfiles.length > 0) {
                                this.billingData = this.billingProfiles[0];
                                this.billingWasValided = false;
                            }
                            
                        }).catch(function (ex) {
                            vueThis.writeEx2Log(ex);
                            vueThis.genericErrorAlert();
                        });
                }
            },
            modalShow: function (value) {
                if (!value) {
                    this.$emit('hide', false);
                }
            },
            'newSubscription.startDateUtc': function (newValue, oldValue) {
                this.newSubscription.endDateUtc = new Date(this.$moment(this.newSubscription.startDateUtc).add(1, 'year').add(-1, 'days').format());
                this.updatePrice();
            },
        },
    }
</script>
<style scoped>
    .lp-subscriptions{
        white-space: nowrap;
    }
</style>
