<template>
    <div class="px-3">
        <!-- timeline -->
        <div class="row" ref="campaigntimeline" style="min-height:100px;">
            <div class="col-12 hvresult-timeline"  v-if="showTimeLine">
                <time-line type="simple">
                    <time-line-item inverted :badge-type="hvr.lineType" :badge-icon="hvr.lineIcon" v-for="hvr of campResults" v-bind:key="hvr.campaignResultDetailID">
                        <badge slot="header" :type="hvr.lineType">{{hvr.typeLabel}}</badge>
                        <div slot="content">
                            <div class="row">
                                <div class="col">
                                    <div><label><b>{{$t('campaignTimeLine.item.uidLabel')}}</b> {{hvr.campaignResultHID}} ({{hvr.campaignResultDetailID}})</label></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label><b>{{$t('campaignTimeLine.item.emailLabel')}}</b> {{hvr.hvEmail}}</label>
                                    <label v-if="hvr.channelType === 'SMS'">, <b>{{$t('campaignTimeLine.item.phoneNumberLabel')}}</b> {{hvr.hvPhoneNumber}}</label>
                                </div>
                                <div class="col">
                                    <label><b>{{$t('campaignTimeLine.item.cbNameLabel')}}</b> {{hvr.campaignBlockName}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label><b>{{$t('campaignTimeLine.item.tsLabel')}}</b> {{hvr.timeStampUtc | moment("DD.MM.YYYY HH:mm:ss")}}</label>
                                </div>
                                <div class="col">
                                    <label><b>{{$t('campaignTimeLine.item.ipLabel')}}</b> {{hvr.address}}</label>
                                </div>
                                <div class="col">
                                    <label><b>{{$t('campaignTimeLine.item.browserLabel')}}</b> {{hvr.browserName}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div><label><b>{{$t('campaignTimeLine.item.payloadLabel')}}</b> </label></div>
                                    <div><label>{{hvr.payload}}</label></div>
                                </div>
                            </div>
                        </div>
                        <div slot="footer">
                        </div>
                    </time-line-item>
                </time-line>
            </div>
            <div class="col-12 text-center" v-else>
                <p-button type="neutral" @click="manualLoad()">
                    <el-empty>
                        <span slot="image">
                            <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
                        </span>
                        <p-button slot="description" type="primary">
                            {{$t('campaignTimeLine.btnLoadLabel')}}
                        </p-button>
                    </el-empty>
                </p-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { CampaignTools, HvGroupTools } from 'src/lpLibrary.js'
    import platform from 'platform'


    import { Input, Select, Option, Table, Card, TableColumn, Empty } from 'element-ui'
    import { Modal, Pagination, TimeLine, TimeLineItem, Badge } from 'src/components/UIComponents'

    import swal from 'sweetalert2'

    export default {
        name: 'campaign-timeline',
        props: {
            campaignHID: String,
            entityHID: String,
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            TimeLine,
            TimeLineItem,
            [Badge.name]: Badge,
            [Empty.name]: Empty,
        },
        data() {
            return {
                campResults: [],
                showTimeLine: false,
            }
        },
        methods: {
            initComponent: function () {
                //this.loadResults();                
            },
            loadResults: function (force) {
                if (this.campaignHID === undefined) {
                    return;
                }
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.campaigntimeline));
                this.axios.post(this.$root.config.entity.campaign.campaignTimelineUrl(this.entityHID),  null, { params: { campaignHID: this.campaignHID, take: 10, skip: 0, force: force } })
                    .then(response => {
                        loader.hide();
                        this.campResults = response.data;
                        this.campResults.forEach(item => {
                            let channelType = vueThis.$t('campaignTimeLine.item.defaultChLabel');
                            switch (item.channelType) {
                                case "SMS":
                                    channelType = vueThis.$t('campaignTimeLine.item.smsChLabel');
                                    break;
                                case "Email":
                                    channelType = vueThis.$t('campaignTimeLine.item.emailChLabel');
                                    break;
                            }
                            switch (item.eventType) {
                                case "Sent":
                                    item.lineIcon = "fa-solid fa-paper-plane fa-2x  mr-1";
                                    item.lineType = "sent";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.sentLabel');
                                    break;
                                case "Interacted":
                                    item.lineIcon = "fa-solid fa-envelope-open-text fa-2x";
                                    item.lineType = "interacted";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.interactedLabel');
                                    break;
                                case "LinkClicked":
                                    item.lineIcon = "fa-solid fa-hand-point-up fa-2x";
                                    item.lineType = "linkClicked";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.linkClickedLabel');
                                    break;
                                case "DataSubmitted":
                                    item.lineIcon = "nc-icon nc-paper";
                                    item.lineType = "dataSubmitted";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.dataSubmittedLabel');
                                    break;
                                case "Trained":
                                    item.lineIcon = "fa-brands fa-leanpub fa-2x";
                                    item.lineType = "trained";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.trainedLabel');
                                    break;
                                case "Reported":
                                    item.lineIcon = "fa fa-flag fa-2x";
                                    item.lineType = "reported";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.reportedLabel');
                                    break;
                                case "FileOpened":
                                    item.lineIcon = "fa-regular fa-folder-open fa-2x";
                                    item.lineType = "fileOpened";
                                    item.typeLabel = vueThis.$t('campaignTimeLine.item.fileOpenedLabel');
                                    break;
                                default:
                                    item.lineIcon = "nc-icon";
                                    item.lineType = "default";
                                    item.typeLabel = "NaN";
                            }
                            item.typeLabel = channelType + item.typeLabel;
                            item.browserName = "-";
                            if (item.browser != null && item.browser.length > 0) {
                                let plt = platform.parse(item.browser);;
                                item.browserName = plt.name;
                            }
                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            refresh: function (force) {
                if (this.showTimeLine) {
                    this.loadResults(force);
                }
            },
            manualLoad() {
                this.showTimeLine = true;
                this.$emit('showTimeLineOnChange', this.showTimeLine);
                this.loadResults();
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            campaignId: function (val) {
                this.loadResults();
            },
        },
    }

</script>

<style scoped>
    .hvresult-timeline {
        background-color: #ebecf1;
    }

        .hvresult-timeline .card.card-timeline {
            overflow-x: auto;
            max-height: 500px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
</style>