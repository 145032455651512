<template>
    <div>
        <line-chart :labels="chartXAxisLabel" ref="lineChart"
                    :datasets="chartDataSets"
                    :height="100"
                    :extraOptions="extraOptions">
        </line-chart>
    </div>
</template>

<script>
    import LineChart from 'src/components/UIComponents/Charts/LineChart'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'statistic-by-hvs-chart',
        props: {
            statistic: {
                type: Object,
            },
        },
        components: {
            LineChart,
        },
        data() {
            let vueThis = this;
            return {
                extraOptions: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        //display: true,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0
                            }
                        }]
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                //console.log(tooltipItem, data);
                                if (data['datasets'][tooltipItem['datasetIndex']]['dataProc'] == null) {
                                    return " " + data['datasets'][tooltipItem['datasetIndex']]['label'] + ": " + data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']];

                                }
                                return " " + data['datasets'][tooltipItem['datasetIndex']]['label'] + ": " + data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] + " (" + data['datasets'][tooltipItem['datasetIndex']]['dataProc'][tooltipItem['index']] + " %)";
                            },
                        },
                    },
                },

            }
        },
        computed: {
            chartData() {
                if (this.statistic == undefined || this.statistic.chartData == undefined) return [];
                return this.statistic.chartData;
            },
            chartXAxisLabel() {
                return this.chartData.map(a => this.$moment(a.timeStampUtc).format("DD-MM-YYYY"));
            },
            chartReactedData() {
                let dataProc = null;
                let dataValue = null;
                if (this.chartData != undefined && this.chartData.length > 0) {
                    dataValue = this.chartData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countReacted;
                        return 0;
                    });
                    dataProc = this.chartData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return (100 * a.percentReacted).toFixed(1);
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.statByHvsChart.reactedLabel'),
                    fill: false,
                    backgroundColor: '#ffa500',
                    borderColor: '#ffa500',
                    data: dataValue,
                    dataProc: dataProc,
                };
            },
            chartCompromisedData() {
                let dataProc = null;
                let dataValue = null;
                if (this.chartData != undefined && this.chartData.length > 0) {
                    dataValue = this.chartData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countCompromised;
                        return 0;
                    });
                    dataProc = this.chartData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return (100 * a.percentCompromised).toFixed(1);
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.statByHvsChart.compromisedLabel'),
                    fill: false,
                    backgroundColor: '#ef8157',
                    borderColor: '#ef8157',
                    data: dataValue,
                    dataProc: dataProc,
                };
            },
            chartHvsTData() {
                let dataValue = null;
                if (this.chartData != undefined && this.chartData.length > 0) {
                    dataValue = this.chartData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countHvInTraining;
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.statByHvsChart.hvsLabel'),
                    fill: false,
                    backgroundColor: '#66615b',
                    borderColor: '#66615b',
                    data: dataValue,
                    dataProc: null,
                };
            },
            chartDataSets() {
                return [
                    this.chartHvsTData,
                    this.chartReactedData,
                    this.chartCompromisedData,
                ];
            }
        }
    }
</script>

<style scoped>
</style>