<template>
    <div class="row entity-mgmt pt-2" ref="entContent">
        <div class="col-12">
            <!-- toolbar -->
            <div class="row toolbar">
                <div class="col-12">
                    <div class="">
                        <el-tooltip content="Create new entity" :open-delay="300" placement="top">
                            <p-button type="success" @click="createNewEntity">
                                <i class="nc-icon nc-simple-add"></i>  {{ $t('beViews.entityMgmt.btnNewE')}}
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <!-- header -->
            <!--<div class="row justify-content-between">
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-2">
                    <el-select class="select-default float-sm-left w-100"
                                v-model="e_pagination.perPage"
                                placeholder="Per page">
                        <el-option class="select-default"
                                    v-for="item in e_pagination.perPageOptions"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                    <div class="float-sm-right">
                        <fg-input class="input-sm"
                                    placeholder="Search"
                                    v-model="e_searchQuery"
                                    addon-right-icon="nc-icon nc-zoom-split">
                        </fg-input>
                    </div>
                </div>
            </div>-->
            <!-- table -->
            <div class="row">
                <div class="col-12" ref="tablecontainer">
                    <el-table :data="e_queriedData" ref="eltable" :empty-text="$t('beViews.entityMgmt.table.noData')"
                                :default-sort="{ prop: 'name', order: 'ascending'}"
                                @sort-change="e_handleSortChange">
                        <el-table-column prop="name" sortable="custom" :label="$t('beViews.entityMgmt.table.colEName')">
                        </el-table-column>
                        <el-table-column :width="140" class-name="td-actions" :label="$t('beViews.entityMgmt.table.colActions.header')" align="center">
                            <template slot-scope="props">
                                <el-tooltip :content="$t('beViews.entityMgmt.table.colActions.btnAccess')" :open-delay="300" placement="top">
                                    <p-button type="default" size="sm" icon @click="editRoleMgmt(props.row.hid)">
                                        <i class="fa-solid fa-key" aria-hidden="true"></i>
                                    </p-button>
                                </el-tooltip>
                                <el-tooltip :content="$t('beViews.entityMgmt.table.colActions.btnRename')" :open-delay="300" placement="top">
                                    <p-button type="default" size="sm" icon @click="editEntity(props.row.hid, props.row.name)">
                                        <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                                    </p-button>
                                </el-tooltip>
                                <el-tooltip :content="$t('beViews.entityMgmt.table.colActions.btnDelete')" :open-delay="300" placement="top">
                                    <p-button type="danger" size="sm" icon @click="deleteEntity(props.row.hid, props.row.name)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </p-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- paging -->
            <div class="row">
                <div class="col-sm-6 pagination-info">
                    <p class="category">
                        {{$t('beViews.entityMgmt.pagingInfo', [e_from + 1, e_to,e_total ])}}
                    </p>
                </div>
                <div class="col-sm-6">
                    <p-pagination class="pull-right"
                                    v-model="e_pagination.currentPage"
                                    :per-page="e_pagination.perPage"
                                    :total="e_pagination.total">
                    </p-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Card, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import PPagination from 'src/components/UIComponents/Pagination.vue'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            PPagination,
            StatsCard,
        },
        props: {
            billingEntityHID: String
        },
        data() {
            return {
                entities: [],
                //-------------------
                e_pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25],
                    total: 0
                },
                e_propsToSearch: ['name'],
                e_searchQuery: '',
                e_sortObject: { order: 'ascending', name: 'name' },
            }
        },
        methods: {
            initComponent: function () {
                this.loadEntities();
            },
            loadEntities() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.entContent));
                this.axios.post(this.$root.config.billingEntity.getEntities4BeUrl(this.billingEntityHID), null)
                    .then(response => {
                        loader.hide();
                        vueThis.entities = response.data;
                        //vueThis.$store.commit('updateEntities', this.entities);
                        this.$store.dispatch('updateUser');
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            createNewEntity() {
                let vueThis = this;
                swal.fire({
                    title: 'Enter name of new entity',
                    input: 'text',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'Entity name is required';
                        }
                        if (value.trim().length > this.$root.config.maxEntityNameLength) {
                            return 'Entity name is too long. Max length is ' + vueThis.$root.config.maxEntityNameLength + '.';
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.billingEntity.createEntityUrl(vueThis.billingEntityHID), null, { params: { eName: result.value } })
                        .then(response => {
                            vueThis.loadEntities();
                            vueThis.$store.dispatch('updateUser');
                            vueThis.$emit('onEntityCreated');
                            swal.fire({
                                icon: 'success',
                                html: 'Entity ' + result.value + ' was created',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }).catch(function (error) {
                            if (error.response.status == 400) {
                                switch (error.response.data.code) {
                                    case 100300:
                                        swal.fire({
                                            icon: 'warning',
                                            html: "Entity with the name " + result.value + " already exists.",
                                            confirmButtonClass: 'btn btn-success btn-fill',
                                            timer: vueThis.$root.config.alertTimer15,
                                            timerProgressBar: true,
                                        });
                                        break;
                                    case 100301:
                                        swal.fire({
                                            icon: 'error',
                                            html: "You have reached the limit for the number of entities that can be created under this subscription. If you need more entities please contact our support team.",
                                            confirmButtonClass: 'btn btn-success btn-fill',
                                            timer: vueThis.$root.config.alertTimer15,
                                            timerProgressBar: true,
                                        });
                                        break;
                                    case 100801:
                                        swal.fire({
                                            icon: 'error',
                                            html: "This organisation does not have an active subscription. Please add a subscription before managing the organisation",
                                            confirmButtonClass: 'btn btn-success btn-fill',
                                            timer: vueThis.$root.config.alertTimer15,
                                            timerProgressBar: true,
                                        });
                                        break;
                                    default: {
                                        vueThis.genericErrorAlert();
                                    }
                                }

                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },
            refresh() {
                this.loadEntities();
            },
            editEntity: function (ehid, name) {
                let vueThis = this;
                swal.fire({
                    title: vueThis.$t('beViews.entityMgmt.renameDialog.msg'),
                    input: 'text',
                    inputValue: name,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return vueThis.$t('beViews.entityMgmt.renameDialog.errReq');
                        }
                        if (value.trim().length > this.$root.config.maxEntityNameLength) {
                            return vueThis.$t('beViews.entityMgmt.renameDialog.errMax', [this.$root.config.maxEntityNameLength]);
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.billingEntity.getRenameEntityUrl(vueThis.billingEntityHID), null, { params: { eHID: ehid, name: result.value.trim() } })
                        .then(response => {
                            vueThis.refresh();
                            vueThis.$store.dispatch('updateUser');
                            vueThis.$emit('onEntityChange');
                            swal.fire({
                                icon: 'success',
                                title: vueThis.$t('beViews.entityMgmt.renameDialog.success'),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });                            
                        })
                        .catch(function (error) {
                            if (error.response.status == 400) {
                                let respMsg = error.response.data;
                                if (result == null || result == undefined) {
                                    result = { value: "NaN" };
                                }
                                swal.fire({
                                    title: vueThis.$t('beViews.entityMgmt.renameDialog.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: result.value }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                            }
                        });
                });
            },
            deleteEntity: function (ehid, name) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = `Do you want to delete entity <br/> '${name}'?`;
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        vueThis.axios.post(vueThis.$root.config.billingEntity.deleteEntityUrl(vueThis.billingEntityHID), null, { params: { eHID: ehid } })
                            .then(response => {
                                swal.close();
                                vueThis.refresh();
                                vueThis.$store.dispatch('updateUser');
                                vueThis.$emit('onEntityDeleted');
                                swal.fire({
                                    icon: 'success',
                                    title: `Entity '${name}' was deleted.`,
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                swal.close();
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: 'Deleting of entity failed. Please try it again.',
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            editRoleMgmt(ehid) {
                this.$store.commit('updateBeListTableExpRows', [this.billingEntityHID]);
                this.$router.push({ name: 'eRoleMgmt', params: { behid: this.billingEntityHID, ehid: ehid } })
            },
            //-----------------------------
            e_handleSortChange(e) {
                this.e_sortObject = { order: e.order, name: e.prop };
            },
        },
        computed: {
            //--------------------------
            e_queriedData() {
                var cThis = this;
                if (!this.e_searchQuery) {
                    this.e_pagination.total = this.entities.length;
                    return this.entities.sort(function (a, b) { return sortingHandler(a, b, cThis.e_sortObject); }).slice(this.e_from, this.e_to);
                }
                let result = this.entities
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.e_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.e_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.e_pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.e_sortObject); }).slice(this.e_from, this.e_to);
            },
            e_to() {
                let highBound = this.e_from + this.e_pagination.perPage;
                if (this.e_total < highBound) {
                    highBound = this.e_total;
                }
                return highBound;
            },
            e_from() {
                if (this.e_pagination.total == 0) return -1;
                return this.e_pagination.perPage * (this.e_pagination.currentPage - 1);
            },
            e_total() {
                return this.e_pagination.total;
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            billingEntityHID: function (value) {
                this.loadEntities();
            },
        }
    }
</script>

<style scoped>
</style>