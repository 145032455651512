<template>
    <div>
        <bar-chart :labels="chartXAxisLabel" ref="barChart"
                   :height="100"
                   :datasets="chartDataSets" :extraOptions="extraOptions">
        </bar-chart>
    </div>
</template>

<script>
    import BarChart from 'src/components/UIComponents/Charts/BarChart'
    import { MaturityLevelTools } from 'src/lpLibrary.js'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'hv-maturitylevel-distribution-chart',
        props: {
            chartData: Array,
        },
        components: {
            BarChart,
        },
        data() {
            return {
                extraOptions: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            },
                            stacked: true,
                            scaleLabel: {
                                display: true,
                                labelString: '%'
                            },
                            afterBuildTicks: function (scale) {
                                if (scale.max > 100) {
                                    scale.max = 100;
                                }
                            },
                        }],
                        xAxes: [{
                            stacked: true,
                        }],
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                //console.log(tooltipItem, data);
                                return " " + data['datasets'][tooltipItem['datasetIndex']]['label'] + ": " + data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] + " % (" + data['datasets'][tooltipItem['datasetIndex']]['dataValue'][tooltipItem['index']] + ")";
                            },
                        },
                    },
                },
            };
        },
        methods: {
            countHvInItem(item) {
                return item.hvInMaturityLvl1 + item.hvInMaturityLvl2 + item.hvInMaturityLvl3 + item.hvInMaturityLvl4 + item.hvInMaturityLvl5 + item.hvInMaturityLvlUnrated; 
            }
        },
        computed: {
            chartXAxisLabel() {
                return this.chartData.map(a => this.$moment(a.timeStampUtc).format("DD-MM-YYYY"));
            },
            charthvInMaturityLvl1Data() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {                 
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvl1 / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvl1);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group1, true),
                    backgroundColor: MaturityLevelTools.Color_Group1_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            charthvInMaturityLvl2Data() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {                  
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvl2 / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvl2);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group2, true),
                    backgroundColor: MaturityLevelTools.Color_Group2_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            charthvInMaturityLvl3Data() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {                  
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvl3 / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvl3);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group3, true),
                    backgroundColor: MaturityLevelTools.Color_Group3_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            charthvInMaturityLvl4Data() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {                   
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvl4 / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvl4);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group4, true),
                    backgroundColor: MaturityLevelTools.Color_Group4_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            charthvInMaturityLvl5Data() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {               
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvl5 / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvl5);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group5, true),
                    backgroundColor: MaturityLevelTools.Color_Group5_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            charthvInhvInMaturityLvlUnratedData() {
                let dataProc = [];
                let dataValue = [];
                if (this.chartData != undefined) {               
                    dataProc = this.chartData.map(a => {
                        let totalHv = this.countHvInItem(a);
                        if (totalHv == 0) return 0;
                        return (100 * (a.hvInMaturityLvlUnrated / totalHv)).toFixed(1);
                    });
                    dataValue = this.chartData.map(a => a.hvInMaturityLvlUnrated);
                }
                return {
                    label: MaturityLevelTools.translateGroupName(MaturityLevelTools.Name_Group0, true),
                    backgroundColor: MaturityLevelTools.Color_Group0_Basic,
                    data: dataProc,
                    dataValue: dataValue,
                };
            },
            chartDataSets() {
                return [
                    this.charthvInMaturityLvl1Data,
                    this.charthvInMaturityLvl2Data,
                    this.charthvInMaturityLvl3Data,
                    this.charthvInMaturityLvl4Data,
                    this.charthvInMaturityLvl5Data,
                    this.charthvInhvInMaturityLvlUnratedData,
                ];

            },
        },
    }
</script>

<style scoped>
</style>