<template>
    <div class="card card-circle-select" :class="classes">
        <div class="card-header">
            <p class="card-title">{{title}}</p>
        </div>


        <div class="card-body">
            <div class="row d-flex justify-content-center">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2" v-for="item in items">
                    <icon-checkbox :icon="item.icon" :iconText="item.iconText" :title="item.label" :checked="item.isSelected" @input="(c)=>changeLang(item.key, c)">
                    </icon-checkbox>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <slot name="footer">

            </slot>
        </div>
    </div>
</template>

<script>
    import { IconCheckbox } from 'src/components/UIComponents'

    export default {
        name: 'circle-select-card',
        components: {
            IconCheckbox,
        },
        props: {
            title: {
                type: String,
                description: 'Card title'
            },
            items: {
                type: Array,
                default: () => []
            },
            size: {
                type: String,
                description: "lg|sm"
            }
        },
        methods: {
            changeLang(lang, checked) {
                this.$emit('changeLang', lang, checked);
            }
        },
        computed: {
            classes() {
                let rootClasses = [];
                if (this.size) {
                    rootClasses.push(`select-${this.size}`)
                }
                return rootClasses;
            }
        }
    }
</script>

<style scoped>
</style>