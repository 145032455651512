<template>
    <div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card" data-v-step="status-card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title">{{$t('helpSupportView.cardStatus.header')}}</h4>
                            </div>
                            <div class="col text-right icon">
                                <i class="fa fa-heartbeat fa-3x" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <p>
                            {{$t('helpSupportView.cardStatus.text1')}}
                        </p>

                        <p>
                            {{$t('helpSupportView.cardStatus.text2')}}
                        </p>

                        <p>
                            https://lazyphish.statuspage.io
                        </p>

                        <a class="btn btn-info" href="https://lazyphish.statuspage.io" target="_blank">{{$t('helpSupportView.cardStatus.btnOpenStatusPage')}}</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card" data-v-step="helpdesk-card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title">{{$t('helpSupportView.cardHelpDesk.header')}}</h4>
                            </div>
                            <div class="col text-right icon">
                                <i class="fa fa-life-ring fa-3x" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <p>
                            {{$t('helpSupportView.cardHelpDesk.text1')}}
                        </p>

                        <p>
                            {{$t('helpSupportView.cardHelpDesk.text2')}}
                        </p>

                        <p>
                            https://lazycompany.atlassian.net/servicedesk
                        </p>

                        <a class="btn btn-warning" href="https://lazycompany.atlassian.net/servicedesk" target="_blank">{{$t('helpSupportView.cardHelpDesk.btnOpenHelpDeskPage')}}</a>
                    </div>
                </div>
            </div>



            <!--<div class="col-12 col-md-6">
            <div class="card" data-v-step="link-guide">
                <div class="card-header">
                    <div class="row">
                        <div class="col d-flex justify-content-start">
                            <h4 class="card-title">Documentation</h4>
                        </div>
                        <div class="col text-right icon">
                            <i class="fa fa-book fa-3x" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <div class="card-body" ref="docLinks">

                    <div class="doc-section" v-for="dSection in docLinks">
                        <a :href="dSection.link" target="_blank"><h5><i class="fa fa-book mr-1" aria-hidden="true"></i>{{dSection.name}}</h5></a>
                        <ul class="fa-ul">
                            <li class="mt-1" v-for="dlink in dSection.children">
                                <i class="fa fa-file-text-o"></i>
                                <a class="ml-1" :href="dlink.link" target="_blank">{{dlink.name}}</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>-->
            <!--<div class="col-12 col-md-6">
            <div class="row h-50">
                <div class="col-12">
                    <div class="card" data-v-step="status-card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-flex justify-content-start">
                                    <h4 class="card-title">LazyPhish Status</h4>
                                </div>
                                <div class="col text-right icon">
                                    <i class="fa fa-heartbeat fa-3x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <p>
                                In case of any issues please check LazyPhish status page for any incidents that we may be currently facing. You will find here the actual status of all our services and all the relevant details for any open incident.
                            </p>

                            <p>
                                We recommend you to bookmark this page to check the status of our products independently.
                            </p>

                            <p>
                                https://lazyphish.statuspage.io
                            </p>

                            <a class="btn btn-info" href="https://lazyphish.statuspage.io" target="_blank">OPEN STATUS PAGE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row h-50">
                <div class="col-12">
                    <div class="card" data-v-step="helpdesk-card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col d-flex justify-content-start">
                                    <h4 class="card-title">LazyPhish HelpDesk</h4>
                                </div>
                                <div class="col text-right icon">
                                    <i class="fa fa-life-ring fa-3x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <p>
                                If you are facing any issues and you did not find answer in our documentation you can always submit a support ticket in our helpdesk.
                            </p>

                            <p>
                                Use help desk systems for any issues or questions related to technical support, billing, data privacy, bug reporting, features recommendation or other questions.
                            </p>

                            <p>
                                https://lazycompany.atlassian.net/servicedesk
                            </p>

                            <a class="btn btn-warning" href="https://lazycompany.atlassian.net/servicedesk" target="_blank">OPEN HELPDESK</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
        </div>

        </div>
</template>

<script>
    import { Card } from 'src/components/UIComponents'

    export default {
        components: {
            Card
        },
        data() {
            return {
                docLinks: [],
            }
        },
        methods: {
        },
        mounted() {
            //let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.docLinks));
            //let url = "https://prodlpstorage.blob.core.windows.net/public/helpsupport/links.en.json";
            //if (this.$root.config.envName === "dev" || this.$root.config.envName === "devAzure")
            //    url = "https://devlpstorage.blob.core.windows.net/public/helpsupport/links.en.json";
            //if (process.env.this.$root.config.envName === "test")
            //    url = "https://testlpstorage.blob.core.windows.net/public/helpsupport/links.en.json";

            //this.axios.post(url, null, { withCredentials: false }).then(response => {
            //    this.docLinks = response.data;
            //    loader.hide();
            //}).catch(function (ex) {
            //    vueThis.writeEx2Log(ex);
            //    loader.hide();
            //});
        }
    }
</script>

<style scoped>
    .icon {
        color: #6d6d6c;
    }
    .doc-section .fa {
        color: #6d6d6c;
    }
</style>