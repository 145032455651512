<template>
    <div>
        <div class="mb-3 d-print-none">
            <div class="btn-group report-btn-group d-none d-md-block" data-v-step="step-switch">
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : view === 'entity'}" @click="changeView('entity')"> <i class="fa fa-building mr-1"></i>{{$t('entityViews.eReport.filter.entityLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : view === 'groups'}" @click="changeView('groups')"> <i class="fa fa-users mr-1"></i> {{$t('entityViews.eReport.filter.hvgLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : view === 'hvs'}" @click="changeView('hvs')"> <i class="fa fa-user mr-1"></i> {{$t('entityViews.eReport.filter.hvLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel :class="{ 'active' : view === 'genreports'}" @click="changeView('genreports')"> <i class="fa fa-file-text mr-1"></i> {{$t('entityViews.eReport.filter.genReportsLabel')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('entity')" :class="{active: view === 'entity' }"> <i class="fa fa-building"></i> {{$t('entityViews.eReport.filter.entityLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('groups')" :class="{active: view === 'groups' }"> <i class="fa fa-users"></i> {{$t('entityViews.eReport.filter.hvgLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('hvs')" :class="{active:view === 'hvs' }"> <i class="fa fa-user"></i> {{$t('entityViews.eReport.filter.hvLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('genreports')" :class="{active: view === 'genreports' }"> <i class="fa fa-file-text"></i> {{$t('entityViews.eReport.filter.genReportsLabel')}}</p-button>
                </div>
            </div>
        </div>



        <!-- entity -->
        <report viewBy="entity" :entityHID="entityHID" v-if="view === 'entity'" ref="entityReportView"></report>

        <!-- groups -->
        <group-report-table v-if="view === 'groups'" :entityHID="entityHID" ref="gTable" :title="$t('entityViews.eReport.groupReportTable.header')" :description="$t('entityViews.eReport.groupReportTable.text')"></group-report-table>


        <!-- HVs -->
        <div class="row" v-if="view === 'hvs'">
            <div class="col-12">
                <hv-table ref="hvTable" :entityHID="entityHID" 
                          viewBy="entity" orderBy="score"
                          :title="$t('entityViews.eReport.hvReportTable.header')"
                          :description="$t('entityViews.eReport.hvReportTable.text')">
                </hv-table>
            </div>
        </div>

        <!-- periodical -->
        <div ref="periodicalContent">
            <div class="card" v-if="view === 'genreports'">
                <div class="card-header">
                    <div class="row">
                        <div class="col d-flex justify-content-start">
                            <h4 class="card-title" data-v-step="step-name">{{$t('entityViews.eReport.genReports.header')}}</h4>
                        </div>
                        <div class="col text-right">
                            <el-tooltip :content="$t('entityViews.eReport.genReports.btnRefreshTooltip')" :open-delay="300" placement="top">
                                <p-button type="neutral" class="mr-2" icon @click="refresh()">
                                    <i class="fa-solid fa-arrows-rotate"></i>
                                </p-button>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-muted">
                                {{$t('entityViews.eReport.genReports.text')}}
                            </p>
                            <p class="text-muted">
                                <i class="fa fa-file-text ml-1"></i> {{$t('entityViews.eReport.genReports.campType')}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <!-- toolbar -->
                    <div class="row toolbar">
                        <div class="col-12">

                        </div>
                    </div>
                    <!-- header -->
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-6 pt-0 pt-md-2">

                        </div>
                        <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                            <div class="d-flex justify-content-end">
                                <fg-input class="input-sm  mr-2"
                                          :placeholder="$t('entityViews.eReport.genReports.searchPlaceholder')"
                                          v-model="searchQuery"
                                          addon-right-icon="nc-icon nc-zoom-split">
                                </fg-input>
                                <el-select class="select-default float-sm-left page-size"
                                           v-model="pagination.perPage">
                                    <el-option class="select-default"
                                               v-for="item in pagination.perPageOptions"
                                               :key="item"
                                               :label="item"
                                               :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <!-- table -->
                    <div class="row">
                        <div class="col-12" ref="table">
                            <el-table :data="queriedData" :empty-text="$t('entityViews.eReport.genReports.table.noData')"
                                      :default-sort="{ prop: 'createdOn', order: 'descending'}"
                                      @sort-change="handleSortChange">
                                <el-table-column prop="name" sortable="custom" :label="$t('entityViews.eReport.genReports.table.colName')">
                                </el-table-column>
                                <el-table-column prop="createdOn" sortable="custom" :label="$t('entityViews.eReport.genReports.table.colCreated')">
                                    <template slot-scope="props">
                                        {{props.row.createdOn | moment("DD.MM.YYYY HH:mm:ss")}}
                                    </template>
                                </el-table-column>
                                <el-table-column :width="160" align="center"
                                                 class-name="td-actions">
                                    <template slot="header" slot-scope="scope">
                                        <span data-v-step="step-actions">{{$t('entityViews.eReport.genReports.table.colActions.header')}}</span>
                                    </template>
                                    <template slot-scope="props">
                                        <div v-if="props.row.isDownloadable">
                                            <el-tooltip :content="$t('entityViews.eReport.genReports.table.colActions.btnDownload')" :open-delay="300" placement="top">
                                                <a :href="props.row.dwnlnk" class="btn btn-icon btn-default btn-sm">
                                                    <i class="fa fa-download" aria-hidden="true"></i>
                                                </a>
                                            </el-tooltip>
                                        </div>
                                        <div v-else>

                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </div>
                    </div>
                    <!-- paging -->
                    <div class="row">
                        <div class="col-sm-6 pagination-info">
                            <p class="category">{{$t('entityViews.eReport.genReports.pagingInfo', [from + 1, to, total])}}</p>
                        </div>
                        <div class="col-sm-6">
                            <p-pagination class="pull-right"
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total="pagination.total">
                            </p-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ReportTools } from 'src/lpLibrary.js'
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Modal, Card, Pagination } from 'src/components/UIComponents'
    import Report from 'src/components/UIComponents/Report.vue'
    import HvTable from 'src/components/UIComponents/HVResult/HVTable.vue'
    import GReportTable from 'src/components/UIComponents/GroupReportTable.vue'

    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            Report,
            [HvTable.name]: HvTable,
            [GReportTable.name]: GReportTable,
        },
        data() {
            return {
                reports: [],
                view: 'entity',
                entityHID: '',
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                propsToSearch: ['name'],
                searchQuery: '',
                sortObject: { order: 'descending', name: 'createdOn' },
            }
        },
        methods: {
            initComponent: function () {
                if (this.view === "entity") {
                    setTimeout(() => this.$refs.entityReportView.refresh(), 300);
                }
                //if (this.view === "hvs") {
                //    setTimeout(() => this.$refs.hvTable.refresh(), 300);
                //}
                if (this.view === "groups") {
                    setTimeout(() => this.$refs.gTable.refresh(), 300);
                }
                if (this.view === "genreports") {
                    this.loadReports();
                }
            },
            loadReports() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.periodicalContent));
                this.axios.post(this.$root.config.entity.report.getReportsUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        this.reports = response.data;
                        this.reports.forEach(function (item) {
                            item.dwnlnk = vueThis.$root.config.entity.report.getDownloadReportUrl(vueThis.entityHID) + "?reportHID=" + item.hid;
                            item.isDownloadable = item.status == ReportTools.Status_ReportComplete;
                        }); 
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            refresh() {
                if (this.view === "genreports") {
                    this.loadReports();
                }
            },
            changeView(val) {
                if (this.view != val) {
                    this.$router.push({ name: 'eReport', query: { v: val } });
                }
                else {
                    this.view = val;
                }
            },
            //----------
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },

        },
        computed: {

            //--------------------
            queriedData() {
                var cThis = this;
                if (!this.searchQuery) {
                    this.pagination.total = this.reports.length;
                    return this.reports.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }
                let result = this.reports
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.pagination.total;
            },
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    let v = this.$route.query.v;
                    if (v == undefined) v = 'entity';
                    this.view = v;
                    this.initComponent();
                }
            }
        },
        mounted: function () {
            this.initComponent();
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            let v = this.$route.query.v;
            if (v == undefined) v = 'entity';
            this.view = v;
        }
    }
</script>

<style scoped>
</style>