<template>
    <div class="card lazyai-setup-card" :class="classes">
        <div class="card-header">
            <p class="card-title">{{title}}</p>
        </div>
        <div class="card-body">
            <div class="row d-flex justify-content-center">
                <div class="col mx-3" v-for="m in modes">
                    <icon-checkbox :icon="m.icon" :title="m.title" :checked="mode == m.mode" @input="changeMode(m.mode)">
                    </icon-checkbox>
                    <hr />
                    <div class="text-center">
                        <span class="subs-period ml-1"> {{m.text}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <slot name="footer">

            </slot>
        </div>
    </div>
</template>

<script>
    import { IconCheckbox } from 'src/components/UIComponents'

    export default {
        name: 'lazyai-setup-card',
        components: {
            IconCheckbox,
        },
        props: {
            title: {
                type: String,
                description: 'Card title',
            },
            mode: {
                type: String,
                default: '',
            },
            modes: {
                type: Array
            },
            size: {
                type: String,
                description: "lg|sm"
            }
        },
        methods: {
            changeMode(mode) {
                this.$emit('changeMode', mode);
            }
        },  
        computed: {
            classes() {
                let rootClasses = [];
                if (this.size) {
                    rootClasses.push(`select-${this.size}`)
                }
                return rootClasses;
            }
        }
    }
</script>

<style scoped>
    .card-content {
        min-height: 85px
    }
</style>