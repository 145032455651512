<template>
    <div class="stepper" :class="{disable : disabled}">
        <step-item v-for="(item, index) in steps" 
                   :title="item.title" :icon="item.icon" :status="item.status" :description="item.description" :lineClass="item.lineClass"
                   :key="index">
        </step-item>
    </div>
</template>

<script>

    import StepItem from './StepItem.vue'


    export default {
        name: 'stepper',
        components: {
            StepItem,
        },
        props: {
            steps: {
                type: Array,
                default: [],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>
</style>