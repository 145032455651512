<template>
    <div class="step-item" :class="status">
        <!--<div class="step-icon">
            <span class="fa-stack fa-3x" v-if="status === 'complete'">
                <i class="fa-solid fa-circle fa-stack-2x"></i>
                <i class="fa-stack-1x fa-inverse" :class="icon"></i>
            </span>
            <span class="fa-stack fa-3x" v-else-if="status === 'active'">
                <i class="fa-regular fa-circle fa-stack-2x"></i>
                <i class="fa-stack-1x fa-beat-fade" :class="icon"></i>
            </span>
            <span class="fa-stack fa-3x" v-else>
                <i class="fa-regular fa-circle fa-stack-2x"></i>
                <i class="fa-stack-1x" :class="icon"></i>
            </span>
        </div>-->
        <div class="step-icon mb-2">
            <span class="fa-2x" v-if="status === 'complete'">
                <i class="" :class="icon"></i>
            </span>
            <span class="fa-2x" v-else-if="status === 'active'">
                <i class="fa-beat-fade" :class="icon"></i>
            </span>
            <span class="fa-2x" v-else>
                <i class="" :class="icon"></i>
            </span>
        </div>
        <div class="step-point-wrapper">
            <div class="step-point"></div>
        </div>
        <h6 class="step-title mt-3">{{title}}</h6>
        <p class="step-desc mt-2 mx-3">
            {{description}}
        </p>
        <div class="step-line-wrapper" :class="lineClass">
            <div class="step-line">
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'step-item',
        props: {
            status: {
                type: String,
                default: '',
                description: '[|active|complete]'
            },
            title: String,
            icon: String,
            description: String,
            lineClass: String,
        },
    }
</script>

<style scoped>
</style>