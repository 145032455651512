<template>
    <div >
        <el-tooltip :content="tooltipText" :open-delay="300" :placement="tooltipPlacement" :disabled="tooltipText == null">
            <p-button :icon="onlyIcon" :size="size" @click="uploadFile" v-bind:style="{ 'background-color': backgroundColor + '!important' }" class="w-100">
                <i :class="icon" v-bind:style="{ 'background-color': backgroundColor }"></i> {{btnLabel}}
            </p-button>
        </el-tooltip>
        <input type="file" @change="handleFileChange" style="display: none;" ref="fileinput" accept=".csv,.txt" />
    </div>
</template>
<script>
    import swal from 'sweetalert2'

    export default {
        name: 'upload-button',
        props: {
            color: {
                type: String,
                description: 'Button color'
            },
            backgroundColor: {
                type: String,
                description: 'Button background color'
            },
            tooltipText: {
                type: String,
                description: 'Tooltip text'
            },
            tooltipPlacement: {
                type: String,
                description: 'Tooltip placement'
            },
            icon: {
                type: String,
                description: 'Button icon'
            },
            url: {
                type: String,
                description: 'URL of server for upload file'
            },
            groupHID: {
                type: String,
                description: 'GroupHID for uploading employees'
            },
            entityHID: {
                type: String,
                description: 'EntityHID for uploading employees'
            },
            mode: {
                type: String,
                default: 'toGroup',
                description: 'toEntity|toGroup'
            },
            size: {
                type: String,
                default: '',
                description: 'nothing|lg|sm',
            },
            hvGroupHasRunningCampaign: {
                type: Boolean,
                default: false
            },
            btnLabel: {
                type: String,
                default: '',
            },
            uploadMode: {
                type: String,
                default: 'addOrUpdate',
                dascription: 'noIncludedRemove|addOrUpdate'
            }
        },
        data() {
            return {
                csvStruct: {
                    toGroup: this.$t('uploadButton.toGroupLabel'),
                    toEntity: this.$t('uploadButton.toEntityLabel'),
                }
            }
        },
        methods: {
            handleFileChange(e) {
                let csvfile = e.target.files[0];
                let formData = new FormData();
                formData.append('entityHID', this.entityHID);
                if (this.mode === 'toGroup')
                    formData.append('groupHID', this.groupHID);
                formData.append('mode', this.mode);
                formData.append('uploadMode', this.uploadMode);
                formData.append('dataFile', csvfile);
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                let vueThis = this;
                this.axios.post(this.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(response => {
                        swal.close();
                        vueThis.$emit('uploadFinished', true);
                    }).catch(function (error) {
                        swal.close();
                        if (error.response.status == 400) {
                            vueThis.$emit('uploadFinished', false, error.response.data.code, error.response.data.description, error.response.data.data);
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.$emit('uploadFinished', false);
                        }
                    });
            },
            async uploadFile() {
                this.$refs.fileinput.value = "";
                if (this.hvGroupHasRunningCampaign) {
                    //console.log('running');
                    let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                    cfg.icon = 'warning',
                        cfg.title = this.$t('uploadButton.groupHasRunCampAlert.msg');
                    let { value: confirm } = await swal.fire(cfg);
                    if (!confirm)
                        return;
                }

                let msg = this.csvStruct.toGroup;
                if (this.mode === 'toEntity')
                    msg = this.csvStruct.toEntity;

                swal.fire({
                    icon: 'info',
                    title: this.$t('uploadButton.uploadHeader'),
                    html: msg,
                    showCancelButton: true,
                    onClose: () => {

                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.fileinput.click();
                    }
                });
            },

        },
        computed: {
            onlyIcon: function() {
                return (this.btnLabel == "" || this.btnLabel == undefined);
            }
        }
    }

</script>
<style scoped>
    .card-content {
        min-height: 85px
    }

    .icon-big >>> i {
        border-radius: 50%;
    }

    .icon-big >>> i:before {
        padding: 10px;
    }

</style>
