<template>
    <div>
        <input ref="status" v-model="billingProfile.status" hidden />

        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <div class="btn-group campaign-btn-group d-none d-md-block" data-v-step="step-bpf-switch">
                    <p-button type="primary" round outline nativeLabel @click="changeBPStatus('cz')" :class="{active: billingProfile.status === 'cz' }">
                        {{ $t('billingProfileForm.status.czechRepublic')}}
                    </p-button>

                    <p-button type="primary" round outline nativeLabel @click="changeBPStatus('eu')" :class="{active: billingProfile.status === 'eu' }">
                        {{ $t('billingProfileForm.status.eu')}}
                    </p-button>

                    <p-button type="primary" round outline nativeLabel @click="changeBPStatus('noeu')" :class="{active: billingProfile.status === 'noeu' }">
                        {{ $t('billingProfileForm.status.noEU')}}
                    </p-button>
                </div>
                <div class="row d-md-none">
                    <div class="col-12 col-md-4">
                        <p-button type="primary" class="w-100" outline nativeLabel @click="changeBPStatus('cz')" :class="{active: billingProfile.status === 'cz' }">
                            {{ $t('billingProfileForm.status.czechRepublic')}}
                        </p-button>
                    </div>

                    <div class="col-12 col-md-4">
                        <p-button type="primary" class="w-100" outline nativeLabel @click="changeBPStatus('eu')" :class="{active: billingProfile.status === 'eu' }">
                            {{ $t('billingProfileForm.status.eu')}}
                        </p-button>
                    </div>

                    <div class="col-12 col-md-4">
                        <p-button type="primary" class="w-100" outline nativeLabel @click="changeBPStatus('noeu')" :class="{active: billingProfile.status === 'noeu' }">
                            {{ $t('billingProfileForm.status.noEU')}}
                        </p-button>
                    </div>
                </div>
            </div>
        </div>

        <div data-v-step="step-bpf-bebilling">
            <div class="row">
                <div class="col-12">
                    <fg-input type="text"
                              :label="$t('billingProfileForm.companyName.label')"
                              :placeholder="$t('billingProfileForm.companyName.placeholder')" v-validate="modelValidations.companyName" name="companyName"
                              v-model="billingProfile.companyName" :error="getError('companyName')">
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <fg-input type="text"
                              :label="$t('billingProfileForm.companyNumber.label')"
                              :placeholder="$t('billingProfileForm.companyNumber.placeholder')" v-validate="modelValidations.companyNumber" name="companyNumber" 
                              v-model="billingProfile.companyNumber" :error="getError('companyNumber')">
                    </fg-input>
                </div>
                <div class="col-md-6">
                    <fg-input type="text" data-v-step="step-bpf-vat"
                              :label="$t('billingProfileForm.taxNumber.label')"
                              :placeholder="$t('billingProfileForm.taxNumber.placeholder')" v-validate="modelValidations.taxNumber" name="taxNumber" 
                              v-model="billingProfile.taxNumber" :error="getError('taxNumber')">
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <fg-input type="text"
                              :label="$t('billingProfileForm.address.label')"
                              :placeholder="$t('billingProfileForm.address.placeholder')" v-validate="modelValidations.address" name="address"
                              v-model="billingProfile.address" :error="getError('address')">
                    </fg-input>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <fg-input type="text"
                              :label="$t('billingProfileForm.city.label')"
                              :placeholder="$t('billingProfileForm.city.placeholder')" v-validate="modelValidations.city" name="city"
                              v-model="billingProfile.city" :error="getError('city')">
                    </fg-input>
                </div>
                <div class="col-md-4">
                    <fg-input type="text"
                              :label="$t('billingProfileForm.postCode.label')"
                              :placeholder="$t('billingProfileForm.postCode.placeholder')" v-validate="modelValidations.postCode" name="postCode"
                              v-model="billingProfile.postCode" :error="getError('postCode')">
                    </fg-input>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{$t('billingProfileForm.country.label')}}</label>
                        <fg-input :error="getError('country')">
                            <el-select class="select-default" filterable
                                       :placeholder="$t('billingProfileForm.country.placeholder')" name="country" v-validate="modelValidations.country"
                                       v-model="billingProfile.country" :disabled="disableCountrySelector">
                                <el-option v-for="item in countries"
                                           class="select-default"
                                           :value="item.Code"
                                           :label="item.Name"
                                           :key="item.Code">
                                </el-option>
                            </el-select>
                        </fg-input>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { Select, Option } from 'element-ui'
    import { Tools } from 'src/lpLibrary.js'

    export default {
        name: 'billingProfileForm',
        components: {
            [Select.name]: Select,
            [Option.name]: Option
        },
        props: {
            billingProfile: Object,
            defaultCountryCode: {
                type: String,
                default: "",
            }
        },
        data() {
            let vueThis = this;
            return {
                modelValidations: {
                    //firstName: {
                    //    required: true,
                    //    max: 200,
                    //},
                    //lastName: {
                    //    required: true,
                    //    max: 200,
                    //},
                    companyName: {
                        required: true,
                        max: 100,
                    },
                    companyNumber: {
                        required: true,
                        max: 20,
                        numeric: true,
                    },
                    taxNumber: {
                        required: true,
                        max: 20,
                        taxnumber: 'status',
                    },
                    address: {
                        required: true,
                        max: 100,
                    },
                    city: {
                        required: true,
                        max: 20,
                    },
                    postCode: {
                        required: true,
                        max: 10,
                        numeric: true,
                    },
                    country: {
                        required: true,
                    },
                },
            }
        },
        methods: {
            async validate() {
                this.$validator.validateAll().then(isValid => {
                    this.fillByStatus();
                    if (isValid) {
                        this.$emit('validationFinished', true);
                    }
                    this.$emit('validationFinished', false);
                })
            },
            getError(fieldName) {
                let familierName = this.$t('billingProfileForm.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
            changeBPStatus(status) {
                this.billingProfile.status = status;
            },
            fillByStatus() {
                //this.modelValidations.taxNumber.required = true;
                switch (this.billingProfile.status) {
                    case 'eu':
                        break;
                    case 'cz':
                        this.billingProfile.country = 'CZ';
                        //this.modelValidations.taxNumber.required = false;
                        break;
                    case 'noeu':
                        break;
                }
            }
        },
        computed: {
            countries: function () {
                return this.$t("countries");
                //return Tools.Countries();                
            },
            disableCountrySelector() {
                return this.billingProfile.status == 'cz';
            },
        },
        watch: {
            'billingProfile.status': function () {
                this.fillByStatus();
            },
        },
        mounted() {
            this.fillByStatus();
        },
    }
</script>

<style scoped>
</style>