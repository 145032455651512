<template>
    <div ref="hvReportContent">
        <div class="row">
            <div class="col-12">
                <report viewBy="hv" :entityHID="entityHID" :hvHID="hvHID" ref="hvReportView"></report>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, } from 'src/components/UIComponents'
    import Report from 'src/components/UIComponents/Report.vue'

    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            Report,
        },
        data() {
            return {
                hvHID: null,
            }
        },
        methods: {
            initComponent() {
                setTimeout(() => this.$refs.hvReportView.refresh(), 200);
            },
            refresh() {
                this.initComponent();
            },
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    if (this.entityHID != this.$route.params.ehid) {
                        this.$router.push({ name: 'eReport', query: { v: 'hvs' } });
                    }
                    else {
                        this.entityHID = this.$route.params.ehid;
                        this.hvHID = this.$route.params.hvhid;
                        this.initComponent();
                    }
                }
            }
        },
        mounted: function () {
            this.initComponent();
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            this.hvHID = this.$route.params.hvhid;
        }
    }

</script>

<style scoped>
</style>