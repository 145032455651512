<template>
    <div ref="content" class="campaign-container">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'eCampaignsList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">
                        <span class="" v-if="campaign.isTest">{{ $t('entityViews.campaign.testPrefix')}}</span>
                        {{campaign.name}}
                        <el-tooltip class="ml-4" :content="$t('entityViews.campaign.noTrainingTooltip')" :open-delay="300" placement="bottom" v-if="campaign.anonymousMode">
                            <i class="fa-stack">
                                <i class="fa fa-graduation-cap fa-stack-1x campaign-info-icon"></i>
                                <i class="fa fa-ban fa-stack-2x  campaign-info-icon"></i>
                            </i>
                        </el-tooltip>
                        <el-tooltip class="ml-4" :content="$t('entityViews.campaign.trainingTooltip')" :open-delay="300" placement="bottom" v-else>
                            <i class="fa fa-graduation-cap campaign-info-icon" aria-hidden="true"></i>
                        </el-tooltip>
                        <el-tooltip class="ml-2" :content="$t('entityViews.campaign.qaTooltip')" :open-delay="300" placement="bottom" v-if="campaign.isQA">
                            <i class="fa-solid fa-user-check campaign-info-icon" aria-hidden="true"></i>
                        </el-tooltip>
                    </p>
                    <p class="category">{{ $t('entityViews.campaign.header')}}</p>
                </div>
            </div>
            <div class="col text-right">
                <el-tooltip :content="$t('entityViews.campaign.btnDownloadReportTooltip')" :open-delay="300" placement="top" v-if="campaign.isFinished">
                    <a :href="campaign.reportDwnLnk" class="btn btn-icon btn-outline-default mr-2">
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.campaign.btnRenameTooltip')" :open-delay="300" placement="top" v-if="lazyAIStopped && manualControl">
                    <p-button type="default" class="mr-2" icon @click="editCampaign()">
                        <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.campaign.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh()">
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.campaign.btnPauseTooltip')" :open-delay="300" placement="top" v-if="lazyAIStopped && manualControl && campaign.canStopped">
                    <p-button type="warning" class="mr-2" icon @click="stopCampaign()">
                        <i class="fa fa-pause"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.campaign.btnStartTooltip')" :open-delay="300" placement="top" v-if="lazyAIStopped && manualControl && campaign.isPaused">
                    <p-button type="success" class="mr-2" icon @click="startCampaign()">
                        <i class="fa fa-play"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.campaign.btnDeleteTooltip')" :open-delay="300" placement="top" v-if="lazyAIStopped && manualControl && campaign.canDeleted">
                    <p-button type="danger" icon @click="deleteCampaign">
                        <i class="fa-solid fa-trash-can"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>

        <div class="row" v-if="campaign.errorCode != null">
            <div class="col-12">
                <div class="alert fade show alert-danger">
                    <span>
                        {{errorMsg}}
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-4">
                <stats-card type="info" data-v-step="step-group"
                            :title="translateGroupName(campaign.hvGroupName,campaign.isHvGroupSystem)"
                            :small-title="$t('entityViews.campaign.statusCards.groupLabel')"
                            icon="fa fa-users">
                    <span slot="footer">
                        <div class="footer-container">
                            <div class="row">
                                <div class="col">{{$t('entityViews.campaign.statusCards.domainStatus')}}</div>
                                <div class="col text-right"><i class="fa" v-bind:class="[campaign.isValidHvGroupDomains ? 'fa-check' : 'fa-times']"></i></div>
                            </div>
                        </div>
                    </span>
                </stats-card>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <stats-card type="info" data-v-step="step-template"
                            :title="campaign.campaignBlockGroupName"
                            :small-title="$t('entityViews.campaign.statusCards.cbgLabel')"
                            icon="fa fa-file-text">
                    <span slot="footer">
                        <div class="footer-container">
                            <div class="row">
                                <div class="col">{{$t('entityViews.campaign.statusCards.cbLabel')}}</div>
                                <div class="col text-right">{{campaign.countCampGroupBlocks}}</div>
                            </div>
                            <div class="row">
                                <div class="col">{{$t('entityViews.campaign.statusCards.langLabel')}}</div>
                                <div class="col text-right">{{campaign.campaignBlockGroupCulture.toUpperCase()}}</div>
                            </div>
                        </div>
                    </span>
                </stats-card>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <stats-card type="info" data-v-step="step-status"
                            :title="$t(campaign.statusLabelId)"
                            :small-title="$t('entityViews.campaign.statusCards.statusLabel')"
                            :icon="campaign.statusIcon">
                    <span slot="footer">
                        <div class="footer-container">
                            <div class="row">
                                <div class="col text-left">
                                    {{campaign.startUtc | moment("DD.MM.YYYY")}}
                                </div>
                                <div class="col text-right">
                                    {{campaign.endUtc | moment("DD.MM.YYYY")}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col my-auto">
                                    <p-progress :value="campaign.processPercent"></p-progress>
                                </div>
                            </div>
                            <div class="row" v-if="campaign.isRunning">
                                <div class="col">{{$t('entityViews.campaign.statusCards.nextActionLabel')}}</div>
                                <div class="col text-right">{{nextAction}}</div>
                            </div>
                            <div class="row" v-if="campaign.isPaused">
                                <div class="col">{{$t('entityViews.campaign.statusCards.campPausedAtLabel')}}</div>
                                <div class="col text-right">{{lastStatusChanged}}</div>
                            </div>
                        </div>
                    </span>
                </stats-card>
            </div>
        </div>

        <div class="row" v-if="campaign.isFinished || campaign.isRunning || campaign.isPaused">
            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('entityViews.campaign.maturityLvlDistHeader')}}
                    </h4>
                    <div class="w-100 d-flex justify-content-center mb-3">
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group0, true) + ': ' + mLvlG0[0] + '% (' + mLvlG0[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group0_Basic, 'width': mLvlG0[0] + '%' }"></div>
                        </el-tooltip>
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group1, true) + ': ' + mLvlG1[0] + '% (' + mLvlG1[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group1_Basic, 'width': mLvlG1[0] + '%' }"></div>
                        </el-tooltip>
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group2, true) + ': ' + mLvlG2[0] + '% (' + mLvlG2[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group2_Basic, 'width': mLvlG2[0] + '%' }"></div>
                        </el-tooltip>
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group3, true) + ': ' + mLvlG3[0] + '% (' + mLvlG3[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group3_Basic, 'width': mLvlG3[0] + '%' }"></div>
                        </el-tooltip>
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group4, true) + ': ' + mLvlG4[0] + '% (' + mLvlG4[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group4_Basic, 'width': mLvlG4[0] + '%' }"></div>
                        </el-tooltip>
                        <el-tooltip :content="mLvlTools.translateGroupName(mLvlTools.Name_Group5, true) + ': ' + mLvlG5[0] + '% (' + mLvlG5[1] + ')'" :open-delay="300" placement="top">
                            <div class="mlvl-item" :style="{ 'background': mLvlTools.Color_Group5_Basic, 'width': mLvlG5[0] + '%' }"></div>
                        </el-tooltip>
                    </div>
                </card>
            </div>
        </div>


        <h3 class="title mt-4 text-center">{{$t('entityViews.campaign.hvStatHeader')}}</h3>

        <div class="row">
            <div class="col-12">
                <channel-statistic-cards-byhvs :statistic="byHvs" showInitiatedProc>
                </channel-statistic-cards-byhvs>
            </div>
        </div>

        <h3 class="title mt-4 text-center">{{$t('entityViews.campaign.simulationStatHeader')}}</h3>

        <!-- statistics -->
        <div class="row">
            <div class="col-12">

                <card data-v-step="step-chart-percent">
                    <h4 slot="header">
                        {{$t('entityViews.campaign.activityHeader')}}
                    </h4>
                    <div>
                        <camp-statistic-chart :ChartData="byMessages">
                        </camp-statistic-chart>
                    </div>
                </card>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <channel-statistic-cards-bymsgs :statistic="byMessages" showInitiatedProc>
                </channel-statistic-cards-bymsgs>
            </div>
        </div>

        <div class="row  d-none d-md-block">
            <div class="col-12">
                <card data-v-step="step-chart">
                    <h4 slot="header">
                        {{$t('entityViews.campaign.dateDistHeader')}}
                    </h4>
                    <div>
                        <date-distribution-chart :chartData="dateDistribution" :lastDate="lastDate">

                        </date-distribution-chart>
                    </div>
                </card>
            </div>
        </div>
        <div class="row  d-none d-md-block">
            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('entityViews.campaign.wdDistHeader')}}
                    </h4>
                    <div>
                        <weekday-distribution-chart :chartData="weekDayDistribution">

                        </weekday-distribution-chart>
                    </div>
                </card>
            </div>
        </div>
        <div class="row  d-none d-md-block">
            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('entityViews.campaign.hDistHeader')}}
                    </h4>
                    <div>
                        <hourly-distribution-chart :chartData="hourlyDistribution">

                        </hourly-distribution-chart>
                    </div>
                </card>
            </div>
        </div>

        <div class="row d-none d-md-block" v-if="!campaign.isScheduled">
            <div class="col-12">
                <card>
                    <h4 slot="header">
                        {{$t('entityViews.campaign.cbDistHeader')}}
                    </h4>
                    <div>
                        <cb-distribution :data="campaignBlockDistribution" viewBy="campaign">

                        </cb-distribution>
                    </div>
                </card>
            </div>
        </div>

        <div class="row" v-if="showLast10Actions">
            <div class="col-12">
                <div class="card" data-v-step="step-10actions">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title">{{$t('entityViews.campaign.last10ActsHeader')}}</h4>
                            </div>
                            <div class="col text-right">
                                <el-tooltip content="Refresh" :open-delay="300" placement="top" v-if="showTimelineRefresh">
                                    <p-button type="neutral" class="mr-2" icon @click="$refs.cTimeline.refresh(true)">
                                        <i class="fa-solid fa-arrows-rotate"></i>
                                    </p-button>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <campaign-timeline :campaignHID="campaign.hid" :entityHID="entityHID" ref="cTimeline" @showTimeLineOnChange="showTimeLineOnChange"></campaign-timeline>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hv-table :title="$t('entityViews.campaign.listHvsHeader')" :hvGroupHID="campaign.hvGroupHID" :entityHID="entityHID" :campaignHID="campaign.hid"
                          viewBy="campaign" ref="hvTable" :autoLoading="false" data-v-step="step-detail"></hv-table>
            </div>
        </div>

    </div>
</template>

<script>
    import { CampaignTools, MaturityLevelTools, LazyAITools } from 'src/lpLibrary.js'
    import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, StatsCard } from 'src/components/UIComponents'
    import DateDistributionChart from 'src/components/UIComponents/Charts/DateDistributionChart.vue'
    import CampStatisticChart from 'src/components/UIComponents/Charts/CampStatisticChart.vue'
    import swal from 'sweetalert2'
    import campTimeline from 'src/components/UIComponents/CampaignTimeLine.vue'
    import WeekDayDistributionChart from 'src/components/UIComponents/Charts/WeekDayDistributionChart.vue'
    import HourlyDistributionChart from 'src/components/UIComponents/Charts/HourlyDistributionChart.vue'
    import ChannelStatisticCardsMsgs from 'src/components/UIComponents/ChannelStatisticCardsByMsgs.vue'
    import ChannelStatisticCardsHvs from 'src/components/UIComponents/ChannelStatisticCardsByHvs.vue'
    import CBDistribution from 'src/components/UIComponents/CampaignBlock/CampaignBlockDistribution.vue'

    import HvTable from 'src/components/UIComponents/HVResult/HVTable.vue'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1*sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1*sign;
        }
        return comparison;
    }


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            [Progress.name]: Progress,
            CircleChartCard,
            DateDistributionChart,
            CampStatisticChart,
            StatsCard,
            [HvTable.name]: HvTable,
            [campTimeline.name]: campTimeline,
            [WeekDayDistributionChart.name]: WeekDayDistributionChart,
            [HourlyDistributionChart.name]: HourlyDistributionChart,
            [CBDistribution.name]: CBDistribution,
            [ChannelStatisticCardsMsgs.name]: ChannelStatisticCardsMsgs,
            [ChannelStatisticCardsHvs.name]: ChannelStatisticCardsHvs,
        },
        data() {
            return {
                entityHID: null,
                showTimelineRefresh: false,
                manualControl: false,
                lazyAIStopped: false,
                campaign: {
                    isRunning: CampaignTools.Status_Finished,
                    statistic: {},
                    campaignBlockGroupCulture: "",
                },
            }
        },
        computed: {
            showLast10Actions() {
                return this.campaign.status == (CampaignTools.Status_Running || CampaignTools.Status_Paused);
            },
            lastDate() {
                //return new Date(this.campaign.endUtc);
                if (this.dateDistribution == undefined) return new Date(this.campaign.endUtc);

                let lastDate = this.dateDistribution[this.dateDistribution.length - 1];
                if (lastDate != undefined) {
                    return new Date(lastDate.timeStampUtc);
                }
                return new Date(this.campaign.endUtc);
            },   
            nextAction() {
                if (this.campaign.nextActionUtc == null) return "-----"
                return this.$moment(this.campaign.nextActionUtc).format("DD.MM.YYYY HH:mm");
            },
            lastStatusChanged() {
                if (this.campaign.lastStatusChangeUtc == null) return "-----"
                return this.$moment(this.campaign.lastStatusChangeUtc).format("DD.MM.YYYY HH:mm");
            },
            byMessages() {
                if (this.campaign.statistic == undefined) return {};
                return this.campaign.statistic.byMessages;
            },
            byHvs() {
                if (this.campaign.statistic == undefined) return {};
                return this.campaign.statistic.byHvs;
            },
            dateDistribution() {
                if (this.campaign.statistic == undefined) return [];
                return this.campaign.statistic.dateDistribution;
            },
            weekDayDistribution() {
                if (this.campaign.statistic == undefined) return [];
                return this.campaign.statistic.weekDayDistribution;
            },
            hourlyDistribution() {
                if (this.campaign.statistic == undefined) return [];
                return this.campaign.statistic.hourlyDistribution;
            },
            campaignBlockDistribution() {
                if (this.campaign.statistic == undefined) return [];
                return this.campaign.statistic.campaignBlockDistribution;
            },
            showSendingMode() {
                return this.campaign.sendingMode == CampaignTools.Status_Huge;
            },
            errorMsg() {
                return CampaignTools.GetErrorMsg(this.campaign.errorCode);
            },
            mLvlTools() {
                return MaturityLevelTools;
            },           
            mLvlG0() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0, 0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvlUnrated / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvlUnrated
                ];    
            },
            mLvlG1() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0, 0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl1 / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl1
                ]
            },
            mLvlG2() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0, 0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl2 / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl2
                ]
            },
            mLvlG3() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0, 0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl3 / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),    
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl3
                ]
            },
            mLvlG4() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0, 0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl4 / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),    
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl4
                ]
            },
            mLvlG5() {
                if (this.campaign.statistic.maturityLevelDistribution == undefined) return [0,0];
                return [
                    (this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl5 / this.campaign.statistic.byHvs.countInitiated * 100).toFixed(1),  
                    this.campaign.statistic.maturityLevelDistribution.hvInMaturityLvl5
                ]
            },
        },
        methods: {
            loadCampaign: function () {
                let chid = this.$route.params.chid;
                if (chid === undefined) return;
                let vueThis = this;
                //let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.campaign.getCampaignDetailUrl(this.entityHID), null, { params: { campaignHID: chid } })
                    .then(response => {
                        vueThis.campaign = response.data;
                        var suiData = CampaignTools.GetStatusUIData(vueThis.campaign.status);
                        vueThis.campaign.statusLabelId = suiData.labelId;
                        vueThis.campaign.statusIcon = suiData.icon;
                        vueThis.campaign.isRunning = vueThis.campaign.status == CampaignTools.Status_Running;
                        vueThis.campaign.isScheduled = vueThis.campaign.status == CampaignTools.Status_Scheduled;
                        vueThis.campaign.isFinished = vueThis.campaign.status == CampaignTools.Status_Finished;
                        vueThis.campaign.isPaused = vueThis.campaign.status == CampaignTools.Status_Paused;
                        vueThis.campaign.canStopped = vueThis.campaign.status == CampaignTools.Status_Running || vueThis.campaign.status == CampaignTools.Status_Scheduled;
                        vueThis.campaign.canDeleted = vueThis.campaign.status != CampaignTools.Status_Finished || vueThis.campaign.isQA;

                        vueThis.campaign.reportDwnLnk = vueThis.$root.config.entity.report.getDownloadReportUrl(vueThis.entityHID) + "?reportHID=" + vueThis.campaign.reportHID
                        
                        //loader.hide();
                        swal.close();
                    }).catch(function (error) {
                        swal.close();
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        //loader.hide();
                    });
            },
            deleteCampaign: function () {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = this.$t('entityViews.campaign.delAlert.msg', [this.campaign.name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.campaign.deleteCampaignUrl(this.entityHID), null, { params: { campaignHID: this.campaign.hid } })
                            .then(response => {
                                swal.close();
                                this.$router.push({ name: 'eCampaignsList' });
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.campaign.delAlert.success', [this.campaign.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                swal.close();
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.campaign.delAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            refresh: function () {
                this.loadCampaign();
                if (this.showLast10Actions) {
                    this.$refs.cTimeline.refresh();
                }
            },
            stopCampaign() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.campaign.stopAlert.msg', [vueThis.campaign.name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.campaign.changeCampaignStatusUrl(this.entityHID), null, { params: { campaignHID: this.campaign.hid, action: 0 } })
                            .then(response => {
                                vueThis.loadCampaign();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.campaign.stopAlert.success', [vueThis.campaign.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.campaign.stopAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            startCampaign() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.campaign.startAlert.msg', [vueThis.campaign.name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        this.axios.post(this.$root.config.entity.campaign.changeCampaignStatusUrl(this.entityHID), null, { params: { campaignHID: this.campaign.hid, action: 1 } })
                            .then(response => {
                                vueThis.loadCampaign();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.campaign.startAlert.success', [vueThis.campaign.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.campaign.startAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            editCampaign() {
                var vueThis = this;
                let cName = this.campaign.name;
                swal.fire({
                    title: vueThis.$t('entityViews.campaign.editAlert.msg'),
                    input: 'text',
                    inputValue: cName,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return vueThis.$t('entityViews.campaign.editAlert.errReq');
                        }
                        if (value.trim().length > this.$root.config.maxCampainNameLength) {
                            return vueThis.$t('entityViews.campaign.editAlert.errMax', [this.$root.config.maxCampainNameLength]);
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.entity.campaign.renameCampaignUrl(vueThis.entityHID), null, { params: { campaignHID: vueThis.campaign.hid, name: result.value.trim() } })
                        .then(response => {
                            vueThis.refresh();
                            swal.fire({
                                icon: 'success',
                                title: vueThis.$t('entityViews.campaign.editAlert.success'),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        })
                        .catch(function (error) {
                            if (error.response.status == 400) {
                                if (result == null || result == undefined) {
                                    result = { value: "NaN" };
                                }
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('entityViews.campaign.editAlert.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: result.value }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },
            showTimeLineOnChange(value) {
                this.showTimelineRefresh = value;
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
        },
        mounted: function () {
            this.manualControl = this.$store.getters.appState.entity.manualControl;
            this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;
            this.loadCampaign();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.$router.push({ name: 'eCampaignsList' });
                }
            },
        },
        created() {
            this.entityHID = this.$route.params.ehid;
        }
    }

</script>

<style scoped>
    .btn-campaign-control{
        margin: 0px;
    }


    .footer-container {
        min-height: 30px;
    }

</style>
