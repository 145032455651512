<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{title}}</h4>
                    </div>
                    <div class="col text-right">
                        <el-tooltip :content="$t('groupReportTable.btnDownloadCsvTooltip')" :open-delay="300" placement="top">
                            <a :href="downloadCsv" class="btn btn-icon btn-neutral mr-2">
                                <i class="fa fa-download"></i>
                            </a>
                        </el-tooltip>
                        <el-tooltip :content="$t('groupReportTable.btnRefreshTooltip')" :open-delay="300" placement="top">
                            <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="text-muted">
                            {{description}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row" ref="table">
                    <div class="col-12">
                        <!-- header -->
                        <div class="row justify-content-between">
                            <div class="col-12 col-md-6 pt-0 pt-md-2">

                            </div>
                            <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                                <div class="d-flex justify-content-end">
                                    <fg-input class="input-sm mr-2"
                                              :placeholder="$t('groupReportTable.searchPlaceholder')"
                                              v-model="g_searchQuery"
                                              addon-right-icon="nc-icon nc-zoom-split">
                                    </fg-input>
                                    <el-select class="select-default float-sm-left page-size"
                                               v-model="g_pagination.perPage">
                                        <el-option class="select-default"
                                                   v-for="item in g_pagination.perPageOptions"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>


                        </div>
                        <!-- table -->
                        <div class="row">
                            <div class="col-12" ref="table">
                                <el-table :data="g_queriedData" :empty-text="$t('groupReportTable.table.noData')"
                                          :default-sort="{ prop: 'percentCompromisedByHv', order: 'descending'}"
                                          :row-class-name="groupTableRowClassName"
                                          @sort-change="g_handleSortChange">
                                    <el-table-column prop="name" sortable="custom" :label="$t('groupReportTable.table.colName')">
                                    </el-table-column>
                                    <el-table-column prop="countHvsInTraining" sortable="custom" width="100" align="center">
                                        <span slot="header" class="text-default">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </span>
                                    </el-table-column>
                                    <el-table-column prop="percentReactedByHv" sortable="custom" width="130" align="center">
                                        <span slot="header" class="text-reacted">
                                            <i class="fa-solid fa-hand-point-up" aria-hidden="true"></i>
                                        </span>
                                        <div slot-scope="{row}" class="td-number">
                                            {{(row.percentReactedByHv * 100).toFixed(1)}} % ({{row.countReactedByHv}})
                                        </div>
                                    </el-table-column>
                                    <el-table-column prop="percentCompromisedByHv" sortable="custom" width="130" align="center">
                                        <span slot="header" class="text-compromised">
                                            <i class="fa fa-bomb" aria-hidden="true"></i>
                                        </span>
                                        <div slot-scope="{row}" class="td-number">
                                            {{(row.percentCompromisedByHv * 100).toFixed(1)}} % ({{row.countCompromisedByHv}})
                                        </div>
                                    </el-table-column>
                                    <el-table-column :width="120" class-name="td-actions" label="Actions" align="center">
                                        <template slot="header" slot-scope="scope">
                                            <span data-v-step="step-groupactions">{{$t('groupReportTable.table.colActions.header')}}</span>
                                        </template>
                                        <template slot-scope="props">
                                            <el-tooltip :content="$t('groupReportTable.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'eHVGroupReport', params:{ ghid: props.row.hid}}">
                                                    <i class="fa fa-pie-chart"></i>
                                                </router-link>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <!-- paging -->
                        <div class="row">
                            <div class="col-sm-6 pagination-info">
                                <p class="category">{{$t('groupReportTable.pagingInfo', [g_from + 1, g_to, g_total])}}</p>
                            </div>
                            <div class="col-sm-6">
                                <p-pagination class="pull-right"
                                              v-model="g_pagination.currentPage"
                                              :per-page="g_pagination.perPage"
                                              :total="g_pagination.total">
                                </p-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, } from 'src/components/UIComponents'
    import PPagination from 'src/components/UIComponents/Pagination.vue'
    import swal from 'sweetalert2'
    import { MaturityLevelTools } from 'src/lpLibrary.js'

    function g_sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'group-report-table',
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            CollapseItem,
            Collapse,
            PPagination,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
        },
        props: {
            title: {
                type: String,
            },
            description: {
                type: String,
            },
            entityHID: {
                type: String,
            },
        },
        data() {
            return {
                groups:[],
                g_pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                g_propsToSearch: ['name'],
                g_searchQuery: '',
                g_sortObject: { order: 'descending', name: 'percentCompromisedByHv' },
            };
        },
        methods: {
            initComponent(force) {
                this.loadGroupData(force);
            },
            refresh(force) {
                this.initComponent(force);
            },
            loadGroupData(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                let vueThis = this;
                //let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.report.getEntityGroupsReportUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        //loader.hide();
                        vueThis.groups = response.data;
                        vueThis.groups.forEach(function (item) {
                            item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                        });
                        
                        swal.close();
                    }).catch(function (error) {
                        swal.close();
                        vueThis.writeEx2Log(error);
                        //loader.hide();
                        vueThis.genericErrorAlert();
                    });
            },
            //-------------
            g_handleSortChange(e) {
                this.g_sortObject = { order: e.order, name: e.prop };
            },
            groupTableRowClassName({ row, rowIndex, }) {
                if (row.isSystem == true) {
                    return "system-group " + MaturityLevelTools.getGroupCssClass(row.name);
                }
                return '';
            },
        },
        mounted() {
        },
        computed: {
            downloadCsv() {
                return this.$root.config.entity.report.downloadEntityGroupsReportUrl(this.entityHID);
            },
            g_queriedData() {
                if (this.groups == undefined) return [];

                let cThis = this;
                if (!this.g_searchQuery) {
                    this.g_pagination.total = this.groups.length;
                    return this.groups.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); }).slice(this.g_from, this.g_to);
                }
                let result = this.groups
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.g_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.g_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.g_pagination.total = result.length;
                return result.sort(function (a, b) { return g_sortingHandler(a, b, cThis.g_sortObject); }).slice(this.g_from, this.g_to);
            },
            g_to() {
                let highBound = this.g_from + this.g_pagination.perPage;
                if (this.g_total < highBound) {
                    highBound = this.g_total;
                }
                return highBound;
            },
            g_from() {
                if (this.g_pagination.total == 0) return -1;
                return this.g_pagination.perPage * (this.g_pagination.currentPage - 1);
            },
            g_total() {
                return this.g_pagination.total;
            },
        }
    }

</script>

<style scoped>

</style>